import { Icon } from 'leaflet';

//category icons
import boxes from '../assets/icons/fruits.svg'
import bread from '../assets/icons/fruits.svg'
import chicken from '../assets/icons/fruits.svg'
import charcoal from '../assets/icons/fruits.svg'
import plants from '../assets/icons/fruits.svg'
import honey from '../assets/icons/fruits.svg'
import nuts from '../assets/icons/fruits.svg'
import vegetable from '../assets/icons/fruits.svg'
import wood from '../assets/icons/fruits.svg'
import spices from '../assets/icons/fruits.svg'
import herbs from '../assets/icons/fruits.svg'
import beverages from '../assets/icons/fruits.svg'

//color coded map markers
import lavender from '../assets/icon-markers/lavender.svg'
import green from '../assets/icon-markers/green.svg'
import aqua from '../assets/icon-markers/aqua.svg'
import yellow from '../assets/icon-markers/yellow.svg'
import violet from '../assets/icon-markers/violet.svg'
import red from '../assets/icon-markers/red.svg'
import purple from '../assets/icon-markers/purple.svg'
import pink from '../assets/icon-markers/pink.svg'
import orange from '../assets/icon-markers/orange.svg'
import lime from '../assets/icon-markers/lime.svg'
import locationIcon from '../assets/icons/userlocation.svg'

//normal icons 
import truckMarkerIcon from '../assets/icons/truck.svg';
import dcMarkerIcon from '../assets/icons/dc-marker.svg';
import storeMarkerIcon from '../assets/icons/store-marker.svg';
import userMarkerIcon from '../assets/icons/usermarker.svg';

//table headers
export const tableHeaders = [
    {
        orders: [
            {
                drafts: ['Operation', 'Document No.', 'Date', 'Client', 'Amount'],
                sizing: [0, 1]
            },
            {
                myOrders: ['Order Number', 'Order Date', 'Client', 'Amount', 'Status', 'AP Phase'],
                sizing: [0, 1, 5]
            },
            {
                processsing: ['Order Number', 'Order Date', 'Client', 'Amount', 'Status', 'AP Phase'],
                sizing: [0, 1, 5, 6]
            },
            {
                completed: ['Order Number', 'Order Date', 'Completion Date', 'Client', 'Amount', 'Status', 'Actions'],
                sizing: [0, 1, 2, 6]
            },
            {
                cancelled: ['Order Number', 'Order Date', 'Client', 'Amount', 'Status', 'AP Phase'],
                sizing: [1, 3, 5]
            },
        ],
        approvals: [
            {
                commercial: ['Order Number', 'Order Date', 'Client', 'Amount', 'Status', 'Actions'],
                sizing: [0, 1, 5]
            },
            {
                financial: ['Order Number', 'Order Date', 'Client', 'Amount', 'Status', 'Actions'],
                sizing: [0, 1, 5, 6]
            },
            {
                stock: ['Order Number', 'Order Date', 'Client', 'Amount', 'Status', 'Actions'],
                sizing: [0, 1, 5]
            },
            {
                picking: ['Order Number', 'Order Date', 'Client', 'Amount', 'Status', 'Actions', 'Integration'],
                sizing: [0, 1, 5, 6]
            },
            {
                loading: ['Order Number', 'Order Date', 'Client', 'Amount', 'Status', 'truck', 'Actions', 'Integration'],
                sizing: [0, 1, 5, 6, 7, 8]
            },
            {
                delivery: ['Order Number', 'Order Date', 'Client', 'Amount', 'Truck', 'Status', 'Actions', 'Integration'],
                sizing: [0, 1, 4, 6, 7]
            },
        ]
    }
]

//table headers
export const InvoiceTableHeaders = [
    {
        approvals: [
            {
                defaultHeaders: ['UID', 'Product', 'Quantity', 'Price excl (Per Item)', 'Price incl (Per Item)', 'Sub Total'],
                sizing: [0, 1, 2, 3, 4, 5, 6]
            },
            {
                stock: ['UID', 'Product', 'Quantity', 'Approved Quantity', 'Price excl (Per Item)', 'Price incl (Per Item)', 'Sub Total'],
                sizing: [0, 1, 2, 3, 4, 5, 6]
            },
            {
                picking: ['UID', 'Product', 'Quantity', 'Approved Quantity', 'Quantity Picked', 'Price excl (Per Item)', 'Price incl (Per Item)', 'Sub Total'],
                sizing: [0, 1, 2, 3, 4, 5, 6]
            },
            {
                loading: ['UID', 'Product', 'Quantity Picked', 'Price excl (Per Item)', 'Price incl (Per Item)', 'Sub Total'],
                sizing: [0, 1, 2, 3, 4, 5, 6]
            },
            {
                delivery: ['UID', 'Product', 'Quantity Picked', 'Quantity Delivered', 'Price excl (Per Item)', 'Price incl (Per Item)', 'Sub Total'],
                sizing: [0, 1, 2, 3, 4, 5, 6]
            },
            {
                delivered: ['UID', 'Product', 'Quantity Delivered', 'Price excl (Per Item)', 'Price incl (Per Item)', 'Sub Total'],
                sizing: [0, 1, 2, 3, 4, 5, 6]
            },
            {
                iot: ['Store Name', 'Yesterday Close Time', 'Today Open Time', 'Frier Temp (1)', 'Frier Temp (2)', 'Frier Temp (3)', 'Frier Temp (4)'],
                sizing: [0, 1, 2, 3, 4, 5, 6]
            },
        ]
    }
]

//table headers
export const cmsTableHeaders = [
    {
        cms: [
            {
                users: ['User ID', 'Full Name', 'Role', 'Phone', 'Email', 'Date Created', 'Last Order Date', 'Status'],
            },
            {
                vehicles: ['Vehicle ID', 'Make', 'Model', 'License Number', 'Status'],
            },
            {
                clients: ['Client ID', 'Client', 'Contact Person', 'Phone', 'Email', 'Date Created', 'Last Order Date', 'Status'],
            },
            {
                surveys: ['Survey ID', 'Survey Name', 'Start Date', 'End Date', 'Creation Date', 'Status'],
            },
        ]
    }
]

//website starting tab
export const storeStarter = 1

//token extractor from session storage 
const extractTokenFromSession = () => {
    const session = JSON.parse(sessionStorage.getItem('session'));
    const token = session?.token || null;
    return token;
};

//api req header configuration
export const config = {
    headers: {
        'token': extractTokenFromSession(),
        mode: 'no-cors'
    }
};

//DC store coordinates
export const dcCOORDS = [-26.268021186902324, 28.188514039148536]

//category and icon mapper
export const IconMapper = [
    {
        name: 'BOXES',
        icon: boxes
    },
    {
        name: 'BREAD',
        icon: bread
    },
    {
        name: 'CHICKEN',
        icon: chicken
    },
    {
        name: 'CHARCOAL',
        icon: charcoal
    },
    {
        name: 'PLANTS',
        icon: plants
    },
    {
        name: 'HONEY',
        icon: honey
    },
    {
        name: 'NUTS',
        icon: nuts
    },
    {
        name: 'VEGETABLE',
        icon: vegetable
    },
    {
        name: 'WOOD',
        icon: wood
    },
    {
        name: 'SPICES',
        icon: spices
    },
    {
        name: 'HERBS',
        icon: herbs
    },
    {
        name: 'BEVERAGES',
        icon: beverages
    },
    {
        name: 'FRUIT',
        icon: beverages
    }
];

//color coded markers
function createMarker(iconUrl, iconSize, iconAnchor) {
    return new Icon({
        iconUrl: iconUrl,
        iconSize: iconSize,
        iconAnchor: iconAnchor,
    });
}

export const lavenderMarker = createMarker(lavender, [33, 33], [16, 32]);
export const greenMarker = createMarker(green, [33, 33], [16, 32]);
export const aquaMarker = createMarker(aqua, [33, 33], [16, 32]);
export const yellowMarker = createMarker(yellow, [33, 33], [16, 32]);
export const violetMarker = createMarker(violet, [33, 33], [16, 32]);
export const redMarker = createMarker(red, [33, 33], [16, 32]);
export const purpleMarker = createMarker(purple, [33, 33], [16, 32]);
export const pinkMarker = createMarker(pink, [33, 33], [16, 32]);
export const orangeMarker = createMarker(orange, [33, 33], [16, 32]);
export const limeMarker = createMarker(lime, [33, 33], [16, 32]);
export const truckMarker = createMarker(truckMarkerIcon, [53, 53], [16, 32]);
export const userMarker = createMarker(userMarkerIcon, [60, 60], [16, 32]);
export const dcMarker = createMarker(dcMarkerIcon, [53, 53], [16, 32]);
export const storeMarker = createMarker(storeMarkerIcon, [33, 33], [16, 32]);
export const repMarker = createMarker(locationIcon, [60, 60], [16, 32]);

export const colourCodedMarkers = {
    lavender: lavenderMarker,
    green: greenMarker,
    aqua: aquaMarker,
    yellow: yellowMarker,
    violet: violetMarker,
    red: redMarker,
    purple: purpleMarker,
    pink: pinkMarker,
    orange: orangeMarker,
    lime: limeMarker,
};

//map default parameters
export const position = [-26.228460, 28.129074]
export const zoom = 10

export const maxSurveyQuestions = 5