import { Layout } from "../layout/layout"

export const NotificationsReader = () => {
    return (
        <Layout>
            <div className="p-2">
                <p>Read notifications here</p>
            </div>
        </Layout>
    )
}
