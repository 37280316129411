import { useState } from 'react';

export const InputHandler = () => {
    const [inputValues, setInputValues] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues((prevInputValues) => ({ ...prevInputValues, [name]: value }));
    };

    const clearValues = () => setInputValues('')

    return { inputValues, handleChange, clearValues };
};
