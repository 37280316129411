import { Tabs } from "../components/tabs/Tabs";

//components
import { Layout } from "../layout/layout"
import { CreateVehicle } from "../modules/vehicles/create-vehicle";
import { ViewVehicles } from "../modules/vehicles/view-vehicle";

//tab components
const tabs = [
    {
        label: 'Create Vehicle',
        content: <CreateVehicle />,
    },
    {
        label: 'View All Vehicles',
        content: <ViewVehicles />,
    },
];

export const Vehicles = () => {
    return (
        <Layout>
            <div className="p-2">
                <Tabs tabs={tabs} />
            </div>
        </Layout>
    )
}