import "leaflet/dist/leaflet.css";
import { toast } from 'react-hot-toast';
import { useParams } from "react-router-dom";
import { useRef, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";

//images
import callIcon from '../assets/icons/call.svg'

//components
import { Routing } from "../modules/map/router";
import { isEmpty } from "lodash";
import { useQuery } from "../hooks/useQuery";

// map resets 
export const position = [-26.228460, 28.129074]
export const zoom = 15
export const averageVehicleSpeed = 100

export const DepotCOORDS = () => {
    const query = useParams()

    const { data, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/track/getlastlocationbynumber/${query?.query}`, "get", 100)

    if (error) {
        toast('Failed to fetch the truck location', {
            icon: '❌',
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
            duration: 3000,
        });
    }

    if (isEmpty(data)) {
        toast('We could not find the truck please refresh', {
            icon: '❌',
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
            duration: 3000,
        });
    }

    const depotCOORDS = data?.map((property) => {
        return {
            latitude: property.latitude,
            longitude: property.longitude,
        }
    })

    return depotCOORDS;
}

export const ClientCOORDS = () => {
    const query = useParams()

    const { data, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/client/getclientbyordernumber/${query?.query}`, "get", 100)

    if (error) {
        toast('Failed to fetch your location', {
            icon: '❌',
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
            duration: 3000,
        });
    }

    if (isEmpty(data)) {
        toast('We could not find your location please refresh', {
            icon: '❌',
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
            duration: 3000,
        });
    }

    const clientCOORDS = data?.map((property) => {
        return {
            latitude: property.latitude,
            longitude: property.longitude,
        }
    })

    return clientCOORDS;
}

export const DriverContact = () => {
    const query = useParams()

    const { data, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/contact/getdrivercontact/${query?.query}`, "get", 10000)

    if (error) {
        toast('Failed to fetch your location', {
            icon: '❌',
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
            duration: 3000,
        });
    }

    if (isEmpty(data)) {
        toast('We could not find your location please refresh', {
            icon: '❌',
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
            duration: 3000,
        });
    }

    const contact = data[0]?.phone_number

    return contact;
}

export const OrderTracking = () => {
    const mapRef = useRef(null)
    const [routeInfo, setRouteInfo] = useState({
        totalDistance: null,
        totalDuration: null,
    });

    const routeSummary = (routeData) => {

        setRouteInfo({
            ...routeInfo,
            totalDistance: routeData?.totalDistance / 1000,
            totalDuration: routeData?.totalTime / 3600,
        });
    };

    const depotCOORDS = DepotCOORDS();
    const clientCOORDS = ClientCOORDS();
    const contact = DriverContact();

    const routeCOORDS = [...depotCOORDS, ...clientCOORDS]

    return (
        <div className="h-screen w-full relative flex flex-col justify-between gap-0 overflow-hidden">
            <MapContainer ref={mapRef} center={position} zoom={routeInfo.totalDistance !== null && routeInfo.totalDuration !== null ? 20 : zoom} scrollWheelZoom={true} className="w-full h-screen">
                <TileLayer url={process.env.REACT_APP_MAP_TILE} attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
                <Routing wayPoints={routeCOORDS} routeSummary={routeSummary} type='tracking' />
            </MapContainer>
            {routeInfo.totalDistance !== null && routeInfo.totalDuration !== null &&
                <div className="bg-white rounded-lg p-2 flex items-center justify-between gap-1 absolute z-[999999] w-11/12 mx-auto md:mx-0 left-0 right-0 md:w-6/12 lg:w-3/12 bottom-4 md:left-3">
                    <p className="w-3/12 md:w-1/3 text-base md:text-xl font-bold text-left">
                        {routeInfo?.totalDistance?.toFixed(2)}
                        <span className="text-base text-gray-500 font-medium">km</span>
                    </p>
                    <p className="w-5/12 md:w-1/2 text-base md:text-xl font-bold text-center">
                        <span className="text-base">ETA:</span>{" "}
                        {routeInfo?.totalDuration < 1
                            ? (routeInfo?.totalDuration * 60).toFixed(2)
                            : routeInfo?.totalDuration?.toFixed(2)}
                        <span className="text-base text-gray-500 font-medium">
                            {routeInfo?.totalDuration < 1 ? "mins" : "hrs"}
                        </span>
                    </p>
                    <div className="w-2/12 md:w-1/4 flex items-center justify-end">
                        <a href={`tel:${contact && contact}`}>
                            <figure className="bg-green p-2 rounded-lg cursor-pointer">
                                <img src={callIcon} alt='' loading='lazy' className="w-8" />
                            </figure>
                        </a>
                    </div>
                </div>
            }
        </div>
    )
}
