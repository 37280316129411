import { useEffect, useState } from "react";
import { toast } from 'react-hot-toast';
import axios from 'axios';

//hooks
import { useSessionPolling } from "../../../hooks/useSessionPolling"

//components
import { isEmpty } from "lodash";
import { AddSurveyModal } from "./settings-modal/survey-add-modal";
import { EditSurveyModal } from "./settings-modal/survey-edit-modal";

//request configuration
import { config } from '../../../utils/app-settings';

export const Survey = () => {
    const [state, setState] = useState({
        isLoading: '',
        isVisible: null,
        isVisible2: null,
        survey_actions: [],
        property: []
    })

    const userID = useSessionPolling("session");

    const extractTokenFromSession = () => {
        const session = JSON.parse(sessionStorage.getItem('session'));
        const token = session?.token || null;
        return token;
    };

    const getSurveyActions = axios.create({
        baseURL: `${process.env.REACT_APP_ENDPOINT}/api/v1/settings/surveyactions`,
        mode: "no-cors",
        headers: { token: extractTokenFromSession() },
    });

    useEffect(() => {
        // Fetch categories data
        getSurveyActions.get("/")
            .then((res) => {
                if (!isEmpty(res.data)) {
                    setState((prevState) => ({
                        ...prevState,
                        survey_actions: res.data,
                    }));
                }
            })
            .catch((error) => {
                // Handle error if needed
                console.error("Error fetching categories data:", error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userID?.uid]);

    const addSurvey = async (inputValues) => {
        if (!isEmpty(inputValues?.action)) {
            try {

                const payLoad = {
                    actions: inputValues?.action,
                    user_added: userID?.name
                }

                const createAction = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/settings/createsurveyaction`, payLoad, config)

                if (createAction?.data?.message === 'Success') {

                    toast(`Survey action added successfully`, {
                        icon: '👋',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });

                    closeModal2()

                    setTimeout(() => {
                        window.location.reload()
                    }, 3100);

                } else {

                    toast('Failed to add survey action, please try again', {
                        icon: '❌',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });

                    closeModal2()

                    setTimeout(() => {
                        window.location.reload()
                    }, 3100);
                }
            }
            catch (error) {

                toast('Error adding survey action, try again later', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                closeModal2()

                setTimeout(() => {
                    window.location.reload()
                }, 3100);
            }
        } else {
            toast('Error please fill in all the fields', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }


    };

    const updateAction = async (product, inputValues) => {
        if (!isEmpty(inputValues?.actions)) {
            try {
               
                const payLoad = {
                    actions: inputValues?.actions || product?.actions
                }

                const updateAction = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/settings/updateaction/${product?.uid}`, payLoad, config)

                if (updateAction?.data?.message === 'Success') {

                    toast(`Survey action updated successfully`, {
                        icon: '👋',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });

                    closeModal()

                    setTimeout(() => {
                        window.location.reload()
                    }, 3100);

                } else {

                    toast('Failed to update survey action, please try again', {
                        icon: '❌',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });

                    closeModal()

                    setTimeout(() => {
                        window.location.reload()
                    }, 3100);
                }
            }
            catch (error) {
                console.log(error)
                toast('Error updating survey action, try again later', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                closeModal()

                setTimeout(() => {
                    window.location.reload()
                }, 3100);
            }
        } else {
            toast('Error please edit the action to make changes', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }
    };

    const deleteAction = async (Profile_UID) => {

        try {

            const profileDelete = await axios.delete(`${process.env.REACT_APP_ENDPOINT}/api/v1/settings/deletesurveyaction/${Profile_UID}`, config)

            if (profileDelete?.data?.message === 'Success') {

                toast(`Survey action deleted successfully`, {
                    icon: '👋',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                setTimeout(() => {
                    window.location.reload()
                }, 3100);

            } else {

                toast('Failed to delete survey action, please try again', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                setTimeout(() => {
                    window.location.reload()
                }, 3100);
            }
        }
        catch (error) {

            toast('Error deleting survey action, try again later', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });

            setTimeout(() => {
                window.location.reload()
            }, 3100);
        }
    }

    const openModal = (setting) => {
        setState((prevState) => ({ ...prevState, isVisible: true, property: setting }));
    };

    const openModal2 = (property) => setState({ ...state, isVisible2: !state.isVisible2, product: property });

    const closeModal = () => setState((prevState) => ({ ...prevState, isVisible: false }));
    const closeModal2 = () => setState((prevState) => ({ ...prevState, isVisible2: false }));

    return (
        <div className="w-full h-full flex flex-col justify-start gap-4 overflow-hidden">
            <div className="w-full h-full flex flex-col justify-start gap-4">
                <div className='text-lg' >Survey Actions</div>
                <hr className="border border-grey-darker w-[94vw]" />
                <div className="w-full md:w-3/12 flex flex-col items-start justify-center gap-4">
                    {
                        state.survey_actions?.length > 0 ? (
                            state.survey_actions?.map((setting, index) =>
                                <div className=" w-full flex items-center justify-between" key={index}>
                                    <p className="w-1/4 flex items-center justify-start text-left">Action {index + 1}</p>
                                    <p className="w-1/4 flex items-center justify-start text-left">{setting?.actions}</p>
                                    <div className="w-1/4 flex items-center justify-center">
                                        <button onClick={() => openModal(setting)} className="border border-purple px-6 py-1 text-sm text-purple hover:bg-purple hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer">Edit</button>
                                    </div>
                                    <div className="w-1/4 flex items-center justify-center">
                                        <button onClick={() => { deleteAction(setting?.uid) }} className="border border-red px-6 py-1 text-sm text-red hover:bg-red hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer">Delete</button>
                                    </div>
                                </div>
                            )
                        ) : (
                            <p className="text-base font-medium text-gray-500">No set actions</p>
                        )
                    }
                </div>
                <button onClick={openModal2} className="bg-green text-white w-32 py-1 px-2 rounded cursor-pointer text-base lg:ease-in-out duration-300">Add Action</button>
                {state.isVisible2 && <AddSurveyModal closeModal={closeModal2} addSurvey={addSurvey} />}
                {state.isVisible && <EditSurveyModal action={state.property} closeModal={closeModal} updateAction={updateAction} />}
            </div>
        </div>
    )
}
