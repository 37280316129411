import { useState } from "react";
import axios from 'axios';
import { toast } from 'react-hot-toast';
import Validator from 'validatorjs';
import moment from 'moment';
import PlacesAutocomplete from "react-places-autocomplete";

//hooks
import { InputHandler } from "../../hooks/useInputHandler";

//components
import { Roles } from "./sections/roles";
import { Division } from "./sections/division";
import { UserType } from "./sections/user-type";
import { Stores } from "./sections/create-stores";
import { isEmpty } from "lodash";

//images
import loadingIcon from "../../assets/icons/wloader.svg";
import eye from '../../assets/icons/faEye.svg';
import eyeSlash from '../../assets/icons/faEyeSlash.svg';

export const CreateUser = () => {
    const [state, setState] = useState({
        role: '',
        division: '',
        userType: '',
        assignedStores: '',
        status: 1,
        activeStatus: 1,
        isLoading: '',
        errorID: '',
        errorUsername: '',
        bAddress: '',
        dAddress: '',
        operationCompleted: '',
        tempStoresToSend: [],
        showPswd: ''
    })

    const [errors, setErrors] = useState({});

    const { inputValues, clearValues, handleChange } = InputHandler();

    const handleBusinessAddress = async (value) => setState({ ...state, bAddress: value })

    const handleDeliveryAddress = async (value) => setState({ ...state, dAddress: value })

    const togglePswd = () => setState({ ...state, showPswd: !state.showPswd });

    const toggleStatus = () => setState((prevState) => ({ ...prevState, status: prevState.status === 0 ? 1 : 0 }));
    const toggleActive = () => setState((prevState) => ({ ...prevState, activeStatus: prevState.activeStatus === 0 ? 1 : 0 }));

    //grabs and sets values from drop down menus
    const handleRoles = (params) => setState({ ...state, role: params })
    const handleDivision = (params) => setState({ ...state, division: params })
    const handleUserTypes = (params) => setState({ ...state, userType: params })
    const handleAssignedStores = (params) => setState((prevState) => {

        // Check if the store UID already exists in the assignedStores array
        const uidExists = prevState.assignedStores.includes(params);

        if (uidExists) {
            // Remove the UID from the assignedStores state
            return {
                ...prevState, assignedStores: prevState.assignedStores.filter((uid) => uid !== params)
            };
        } else {
            // Add the UID to the assignedStores state
            return {
                ...prevState, assignedStores: [...prevState.assignedStores, params]
            };
        }
    });

    const createUser = async (e) => {
        e.preventDefault()

        const validEntry = {
            userID: 'required',
            name: 'required',
            surname: 'required',
            email: 'required|email',
            username: 'required',
            password: 'required',
            contact: 'required',
            role: 'required',
            userType: 'required',
            division: 'required',
            assignedStores: 'required',
            business_address: 'required',
            home_address: 'required',
        }

        const totalData = Object.assign({}, inputValues, {
            role: state.role.uid,
            division: state.division.uid,
            userType: state.userType.uid,
            assignedStores: state.assignedStores,
            business_address: state.bAddress,
            home_address: state.dAddress
        })

        const validation = new Validator(totalData, validEntry);

        if (validation.fails()) {
            setErrors(validation.errors.all());
            return;
        }

        if (validation.passes()) {

            setState({ ...state, isLoading: true })

            try {
                const session = JSON.parse(sessionStorage.getItem('session'));

                const config = {
                    headers: {
                        'token': session?.token,
                        mode: 'no-cors'
                    }
                };

                const findMatchingUserUID = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/user/getListOfUserUids`, config)

                if (findMatchingUserUID?.data && !isEmpty(findMatchingUserUID?.data)) {

                    const matchingUID = findMatchingUserUID?.data.filter((item) => item.uid === inputValues?.userID);

                    if (isEmpty(matchingUID)) {
                        const findMatchingUsernamne = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/user/getListOfUsernames`, config)

                        if (findMatchingUsernamne?.data && !isEmpty(findMatchingUsernamne?.data)) {
                            const matchingUsername = findMatchingUsernamne?.data.filter((item) => item.username === inputValues?.username);

                            if (isEmpty(matchingUsername)) {

                                const payLoad = {
                                    uid: inputValues?.userID,
                                    name: inputValues?.name,
                                    surname: inputValues?.surname,
                                    email_address: inputValues?.email,
                                    username: inputValues?.username,
                                    password: inputValues?.password,
                                    telegram_number: inputValues?.telegramNum,
                                    contact_number: inputValues?.contact,
                                    internal_employee: state?.status,
                                    active: state?.activeStatus,
                                    business_address: state?.bAddress,
                                    home_address: state?.dAddress,
                                    creation_date: moment().format('YYYY-MM-DD H-mm-ss'),
                                    user_type_uid: state?.userType?.uid,
                                    role_uid: state?.role?.uid,
                                    division_uid: state?.division?.uid,
                                };

                                const createUser = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/user/create`, payLoad, config)

                                if (createUser?.data?.message === 'Success') {

                                    try {

                                        for (let x = 0; x < state.assignedStores.length; x++) {
                                            state.tempStoresToSend.push(state.assignedStores[x].uid)
                                        }

                                        const payLoad = {
                                            stores: state?.tempStoresToSend
                                        }

                                        const assignStoreToUser = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/user/setAssignedStoresForUser/${inputValues?.userID}`, payLoad, config)

                                        if (assignStoreToUser?.data?.message === 'Success') {

                                            toast(`User created successfully`, {
                                                icon: '👋',
                                                style: {
                                                    borderRadius: '10px',
                                                    background: '#333',
                                                    color: '#fff',
                                                },
                                                duration: 3000,
                                            });

                                            //clear values and reset states
                                            clearValues()

                                            setState({
                                                ...state,
                                                role: '',
                                                division: '',
                                                userType: '',
                                                assignedStores: '',
                                                status: 1,
                                                activeStatus: 1,
                                                errorID: '',
                                                errorUsername: '',
                                                bAddress: '',
                                                dAddress: '',
                                                isLoading: false,
                                                operationCompleted: 'completed'
                                            })
                                        }

                                        else {
                                            setState({ ...state, isLoading: false })

                                            toast('Failed to create user, please try again', {
                                                icon: '❌',
                                                style: {
                                                    borderRadius: '10px',
                                                    background: '#333',
                                                    color: '#fff',
                                                },
                                                duration: 3000,
                                            });
                                        }

                                    }
                                    catch (error) {

                                        setState({ ...state, isLoading: false });

                                        toast('Error creating user, try again later', {
                                            icon: '❌',
                                            style: {
                                                borderRadius: '10px',
                                                background: '#333',
                                                color: '#fff',
                                            },
                                            duration: 3000,
                                        });
                                    }
                                } else {
                                    setState({ ...state, isLoading: false })

                                    toast('Failed to create user, please try again', {
                                        icon: '❌',
                                        style: {
                                            borderRadius: '10px',
                                            background: '#333',
                                            color: '#fff',
                                        },
                                        duration: 3000,
                                    });
                                }


                            }
                            else {
                                setState({ ...state, isLoading: false, errorUsername: true, errorID: false })

                                toast('Username already exists, please choose a different one', {
                                    icon: '❌',
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                    },
                                    duration: 3000,
                                });
                            }
                        }
                    }
                    else {
                        setState({ ...state, isLoading: false, errorID: true, errorUsername: false })

                        toast('User ID already exists, please choose a different one', {
                            icon: '❌',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                            duration: 3000,
                        });
                    }
                }
            }
            catch (error) {
                setState({ ...state, isLoading: false })

                toast('Error creating user, try again later', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });
            }
        }
    }

    return (
        <div className="w-full md:h-[83.5vh] overflow-y-scroll lg:overflow-hidden mb-20 lg:mb-0">
            <form className='w-full flex flex-col justify-between items-start gap-4 h-full' onSubmit={createUser}>
                <div className="flex flex-col justify-start gap-4">
                    <div className='flex items-center justify-between'>
                        <div className='flex flex-col justify-start gap-0 w-full'>
                            <p className="text-purple font-semibold text-2xl lg:text-3xl">
                                Create Users
                            </p>
                        </div>
                    </div>
                    <div className="flex items-start justify-start flex-wrap gap-2">
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">User ID</label>
                            <input
                                type='text'
                                name="userID"
                                placeholder="LS27"
                                onChange={handleChange}
                                value={inputValues.userID || ''}
                                className={`w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow ${state.errorID && 'border border-red'}`}
                            />
                            {errors.userID && <span className="text-[var(--red)] text-sm">{errors.userID[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Name</label>
                            <input
                                type='text'
                                name="name"
                                placeholder="John"
                                onChange={handleChange}
                                value={inputValues.name || ''}
                                className="w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow"
                            />
                            {errors.name && <span className="text-[var(--red)] text-sm">{errors.name[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Surname</label>
                            <input
                                type='text'
                                name="surname"
                                placeholder="Doe"
                                onChange={handleChange}
                                value={inputValues.surname || ''}
                                className="w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow"
                            />
                            {errors.surname && <span className="text-[var(--red)] text-sm">{errors.surname[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Email Address</label>
                            <input
                                type='text'
                                name="email"
                                placeholder="jd@example.com"
                                onChange={handleChange}
                                value={inputValues.email || ''}
                                className="w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow"
                            />
                            {errors.email && <span className="text-[var(--red)] text-sm">{errors.email[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Username</label>
                            <input
                                type='text'
                                name="username"
                                placeholder="JDoe23"
                                onChange={handleChange}
                                value={inputValues.username || ''}
                                className={`w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow ${state.errorID && 'border border-red'}`}
                            />
                            {errors.username && <span className="text-[var(--red)] text-sm">{errors.username[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Password</label>
                            <div className="relative flex items-center justify-between">
                                <input
                                    type={state.showPswd ? 'text' : 'password'}
                                    name="password"
                                    placeholder="************************"
                                    onChange={handleChange}
                                    value={inputValues.password || ''}
                                    className="w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow"
                                />
                                <img src={state.showPswd ? eye : eyeSlash} alt='' loading='lazy' className='w-6 absolute right-3 cursor-pointer' onClick={togglePswd} />
                            </div>
                            {errors.password && <span className="text-[var(--red)] text-sm">{errors.password[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Contact</label>
                            <input
                                type='contact'
                                name="contact"
                                placeholder="011 234 8638"
                                onChange={handleChange}
                                value={inputValues.contact || ''}
                                className="w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow"
                            />
                            {errors.contact && <span className="text-[var(--red)] text-sm">{errors.contact[0]}</span>}
                        </div>
                        <div className='flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300'>
                            <label className='text-base font-medium text-gray-500'>{state.status === 0 ? 'External' : 'Internal'}</label>
                            <label className='relative inline-block w-20 h-9 rounded-full'>
                                <input type='checkbox' className='peer opacity-0 w-0 h-0' defaultChecked='active' onClick={toggleStatus} />
                                <span className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-${state.status === 0 ? 'red' : 'green'} rounded-full duration-300 before:content-[''] before:absolute before:w-7 before:h-7 before:bottom-1 before:left-1 before:rounded-full before:bg-white before:duration-300 peer-checked:before:translate-x-11 peer-checked:bg-green`} ></span>
                            </label>
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Role</label>
                            <Roles handleRoles={handleRoles} operationStatus={state.operationCompleted} />
                            {errors.role && <span className="text-[var(--red)] text-sm">{errors.role[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Division</label>
                            <Division handleDivision={handleDivision} operationStatus={state.operationCompleted} />
                            {errors.division && <span className="text-[var(--red)] text-sm">{errors.division[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">User Type</label>
                            <UserType handleUserTypes={handleUserTypes} operationStatus={state.operationCompleted} />
                            {errors.userType && <span className="text-[var(--red)] text-sm">{errors.userType[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Assign Stores</label>
                            <Stores handleAssignedStores={handleAssignedStores} operationStatus={state.operationCompleted} />
                            {errors.assignedStores && <span className="text-[var(--red)] text-sm">{errors.assignedStores[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Business Address</label>
                            <PlacesAutocomplete
                                value={state.bAddress}
                                onChange={(value) => setState({ ...state, bAddress: value })}
                                onSelect={handleBusinessAddress}>

                                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                placeholder: "Enter your address",
                                                className: "w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow",
                                            })}
                                        />
                                        <div className="">
                                            {suggestions.map((suggestion) => {
                                                const style = { backgroundColor: suggestion.active ? "#e3e3e3" : "#ffffff", cursor: "pointer", };

                                                return (
                                                    <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })}>
                                                        <p className="text-sm text-gray-500 font-medium">{suggestion.description}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}

                            </PlacesAutocomplete>
                            {errors.business_address && <span className="text-[var(--red)] text-sm">{errors.business_address[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Delivery Address</label>
                            <PlacesAutocomplete
                                value={state.dAddress}
                                onChange={(value) => setState({ ...state, dAddress: value })}
                                onSelect={handleDeliveryAddress}>

                                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                placeholder: "Enter your address",
                                                className: "w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow",
                                            })}
                                        />
                                        <div className="">
                                            {suggestions.map((suggestion) => {
                                                const style = { backgroundColor: suggestion.active ? "#e3e3e3" : "#ffffff", cursor: "pointer", };

                                                return (
                                                    <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })}>
                                                        <p className="text-sm text-gray-500 font-medium">{suggestion.description}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}

                            </PlacesAutocomplete>
                            {errors.home_address && <span className="text-[var(--red)] text-sm">{errors.home_address[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Telegram Number</label>
                            <input
                                type='number'
                                name="telegramNum"
                                placeholder="4158406514"
                                onChange={handleChange}
                                value={inputValues.telegramNum || ''}
                                className={`w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow ${state.errorID && 'border border-red'}`}
                            />
                        </div>
                        <div className='flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300'>
                            <label className='text-base font-medium text-gray-500'>{state.activeStatus === 0 ? 'In-Active' : 'Active'}</label>
                            <label className='relative inline-block w-20 h-9 rounded-full'>
                                <input type='checkbox' className='peer opacity-0 w-0 h-0' defaultChecked='active' onClick={toggleActive} />
                                <span className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-${state.activeStatus === 0 ? 'red' : 'green'} rounded-full duration-300 before:content-[''] before:absolute before:w-7 before:h-7 before:bottom-1 before:left-1 before:rounded-full before:bg-white before:duration-300 peer-checked:before:translate-x-11 peer-checked:bg-green`} ></span>
                            </label>
                        </div>
                        <button type='submit' className='bg-green text-white py-2 px-10 rounded cursor-pointer text-[1.2rem] mt-2'>
                            {
                                state.isLoading ?
                                    <p className='flex items-center justify-center gap-2'>
                                        Creating
                                        <img src={loadingIcon} alt='Loading' loading='lazy' className='w-6 animate-spin' />
                                    </p>
                                    :
                                    <p className='flex items-center justify-center gap-2'>
                                        Create User
                                    </p>
                            }
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}
