//components
import { Layout } from "../layout/layout"
import { Tabs } from '../components/tabs/Tabs'


//tab components
import { Commercial } from "../modules/approvals/commercial";
import { Financial } from "../modules/approvals/financial";
import { Stock } from "../modules/approvals/stock";
import { Picking } from "../modules/approvals/picking";
import { LoadingStage } from "../modules/approvals/loading";
import { Delivery } from "../modules/approvals/delivery";

const tabs = [
    {
        label: 'Commercial',
        content: <Commercial />,
    },
    {
        label: 'Financial',
        content: <Financial />,
    },
    {
        label: 'Stock',
        content: <Stock />,
    },
    {
        label: 'Picking',
        content: <Picking />,
    },
    {
        label: 'Loading',
        content: <LoadingStage />,
    },
    {
        label: 'Delivery',
        content: <Delivery />,
    },
];

export const Approvals = () => {

    return (
        <Layout>
            <div className="p-2">
                <Tabs tabs={tabs} />
            </div>
        </Layout>
    )
}
