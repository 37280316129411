import { Tabs } from "../components/tabs/Tabs";

//components
import { Layout } from "../layout/layout"

//tab components
import { ProfileSettings } from "../modules/profile/profile-settings";
import { UserDetails } from "../modules/profile/user-details";

export const Profile = () => {

    const tabs = [
        {
            label: 'User Details',
            content: <UserDetails />,
        },
        {
            label: 'Settings',
            content: <ProfileSettings />,
        }
    ];

    return (
        <Layout>
            <div className="w-[100%] h-full flex flex-col gap-4 rounded p-2">
                <Tabs tabs={tabs} />
            </div>
        </Layout>
    )
}
