import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useState, useEffect } from 'react';

const vehicleData = (vehicle) => {
    const { license_number, make, model, uid, active } = vehicle;

    return {
        uid,
        make,
        model,
        active,
        license_number,
    };
};

export const AllVehicles = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = JSON.parse(sessionStorage.getItem('session'));

                const config = {
                    headers: {
                        'token': user?.token,
                    },
                };

                const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/vehicle/active`, config);

                if (response.status === 200) {
                    const vehiclesData = response.data.map(vehicleData);

                    setData(vehiclesData);
                }
            } catch (error) {
                toast('Please refresh the page', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });
            }
        };

        fetchData();
    }, []);

    if (data.length === 0) {
        return null;
    }

    return data;
};
