//components
import { Layout } from "../layout/layout";
import { Tabs } from "../components/tabs/Tabs";

//tab components
import { Cancelled } from "../modules/orders/cancelled";
import { Completed } from "../modules/orders/completed";
import { CreateNew } from "../modules/orders/create-new";
import { Drafts } from "../modules/orders/drafts";
import { MyOrders } from "../modules/orders/my-orders";
import { Processing } from "../modules/orders/processing";

const tabs = [
    {
        label: 'Drafts',
        content: <Drafts />,
    },
    {
        label: 'Create Order',
        content: <CreateNew />,
    },
    {
        label: 'My Orders',
        content: <MyOrders />,
    },
    {
        label: 'Processing',
        content: <Processing />,
    },
    {
        label: 'Completed',
        content: <Completed />,
    },
    {
        label: 'Cancelled',
        content: <Cancelled />,
    },
];

export const Orders = () => {

    return (
        <Layout>
            <div className="p-2">
                <Tabs tabs={tabs} />
            </div>
        </Layout>
    );
};
