import { useState } from "react";
import { toast } from 'react-hot-toast';
import axios from 'axios';

//hooks
import { useQuery } from "../../../hooks/useQuery"

//components
import { isEmpty } from "lodash";
import { Modal } from "./settings-modal/map-modal";

//request configuration
import { config } from '../../../utils/app-settings';

export const Map = () => {

    const [state, setState] = useState({
        isLoading: '',
        isVisible: null,
    })

    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/wand/wanddata`, 'get', 2000);

    if (isLoading) {
        return;
    }

    if (error) {
        return;
    }

    if (isEmpty(data)) {
        return;
    }

    const addProfile = async (inputValues) => {
        if (!isEmpty(inputValues?.profile) && !isEmpty(inputValues?.number)) {
            try {

                const updateTarget = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/wand/insertwandprofile/${inputValues?.profile}/${inputValues?.number}`, {}, config)

                if (updateTarget?.data?.message === 'Success') {

                    toast(`Wand profile added successfully`, {
                        icon: '👋',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });

                    closeModal()

                    setTimeout(() => {
                        window.location.reload()
                    }, 3100);

                } else {

                    toast('Failed to add wand profile, please try again', {
                        icon: '❌',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });

                    closeModal()

                    setTimeout(() => {
                        window.location.reload()
                    }, 3100);
                }
            }
            catch (error) {

                toast('Error adding wand profile, try again later', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                closeModal()

                setTimeout(() => {
                    window.location.reload()
                }, 3100);
            }
        } else {
            toast('Error please fill in all the fields', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }


    };

    const deleteProfile = async (Profile_UID) => {

        try {

            const profileDelete = await axios.delete(`${process.env.REACT_APP_ENDPOINT}/api/v1/wand/deletewandprofile/${Profile_UID}`, config)

            if (profileDelete?.data?.message === 'Success') {

                setState({ ...state, isLoading: false });

                toast(`Profile deleted successfully`, {
                    icon: '👋',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                setTimeout(() => {
                    window.location.reload()
                }, 3100);

            } else {

                setState({ ...state, isLoading: false });

                toast('Failed to delete profile, please try again', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                setTimeout(() => {
                    window.location.reload()
                }, 3100);
            }
        }
        catch (error) {

            setState({ ...state, isLoading: false });

            toast('Error deleting profile, try again later', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });

            setTimeout(() => {
                window.location.reload()
            }, 3100);
        }
    }

    const openModal = (property) => setState({ ...state, isVisible: !state.isVisible, product: property });

    const closeModal = () => setState((prevState) => ({ ...prevState, isVisible: false }));

    return (
        <div className="w-full h-full flex flex-col justify-start gap-4">
            <div className='text-lg' >
                <p className="text-gray-500 text-xl font-medium">Delivery Wand</p>
            </div>
            <hr className="border border-grey-darker w-full" />
            <div className="flex items-start justify-start w-full md:w-5/12">
                <label className="text-base font-bold w-1/3" >Profile</label>
                <label className="text-base font-bold w-1/3" >Contact</label>
                <label className="text-base font-bold w-1/3" >Action</label>
            </div>
            <div className="flex flex-col justify-start gap-2 md:w-5/12">
                {
                    data?.map((setting, index) =>
                        <div className="w-full flex items-center justify-between">
                            <label className="text-base font-medium text-gray-500 w-1/3" >{setting?.profile}</label>
                            <label className="text-base font-medium text-gray-500 w-1/3" >{setting?.phone_number}</label>
                            <div className="text-base font-medium text-gray-500 w-1/3" >
                                <button onClick={() => { deleteProfile(setting?.uid) }} className="border border-red px-6 py-1 text-sm text-red hover:bg-red hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer">Delete</button>
                            </div>
                        </div>
                    )
                }
            </div>
            <button onClick={openModal} className="bg-green text-white w-32 py-1 px-2 rounded cursor-pointer text-base lg:ease-in-out duration-300">Add Profile</button>
            {state.isVisible && <Modal closeModal={closeModal} addProfile={addProfile} />}
        </div>
    )
}
