import { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";

import { useMap } from "react-leaflet";
import { isEmpty } from "lodash";

//icons
import truckIcon from '../../assets/icons/truck.svg';
import destinationIcon from '../../assets/icons/store-marker.svg';
import truckMarkericon from '../../assets/icons/truckMarker.svg';
import repMarkericon from '../../assets/icons/userlocation.svg';

const truckMarker = L.icon({
    iconUrl: truckIcon,
    iconSize: [33, 33],
    iconAnchor: [16, 32],
});

const destinationMarker = L.icon({
    iconUrl: destinationIcon,
    iconSize: [33, 33],
    iconAnchor: [16, 32],
});

const historyMarker = L.icon({
    iconUrl: truckMarkericon,
    iconSize: [33, 33],
    iconAnchor: [16, 32],
});

const RepMarker = L.icon({
    iconUrl: repMarkericon,
    iconSize: [33, 33],
});

export const Routing = ({ wayPoints, routeSummary, type }) => {
    const map = useMap();
    const routingControlRef = useRef(null);

    const tripCoordinates = !isEmpty(wayPoints) && wayPoints?.map(coord => `${coord.latitude}, ${coord.longitude}`);

    const filterCOORDS = (data) => {
        if (tripCoordinates && !isEmpty(tripCoordinates)) {
            const firstValue = data[0];
            const lastValue = data[data.length - 1];
            const totalLength = data.length;
            const interval = Math.floor(totalLength / 100);

            const result = [firstValue];

            for (let i = 1; i < 10; i++) {
                const index = i * interval;
                result.push(data[index]);
            }

            result.push(lastValue);

            return result;
        }
    }

    const processedCOORDS = filterCOORDS(tripCoordinates);

    const tripWaypoints = processedCOORDS?.map(coord => {
        const [lat, lng] = coord.split(',').map(parseFloat);
        return L.latLng(Number(lat), Number(lng));
    });

    useEffect(() => {
        if (map && !isEmpty(tripWaypoints)) {
            if (routingControlRef.current) {
                routingControlRef.current.remove();
            }

            let routingOptions = {
                waypoints: tripWaypoints,
                routeWhileDragging: true,
                lineOptions: {
                    styles: [
                        {
                            color: 'var(--purple)',
                            opacity: 0.8,
                            weight: 3
                        },
                    ]
                },
                driving: true,
                addWaypoints: false,
                draggableWaypoints: true,
                fitSelectedRoutes: true,
                showAlternatives: true,
                useZoomParameter: true,
                autoRoute: true,
            };

            if (type === 'tracking') {
                routingOptions = {
                    ...routingOptions,
                    createMarker: function (i, waypoint, n) {
                        if (i === 0) {
                            return L.marker(waypoint.latLng, { icon: truckMarker });
                        }

                        if (i === n - 1) {
                            return L.marker(waypoint.latLng, { icon: destinationMarker });
                        }
                    }
                };
            }
            else if (type === 'rep') {
                routingOptions = {
                    ...routingOptions,
                    createMarker: function (i, waypoint, n) {
                        return L.marker(waypoint.latLng, { icon: RepMarker });
                    }
                };
            }
            else if (type === 'history') {
                routingOptions = {
                    ...routingOptions,
                    createMarker: function (i, waypoint, n) {
                        return L.marker(waypoint.latLng, { icon: historyMarker });
                    }
                };
            }

            const routingControl = L.Routing.control(routingOptions).addTo(map);

            if (type === 'tracking') {
                routingControl.on('routesfound', (e) => {
                    const { routes } = e;
                    if (!isEmpty(routes)) {
                        const { summary } = routes[0];

                        const { totalDistance, totalTime } = summary;

                        routeSummary({ totalDistance, totalTime });
                    }
                });
            }
        }
    }, [map, tripWaypoints, routeSummary, type]);

    return null;
};