import axios from 'axios';
import { toast } from 'react-hot-toast';
import * as turf from '@turf/turf';
import moment from 'moment';

//configuration
import { dcCOORDS } from '../../utils/app-settings'
import { isEmpty } from 'lodash';
import { pdfResolver } from './pdf-resolver';

export const getContact = async (company_name) => {
    const session = JSON.parse(sessionStorage.getItem('session'));

    const config = {
        headers: {
            'token': session?.token,
            mode: 'no-cors'
        }
    };

    const userDetails = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/client/getclientscontactsbyname/${company_name}`, config)
    return (userDetails?.data)
}

const approveMessage = (name, orderNumber, value, stage) => {
    return `Hello ${name}, your order #${orderNumber} to the value of R${value}, has cleared ${stage} approval. We'll update you on the progress of your order. Team Plus.`;
};

const completeMessage = (name, orderNumber, value) => {
    return `Hello ${name}, your order #${orderNumber} to the value of R${value}, has been delivered. Thank you for your order. Team Plus.`;
};

const revertMessage = (name, orderNumber, value, stage) => {
    return `Hello ${name}, your order #${orderNumber} to the value of R${value}, has been returned back to the ${stage} approval stage. We'll update you on the progress of your order. Team Plus.`;
};

const cancelMessage = (name, orderNumber, value, index) => {

    let stage = '';

    switch (index) {
        case 1:
            stage = 'commercial';
            break;

        case 2:
            stage = 'finance';
            break;

        case 3:
            stage = 'stock';
            break;

        case 4:
            stage = 'picking';
            break;

        case 5:
            stage = 'loading';
            break;

        case 6:
            stage = 'delivery';
            break;

        default:
            stage = 'Unknown Stage';
            break;
    }

    return `Hello ${name}, your order #${orderNumber} to the value of R${value}, has been cancelled in the ${stage} approval stage. We'll update you on the reason for the cancellation in due course. Team Plus.`;
};

const pauseMessage = (name, orderNumber, value, index) => {

    let stage = '';

    switch (index) {
        case 1:
            stage = 'commercial';
            break;

        case 2:
            stage = 'finance';
            break;

        case 3:
            stage = 'stock';
            break;

        case 4:
            stage = 'picking';
            break;

        case 5:
            stage = 'loading';
            break;

        case 6:
            stage = 'delivery';
            break;

        default:
            stage = 'Unknown Stage';
            break;
    }

    return `Hello ${name}, your order #${orderNumber} to the value of R${value}, is on hold in the ${stage} approval stage. We'll update you on the progress of your order. Team Plus.`;
};

//cancel orders
export const cancelOrder = async (invoice, notify, index) => {
    if (invoice) {
        try {
            const session = JSON.parse(sessionStorage.getItem('session'));

            const config = {
                headers: {
                    'token': session?.token,
                    mode: 'no-cors'
                }
            };

            const response = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/OrderStage/cancelled/${invoice?.uid}`, {}, config)

            if (response?.data?.message === 'Success') {

                if (notify) {
                    let data = await getContact(invoice?.company_name)

                    if (data[0]?.contact_number) {

                        const payLoad = {
                            message: cancelMessage(data[0]?.name, invoice?.uid, invoice?.amount, index),
                            destination: data[0]?.contact_number
                        }
                        const sms = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/sms/`, payLoad, config)

                        if (sms?.data === 'Success') {

                            toast(`Succesfully cancelled order No ${invoice?.uid} and sent sms to ${invoice?.company_name}`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });



                        } else {
                            toast(`Succesfully cancelled order No ${invoice?.uid} but failed to send sms to ${invoice?.company_name}`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });


                        }

                    } else {
                        toast(`Succesfully cancelled order No ${invoice?.uid} but ${invoice?.company_name} has no contact person`, {
                            icon: '✅',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                            duration: 3000,
                        });

                    }

                } else {

                    toast(`Succesfully cancelled order No ${invoice?.uid}`, {
                        icon: '✅',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });
                }
            }
        }
        catch (err) {
            toast(`Failed to cancel order No ${invoice?.uid}, please try again`, {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }

    }
};

//pause orders
export const pauseOrder = async (invoice, notify, index) => {
    if (invoice) {
        try {
            const session = JSON.parse(sessionStorage.getItem('session'));

            const config = {
                headers: {
                    'token': session?.token,
                    mode: 'no-cors'
                }
            };

            const response = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/OrderStage/onhold/${invoice?.uid}`, {}, config)

            if (response?.data?.message === 'Success') {

                if (notify) {

                    let data = await getContact(invoice?.company_name)

                    if (data[0]?.contact_number) {

                        const payLoad = {
                            message: pauseMessage(data[0]?.name, invoice?.uid, invoice?.amount, index),
                            destination: data[0]?.contact_number
                        }

                        const sms = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/sms/`, payLoad, config)

                        if (sms?.data === 'Success') {

                            toast(`Succesfully put order No ${invoice?.uid} on hold and sent sms to ${invoice?.company_name}`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });



                        } else {
                            toast(`Succesfully put order No ${invoice?.uid} on hold but failed to send sms to ${invoice?.company_name}`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });


                        }

                    } else {
                        toast(`Succesfully put order No ${invoice?.uid} on hold but ${invoice?.company_name} has no contact person`, {
                            icon: '✅',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                            duration: 3000,
                        });

                    }

                } else {

                    toast(`Succesfully put order No ${invoice?.uid} on hold`, {
                        icon: '✅',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });
                }
            }
        }
        catch (err) {
            toast(`Failed to put order No ${invoice?.uid}, on hold, please try again`, {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }

    }
}

//revert orders
export const revertToCommercial = async (invoice, notify) => {
    if (invoice) {
        try {
            const session = JSON.parse(sessionStorage.getItem('session'));

            const config = {
                headers: {
                    'token': session?.token,
                    mode: 'no-cors'
                }
            };

            const response = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/OrderStage/Commercial/${invoice?.uid}`, {}, config)

            if (response?.data?.message === 'Success') {

                if (notify) {

                    let data = await getContact(invoice?.company_name)

                    if (data[0]?.contact_number) {

                        const payLoad = {
                            message: revertMessage(data[0]?.name, invoice?.uid, invoice?.amount, "commercial"),
                            destination: data[0]?.contact_number
                        }

                        const sms = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/sms/`, payLoad, config)

                        if (sms?.data === 'Success') {

                            toast(`Succesfully reverted order No ${invoice?.uid} to commercial and sent sms to ${invoice?.company_name}`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });



                        } else {
                            toast(`Succesfully reverted order No ${invoice?.uid} to commercial but failed to send sms to ${invoice?.company_name}`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });


                        }

                    } else {
                        toast(`Succesfully reverted order No ${invoice?.uid} to commercial but ${invoice?.company_name} has no contact person`, {
                            icon: '✅',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                            duration: 3000,
                        });

                    }

                } else {

                    toast(`Succesfully reverted order No ${invoice?.uid} to commercial`, {
                        icon: '✅',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });
                }
            }
        }
        catch (err) {
            toast(`Failed to revert order No ${invoice?.uid}, please try again`, {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }

    }
}

export const revertToFinance = async (invoice, notify) => {
    if (invoice) {
        try {
            const session = JSON.parse(sessionStorage.getItem('session'));

            const config = {
                headers: {
                    'token': session?.token,
                    mode: 'no-cors'
                }
            };

            const response = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/OrderStage/Finance/${invoice?.uid}`, {}, config)

            if (response?.data?.message === 'Success') {

                if (notify) {

                    let data = await getContact(invoice?.company_name)

                    if (data[0]?.contact_number) {

                        const payLoad = {
                            message: revertMessage(data[0]?.name, invoice?.uid, invoice?.amount, "finance"),
                            destination: data[0]?.contact_number
                        }

                        const sms = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/sms/`, payLoad, config)

                        if (sms?.data === 'Success') {

                            toast(`Succesfully reverted order No ${invoice?.uid} to finance and sent sms to ${invoice?.company_name}`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });



                        } else {
                            toast(`Succesfully reverted order No ${invoice?.uid} to finance but failed to send sms to ${invoice?.company_name}`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });


                        }

                    } else {
                        toast(`Succesfully reverted order No ${invoice?.uid} to financial but ${invoice?.company_name} has no contact person`, {
                            icon: '✅',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                            duration: 3000,
                        });

                    }

                } else {

                    toast(`Succesfully reverted order No ${invoice?.uid} to finance`, {
                        icon: '✅',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });
                }
            }
        }
        catch (err) {
            toast(`Failed to revert order No ${invoice?.uid}, please try again`, {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }

    }
}

export const revertToStock = async (invoice, notify) => {
    if (invoice) {
        try {
            const session = JSON.parse(sessionStorage.getItem('session'));

            const config = {
                headers: {
                    'token': session?.token,
                    mode: 'no-cors'
                }
            };

            const response = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/OrderStage/Stock/${invoice?.uid}`, {}, config)

            if (response?.data?.message === 'Success') {

                if (notify) {

                    let data = await getContact(invoice?.company_name)

                    if (data[0]?.contact_number) {

                        const payLoad = {
                            message: revertMessage(data[0]?.name, invoice?.uid, invoice?.amount, "stock"),
                            destination: data[0]?.contact_number
                        }

                        const sms = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/sms/`, payLoad, config)

                        if (sms?.data === 'Success') {

                            toast(`Succesfully reverted order No ${invoice?.uid} to stock and sent sms to ${invoice?.company_name}`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });



                        } else {
                            toast(`Succesfully reverted order No ${invoice?.uid} to stock but failed to send sms to ${invoice?.company_name}`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });


                        }

                    } else {
                        toast(`Succesfully reverted order No ${invoice?.uid} to financial but ${invoice?.company_name} has no contact person`, {
                            icon: '✅',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                            duration: 3000,
                        });

                    }

                } else {

                    toast(`Succesfully reverted order No ${invoice?.uid} to stock`, {
                        icon: '✅',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });
                }
            }
        }
        catch (err) {
            toast(`Failed to revert order No ${invoice?.uid}, please try again`, {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }

    }
}

export const revertToPicking = async (invoice, notify) => {
    if (invoice) {
        try {
            const session = JSON.parse(sessionStorage.getItem('session'));

            const config = {
                headers: {
                    'token': session?.token,
                    mode: 'no-cors'
                }
            };

            const response = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/OrderStage/picking/${invoice?.uid}`, {}, config)

            if (response?.data?.message === 'Success') {

                if (notify) {

                    let data = await getContact(invoice?.company_name)

                    if (data[0]?.contact_number) {

                        const payLoad = {
                            message: revertMessage(data[0]?.name, invoice?.uid, invoice?.amount, "picking"),
                            destination: data[0]?.contact_number
                        }

                        const sms = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/sms/`, payLoad, config)

                        if (sms?.data === 'Success') {

                            toast(`Succesfully reverted order No ${invoice?.uid} to picking and sent sms to ${invoice?.company_name}`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });



                        } else {
                            toast(`Succesfully reverted order No ${invoice?.uid} to picking but failed to send sms to ${invoice?.company_name}`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });


                        }

                    } else {
                        toast(`Succesfully reverted order No ${invoice?.uid} to picking but ${invoice?.company_name} has no contact person`, {
                            icon: '✅',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                            duration: 3000,
                        });

                    }

                } else {

                    toast(`Succesfully reverted order No ${invoice?.uid} to picking`, {
                        icon: '✅',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });
                }
            }
        }
        catch (err) {
            toast(`Failed to revert order No ${invoice?.uid}, please try again`, {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }

    }
}

export const revertToLoading = async (invoice, notify) => {
    if (invoice) {
        try {
            const session = JSON.parse(sessionStorage.getItem('session'));

            const config = {
                headers: {
                    'token': session?.token,
                    mode: 'no-cors'
                }
            };

            const deleteTempOrder = await axios.delete(`${process.env.REACT_APP_ENDPOINT}/api/v1/order/deletetmporder/${invoice?.uid}`, config)

            if (deleteTempOrder?.data?.message === 'Success') {

                const setToLoading = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/OrderStage/Loading/${invoice?.uid}`, {}, config)

                if (setToLoading?.data?.message === 'Success') {

                    if (notify) {

                        let data = await getContact(invoice?.company_name)

                        if (data[0]?.contact_number) {

                            const payLoad = {
                                message: revertMessage(data[0]?.name, invoice?.uid, invoice?.amount, "loading"),
                                destination: data[0]?.contact_number
                            }

                            const sms = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/sms/`, payLoad, config)

                            if (sms?.data === 'Success') {

                                toast(`Succesfully reverted order No ${invoice?.uid} to loading and sent sms to ${invoice?.company_name}`, {
                                    icon: '✅',
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                    },
                                    duration: 3000,
                                });

                            } else {
                                toast(`Succesfully reverted order No ${invoice?.uid} to loading but failed to send sms to ${invoice?.company_name}`, {
                                    icon: '✅',
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                    },
                                    duration: 3000,
                                });
                            }

                        } else {
                            toast(`Succesfully reverted order No ${invoice?.uid} to loading but ${invoice?.company_name} has no contact person`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });


                        }

                    } else {

                        toast(`Succesfully reverted order No ${invoice?.uid} to loading`, {
                            icon: '✅',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                            duration: 3000,
                        });

                    }
                }
            }
        }
        catch (err) {
            toast(`Failed to revert order No ${invoice?.uid}, please try again`, {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }

    }
}

//approve orders
export const approveToFinance = async (invoice, notify) => {

    if (invoice) {

        try {
            const session = JSON.parse(sessionStorage.getItem('session'));

            const config = {
                headers: {
                    'token': session?.token,
                    mode: 'no-cors'
                }
            };

            const response = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/OrderStage/Finance/${invoice?.uid}`, {}, config)

            if (response?.data?.message === 'Success') {

                if (notify) {

                    let data = await getContact(invoice?.company_name)

                    if (data[0]?.contact_number) {

                        const payLoad = {
                            message: approveMessage(data[0]?.name, invoice?.uid, invoice?.amount, "commercial"),
                            destination: data[0]?.contact_number
                        }

                        const sms = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/sms/`, payLoad, config)

                        if (sms?.data === 'Success') {

                            toast(`Succesfully approved order No ${invoice?.uid} to financial and sent sms to ${invoice?.company_name}`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });

                        } else {
                            toast(`Succesfully approved order No ${invoice?.uid} to financial but failed to send sms to ${invoice?.company_name}`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });


                        }
                    } else {
                        toast(`Succesfully approved order No ${invoice?.uid} to financial but ${invoice?.company_name} has no contact person`, {
                            icon: '✅',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                            duration: 3000,
                        });

                    }

                } else {
                    toast(`Succesfully approved order No ${invoice?.uid} to financial`, {
                        icon: '✅',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });
                }
            }
        }
        catch (err) {
            toast(`Failed to approve order No ${invoice?.uid}, please try again`, {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }

    }
}

export const approveToStock = async (invoice, notify) => {
    if (invoice) {
        try {
            const session = JSON.parse(sessionStorage.getItem('session'));

            const config = {
                headers: {
                    'token': session?.token,
                    mode: 'no-cors'
                }
            };

            const setStockProvisioned = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/OrderStage/setQuantityStockProvisionedToQuantityOrdered/${invoice?.uid}`, {}, config)

            if (setStockProvisioned?.data?.message === 'Success') {

                const setToStock = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/OrderStage/Stock/${invoice?.uid}`, {}, config)

                if (setToStock?.data?.message === 'Success') {

                    if (notify) {

                        let data = await getContact(invoice?.company_name)

                        if (data[0]?.contact_number) {

                            const payLoad = {
                                message: approveMessage(data[0]?.name, invoice?.uid, invoice?.amount, "finance"),
                                destination: data[0]?.contact_number
                            }

                            const sms = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/sms/`, payLoad, config)
                            if (sms?.data === 'Success') {

                                toast(`Succesfully approved order No ${invoice?.uid} to stock and sent sms to ${invoice?.company_name}`, {
                                    icon: '✅',
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                    },
                                    duration: 3000,
                                });

                            } else {
                                toast(`Succesfully approved order No ${invoice?.uid} to stock but failed to send sms to ${invoice?.company_name}`, {
                                    icon: '✅',
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                    },
                                    duration: 3000,
                                });
                            }
                        } else {
                            toast(`Succesfully approved order No ${invoice?.uid} to stock but ${invoice?.company_name} has no contact person`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });


                        }

                    } else {

                        toast(`Succesfully approved order No ${invoice?.uid} to stock`, {
                            icon: '✅',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                            duration: 3000,
                        });

                    }
                }
            }
        }
        catch (err) {
            toast(`Failed to approve order No ${invoice?.uid}, please try again`, {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }

    }
}

export const approveToPicking = async (invoice, notify) => {

    if (invoice) {
        try {
            const session = JSON.parse(sessionStorage.getItem('session'));
            const pdfData = await pdfResolver(invoice);
            const config = {
                headers: {
                    'token': session?.token,
                    mode: 'no-cors'
                }
            };

            const setQuantityPicked = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/OrderStage/setQuantityPickedToQuantityStockProvisioned/${invoice?.uid}`, {}, config)

            if (setQuantityPicked?.data?.message === 'Success') {

                const setToPicking = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/OrderStage/Picking/${invoice?.uid}`, {}, config)

                if (setToPicking?.data?.message === 'Success') {

                    if (notify) {

                        let data = await getContact(invoice?.company_name)

                        if (data[0]?.contact_number) {

                            const payLoad = {
                                message: approveMessage(data[0]?.name, invoice?.uid, invoice?.amount, "stock"),
                                destination: data[0]?.contact_number
                            }

                            const sms = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/sms/`, payLoad, config)

                            if (sms?.data === 'Success') {

                                toast(`Succesfully approved order No ${invoice?.uid} to picking and sent sms to ${invoice?.company_name}`, {
                                    icon: '✅',
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                    },
                                    duration: 3000,
                                });

                            } else {
                                toast(`Succesfully approved order No ${invoice?.uid} to picking but failed to send sms to ${invoice?.company_name}`, {
                                    icon: '✅',
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                    },
                                    duration: 3000,
                                });
                            }

                        } else {
                            toast(`Succesfully approved order No ${invoice?.uid} to picking but ${invoice?.company_name} has no contact person`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });


                        }

                    }
                    const getTelegramNumber = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/ordermodel/gettelegramnum/${invoice?.uid}`, config)

                    if (getTelegramNumber?.data[0].telegram_number) {

                        try {

                            if (isEmpty(pdfData)) {

                                toast(`Succesfully approved order No ${invoice?.uid} to picking`, {
                                    icon: '✅',
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                    },
                                    duration: 3000,
                                });
                            } else {

                                const payLoad = {
                                    telegram_number: getTelegramNumber?.data[0].telegram_number,
                                    data: pdfData,
                                    fileName: invoice?.company_name + " Order:" + invoice?.uid,
                                    company_name: invoice?.company_name,
                                    orderNumber: invoice?.uid,
                                    createdBy: getTelegramNumber?.data[0].name
                                }

                                const sendTelegram = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/documents/sendtelegram`, payLoad, config);

                                if (sendTelegram?.data === 'OK') {

                                    toast(`Succesfully approved order No ${invoice?.uid} to picking`, {
                                        icon: '✅',
                                        style: {
                                            borderRadius: '10px',
                                            background: '#333',
                                            color: '#fff',
                                        },
                                        duration: 3000,
                                    });
                                } else {
                                    toast(`Failed to approve order No ${invoice?.uid}, please try again`, {
                                        icon: '❌',
                                        style: {
                                            borderRadius: '10px',
                                            background: '#333',
                                            color: '#fff',
                                        },
                                        duration: 3000,
                                    });
                                }
                            }

                        } catch (pdfError) {
                            toast(`Failed to send telegram to store for order No ${invoice?.uid}`, {
                                icon: '❌',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });
                        }

                    } else {

                        toast(`Succesfully approved order No ${invoice?.uid} to picking but failed to get telegram number`, {
                            icon: '✅',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                            duration: 3000,
                        });
                    }
                }
            }
        }
        catch (err) {
            toast(`Failed to approve order No ${invoice?.uid}, please try again`, {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }

    }
}

export const approveToLoading = async (invoice, notify) => {
    if (invoice) {
        try {
            const session = JSON.parse(sessionStorage.getItem('session'));

            const config = {
                headers: {
                    'token': session?.token,
                    mode: 'no-cors'
                }
            };

            const response = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/OrderStage/Loading/${invoice?.uid}`, {}, config)

            if (response?.data?.message === 'Success') {

                if (notify) {
                    let data = await getContact(invoice?.company_name)

                    if (data[0]?.contact_number) {

                        const payLoad = {
                            message: approveMessage(data[0]?.name, invoice?.uid, invoice?.amount, "picking"),
                            destination: data[0]?.contact_number
                        }
                        const sms = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/sms/`, payLoad, config)

                        if (sms?.data === 'Success') {

                            toast(`Succesfully approved order No ${invoice?.uid} to loading and sent sms to ${invoice?.company_name}`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });



                        } else {
                            toast(`Succesfully approved order No ${invoice?.uid} to loading but failed to send sms to ${invoice?.company_name}`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });


                        }

                    } else {
                        toast(`Succesfully approved order No ${invoice?.uid} to loading but ${invoice?.company_name} has no contact person`, {
                            icon: '✅',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                            duration: 3000,
                        });

                    }

                } else {

                    toast(`Succesfully approved order No ${invoice?.uid} to loading`, {
                        icon: '✅',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });
                }
            }
        }
        catch (err) {
            toast(`Failed to approve order No ${invoice?.uid}, please try again`, {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }

    }
}

export const ApproveToDelivery = async (invoice, notify, truck) => {

    if (invoice && invoice?.vehicle_uid) {
        const session = JSON.parse(sessionStorage.getItem('session'));

        const config = {
            headers: {
                'token': session?.token,
                mode: 'no-cors'
            }
        };

        if (notify) {

            let data = await getContact(invoice?.company_name)

            if (data[0]?.contact_number) {

                const payLoad = {
                    message: approveMessage(data[0]?.name, invoice?.uid, invoice?.amount, "loading"),
                    destination: data[0]?.contact_number
                }

                const sms = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/sms/`, payLoad, config)
                if (sms?.data === 'Success') {

                    toast(`Succesfully approved order No ${invoice?.uid} to delivery and sent sms to ${invoice?.company_name}`, {
                        icon: '✅',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });

                } else {
                    toast(`Succesfully approved order No ${invoice?.uid} to delivery but failed to send sms to ${invoice?.company_name}`, {
                        icon: '✅',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    })

                }

            } else {
                toast(`Succesfully approved order No ${invoice?.uid} to delivery but ${invoice?.company_name} has no contact person`, {
                    icon: '✅',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });
            }

        } else {

            toast(`Succesfully approved order No ${invoice?.uid} to delivery`, {
                icon: '✅',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }

        try {

            const extractTokenFromSession = () => {
                const session = JSON.parse(sessionStorage.getItem('session'));
                const token = session?.token || null;
                return token;
            };

            //declaration
            const apiGetAllWandOrders = axios.create({
                baseURL: `${process.env.REACT_APP_ENDPOINT}/api/v1/order/gettmporders/${invoice?.vehicle_uid}`,
                mode: "no-cors",
                headers: { token: extractTokenFromSession() },
            });

            const apiDeleteAllWandOrders = axios.create({
                baseURL: `${process.env.REACT_APP_ENDPOINT}/api/v1/order/deletetmporders/${invoice?.vehicle_uid}`,
                mode: "no-cors",
                headers: { token: extractTokenFromSession() },
            });

            const apiGetOrderDetails = axios.create({
                baseURL: `${process.env.REACT_APP_ENDPOINT}/api/v1/order/getorderbynumber/${invoice?.uid}`,
                mode: "no-cors",
                headers: { token: extractTokenFromSession() },
            });

            const apiGetClientInfo = axios.create({
                baseURL: `${process.env.REACT_APP_ENDPOINT}/api/v1/client/`,
                mode: "no-cors",
                headers: { token: extractTokenFromSession() },
            });

            const apiSendOrders = axios.create({
                baseURL: `${process.env.REACT_APP_ENDPOINT}/api/v1/order/inserttmporders/`,
                mode: "no-cors",
                headers: { token: extractTokenFromSession() },
            });

            const apiGetAllOrderByVehicleUID = axios.create({

                baseURL: `${process.env.REACT_APP_ENDPOINT}/api/v1/order/getordersbyvehicleuid/`,
                mode: "no-cors",
                headers: { token: extractTokenFromSession() },
            });

            const apiGetAllClientInfoByOrderNumber = axios.create({

                baseURL: `${process.env.REACT_APP_ENDPOINT}/api/v1/client/getclientbyordernumber/`,
                mode: "no-cors",
                headers: { token: extractTokenFromSession() },
            });

            let tempOrders = []

            apiGetAllWandOrders.get("/")
                .then((res) => {
                    if (!isEmpty(res.data)) {
                        tempOrders = res.data
                    }
                })
                .then(() => {
                    apiDeleteAllWandOrders.delete("/")
                })
                .then(() => {
                    apiGetOrderDetails.get("/")
                        .then((res) => {
                            var client_name = "";
                            var longitude = "";
                            var latitude = "";

                            apiGetClientInfo.get("/" + res.data[0].client_uid).then((res3) => {
                                client_name = res3.data[0].company_name
                                latitude = res3.data[0].latitude;
                                longitude = res3.data[0].longitude;
                            })
                                .then((res4) => {
                                    tempOrders.push({ uid: res.data[0].number, order_number: res.data[0].number, client_name: client_name, vehicle_uid: invoice?.vehicle_uid, latitude: latitude, longitude: longitude })

                                })
                                .then(() => {

                                    // TurfJS Here
                                    const distances = tempOrders?.map(point => {

                                        const dc = turf.point(dcCOORDS);

                                        const clientStore = turf.point([point.latitude, point.longitude]);

                                        const options = { units: 'kilometers' };
                                        const distance = turf.distance(dc, clientStore, options);

                                        return {
                                            Number: point.order_number,
                                            ClientName: point.client_name,
                                            VehicleUID: invoice?.vehicle_uid,
                                            distance: distance
                                        }

                                    });


                                    // sort the array based on the distance values
                                    const sortedPoints = distances.sort((a, b) => a.distance - b.distance);

                                    for (let x = 0; x < sortedPoints.length; x++) {
                                        //console.log(sortedPoints[x])
                                        // eslint-disable-next-line no-unused-vars
                                        const responseSend = apiSendOrders.post('/',
                                            {
                                                order_number: sortedPoints[x].Number,
                                                client_name: sortedPoints[x].ClientName,
                                                vehicle_uid: sortedPoints[x].VehicleUID,
                                                distance: sortedPoints[x].distance
                                            }
                                        )
                                            .then(() => {
                                            })
                                    }
                                })

                        }).then(() => {
                            // console.table(this.state.TmpOrders)
                        })
                })

            const api4 = axios.create({
                baseURL: `${process.env.REACT_APP_ENDPOINT}/api/v1/OrderStage/Delivery/${invoice?.uid}`,
                mode: "no-cors",
                headers: { token: extractTokenFromSession() },
            });

            api4
                .put("/")
                .then((res) => { }
                )
                .then((res) => {
                    if (truck?.uid) {
                        apiGetAllOrderByVehicleUID.get("/" + truck?.uid).then((res) => {
                            for (let x = 0; x < res.data.length; x++) {
                                apiGetAllClientInfoByOrderNumber.get("/" + res.data[x].number)
                                    .then((res1) => {
                                        window.location.reload()
                                    });

                            }
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        catch (err) {
            toast(`Failed to approve order No ${invoice?.uid}, please try again`, {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }

    }
    else {
        toast(`Please assign a truck to this order`, {
            style: {
                icon: '❌',
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
            duration: 3000,
        });
    }
}

export const completeDelivery = async (invoice, notify) => {
    if (invoice) {
        try {
            const session = JSON.parse(sessionStorage.getItem('session'));

            const config = {
                headers: {
                    'token': session?.token,
                    mode: 'no-cors'
                }
            };

            const setQuantityDelivered = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/OrderStage/setQuantityDeliveredToQuantityPicked/${invoice?.uid}`, {}, config)

            if (setQuantityDelivered?.data?.message === 'Success') {

                const deletetmporder = await axios.delete(`${process.env.REACT_APP_ENDPOINT}/api/v1/order/deletetmporder/${invoice?.uid}`, config)

                if (deletetmporder?.data?.message === 'Success') {
                    var dt = new Date();
                    let date = moment(dt).format('YYYY-MM-DD H-mm-ss');

                    const completeOrder = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/OrderStage/completedanddelivered/${invoice?.uid}`, { date: date }, config)

                    if (completeOrder?.data?.message === 'Success') {

                        if (notify) {

                            let data = await getContact(invoice?.company_name)

                            if (data[0]?.contact_number) {

                                const payLoad = {
                                    message: completeMessage(data[0]?.name, invoice?.uid, invoice?.amount),
                                    destination: data[0]?.contact_number
                                }

                                const sms = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/sms/`, payLoad, config)

                                if (sms?.data === 'Success') {

                                    toast(`Succesfully completed order No ${invoice?.uid} and sent sms to ${invoice?.company_name}`, {
                                        icon: '✅',
                                        style: {
                                            borderRadius: '10px',
                                            background: '#333',
                                            color: '#fff',
                                        },
                                        duration: 3000,
                                    });

                                } else {
                                    toast(`Succesfully completed order No ${invoice?.uid} but failed to send sms to ${invoice?.company_name}`, {
                                        icon: '✅',
                                        style: {
                                            borderRadius: '10px',
                                            background: '#333',
                                            color: '#fff',
                                        },
                                        duration: 3000,
                                    });
                                }

                            } else {
                                toast(`Succesfully completed order No ${invoice?.uid} but ${invoice?.company_name} has no contact person`, {
                                    icon: '✅',
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                    },
                                    duration: 3000,
                                });
                            }

                        } else {


                            toast(`Succesfully completed order No ${invoice?.uid}.`, {
                                icon: '✅',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });


                        }
                    }
                }
            }
        }
        catch (err) {
            toast(`Failed to complete order No ${invoice?.uid}, please try again`, {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }

    }
}

//integrations and downloads
export const posSync = async (invoice) => {
    if (invoice && invoice?.sent_to_legend === 0) {
        const session = JSON.parse(sessionStorage.getItem('session'));

        const config = {
            headers: {
                'token': session?.token,
                mode: 'no-cors'
            }
        };

        const posSyncResult = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/orderstage/sendtolegend/${invoice?.uid}`, {}, config)

        if (posSyncResult?.data?.message === "(Rows matched: 1  Changed: 1  Warnings: 0") {
            toast(`Succesfully synced order No ${invoice?.uid} to your POS`, {
                icon: '✅',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }
    }
    else {
        toast(`You have already synced this order`, {
            icon: '✅',
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
            duration: 3000,
        });
    }
}

export const generatePS = (invoice) => {
    if (invoice) {
        const url = `/pickingslip/${invoice.uid}`;
        window.open(url, '_blank');
    }
}

export const generatePFI = (invoice) => {
    if (invoice) {
        const url = `/proformainvoice/${invoice.uid}`;
        window.open(url, '_blank');
    }
}

export const generateDN = (invoice) => {
    if (invoice) {
        const url = `/deliverynote/${invoice.uid}`;
        window.open(url, '_blank');
    }
}