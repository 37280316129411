import React, { useState } from 'react';

import { addDays, format } from 'date-fns';
import 'react-day-picker/dist/style.css';
import { DayPicker } from 'react-day-picker';

const pastMonth = new Date(2023, 10, 15);

export const DatePicker = ({ accessDates }) => {
    const defaultSelected = {
        from: pastMonth,
        to: addDays(pastMonth, 3)
    };

    const [state, setState] = useState({
        isOpen: false,
        dates: null,
        totalDays: null
    })

    const toggleDatePicker = () => setState({ ...state, isOpen: !state.isOpen })

    const [range, setRange] = useState(defaultSelected);

    const formatDate = (date) => {
        const year = date.getFullYear().toString();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const confirmDates = (e) => {
        e.preventDefault();

        const startDate = formatDate(range?.from);
        const endDate = formatDate(range?.to);

        const timeDifference = range?.to?.getTime() - range?.from?.getTime();
        const totalDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

        setState({ ...state, isOpen: false, dates: `${startDate} to ${endDate}`, totalDays: totalDays });

        const dates = { startDate, endDate }
        accessDates(dates)
    }

    let footer = <p>Please pick the first day.</p>;

    if (range?.from) {
        if (!range.to) {
            footer = <p>{format(range.from, 'PPP')}</p>;
        }
        else if (range.to) {
            footer = <div className='flex flex-col gap-2'>
                <p className='text-sm'>{format(range.from, 'PPP')} to {format(range.to, 'PPP')}</p>
                <button className='w-full p-2 text-sm text-white rounded cursor-pointer bg-green' onClick={confirmDates}>Confirm Dates</button>
            </div>
        }
    }

    return (
        <div className='relative w-full'>
            <div className='flex flex-col justify-start gap-1'>
                <label className="text-base font-medium text-gray-500" >Select Dates</label>
                <p className='p-3 text-sm font-medium text-gray-500 bg-white border rounded shadow cursor-pointer border-grey' onClick={toggleDatePicker}>{(state.dates && `${state.dates}`) || 'Select Dates'}</p>
            </div>
            {
                state.isOpen &&
                <div className='absolute left-0 right-0 pb-3 text-sm text-gray-500 bg-white border rounded shadow border-grey top-20'>
                    <DayPicker
                        id="test"
                        mode="range"
                        defaultMonth={pastMonth}
                        selected={range}
                        footer={footer}
                        onSelect={setRange}
                        className='w-full rounded' />
                </div>
            }
        </div>
    );
}