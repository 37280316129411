//components
import { CancelledVsCompleted } from "./charts/distributor/cancelled-vs-completed-orders"
import { SalesPerStore } from "./charts/distributor/sales-per-store"
import { TurnAroundTimePerDelivery } from "./charts/distributor/turn-around-time-per-delivery"

export const DistributorReports = () => {
    return (
        <div className="w-full pb-6 h-[91vh] lg:h-[83vh] overflow-y-scroll pr-2 flex items-start justify-between flex-wrap gap-y-7">
            <TurnAroundTimePerDelivery />
            <CancelledVsCompleted />
            <SalesPerStore />
        </div>
    )
}
