import { useRef } from "react";
import { isEmpty } from "lodash";
import "leaflet/dist/leaflet.css"
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { position, zoom, colourCodedMarkers, redMarker, truckMarker, repMarker } from '../../utils/app-settings';
import { AllClients } from "../../helpers/map/helpers/getClients";
import { useVehicleContext } from "../../helpers/map/context/map-context";
import { Routing } from "./router";

export const MapTile = () => {
    const mapRef = useRef(null)
    const map = mapRef.current

    const clients = AllClients();
    const { liveLocation, wayPoints, invoiceData, trackingType } = useVehicleContext();

    const RenderStoreMarkers = () => {
        if (!clients || isEmpty(clients)) {
            return;
        }
        else if (clients && !isEmpty(clients)) {
            return (
                <>
                    {
                        clients?.map((store) =>
                            <Marker position={[store.latitude, store.longitude]} icon={colourCodedMarkers[store.color] || redMarker} key={store?.uid}>
                                <Popup>
                                    <div className="flex flex-col justify-center gap-4">
                                        <span className="font-bold text-black text-md md:text-xl">{store.storeName?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>
                                        <p className="text-sm leading-none text-gray-500">{store.address}</p>
                                        <p className="text-sm leading-none text-gray-500">{store.contact_number ? store.contact_number : "No contact set"}<br />{store.email ? store.email : "No email set"}</p>
                                    </div>
                                </Popup>
                            </Marker>
                        )
                    }
                </>
            )
        }
        else {
            return;
        }
    }

    const RenderLiveLocation = () => {
        if (!liveLocation) {
            return;
        }
        else if (liveLocation && !isEmpty(liveLocation)) {
            const { address, latitude, longitude, licenseNumber, make, model, date } = liveLocation

            map.setView([latitude, longitude], zoom);

            return (
                <Marker position={[latitude, longitude]} icon={truckMarker} key={licenseNumber} >
                    <Popup>
                        <div className="flex flex-col justify-center gap-4 py-4 ">
                            <span className="font-bold text-black text-md md:text-xl">{make} {model} - {licenseNumber}</span>
                            <p className="text-sm leading-none text-gray-500">Last seen on <span className='font-bold text-black text-md'>{date?.slice(0, 10)}</span> at <span className='font-bold text-black text-md'>{date?.slice(11, 19)}</span></p>
                            <p className="text-sm leading-none text-gray-500">at  <span className='font-bold text-black text-md'>{address}</span></p>
                        </div>
                    </Popup>
                </Marker>
            )
        }
        else {
            return;
        }
    }

    const RenderRepLocation = () => {
        if (!invoiceData) {
            return;
        }
        else if (invoiceData && !isEmpty(invoiceData)) {
            const { amount, companyName, contactNumber, creationDate, email, latitude, longitude, name, orderNumber } = invoiceData

            return (
                <Marker position={[latitude, longitude]} icon={repMarker}>
                    <Popup>
                        <div className="flex flex-col justify-center gap-4">
                            <span className="font-bold text-black text-md md:text-xl">{companyName?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>

                            <div>
                                <p className="text-sm leading-none text-gray-500">Amount: <span className="font-bold">R {Number(amount)?.toFixed(2)}</span></p>
                                <p className="text-sm leading-none text-gray-500">Invoice: <span className="font-bold">#{orderNumber}</span></p>
                            </div>

                            <div>
                                <p className="text-sm leading-none text-gray-500">Created By: {name}</p>
                                <p className="text-sm leading-none text-gray-500">Creation Date: {creationDate?.slice(0, 10)}</p>
                            </div>

                            <p className="text-sm leading-none text-gray-500">{contactNumber ? contactNumber : "No contact set"}<br />{email ? email : "No email set"}</p>
                        </div>
                    </Popup>
                </Marker>
            )
        }
        else {
            return;
        }
    }

    return (
        <MapContainer ref={mapRef} center={position} zoom={zoom} scrollWheelZoom={true} className="z-40 w-full h-full">
            <TileLayer url='https://tile.openstreetmap.org/{z}/{x}/{y}.png' attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
            {RenderRepLocation()}
            {RenderStoreMarkers()}
            {RenderLiveLocation()}
            <Routing wayPoints={wayPoints} type={trackingType} />
        </MapContainer>
    );
}