// import { isEmpty } from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';
import { isEmpty } from "lodash";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

//hooks
import { useQuery } from "../../hooks/useQuery";

// import required modules
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules';

export const Carousel = () => {

    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/documents/getbanners`, 'get');

    if (isLoading) {
    }

    if (error) {
    }

    if (isEmpty(data)) {
    }

    const imageData = [
        {
            id: data[0]?.uid,
            image: `${process.env.REACT_APP_BANNER_ENDPOINT}/${data[0]?.image_name}`
        },
        {
            id: data[1]?.uid,
            image: `${process.env.REACT_APP_BANNER_ENDPOINT}/${data[1]?.image_name}`
        },
        {
            id: data[2]?.uid,
            image: `${process.env.REACT_APP_BANNER_ENDPOINT}/${data[2]?.image_name}`
        },
        {
            id: data[3]?.uid,
            image: `${process.env.REACT_APP_BANNER_ENDPOINT}/${data[3]?.image_name}`
        }
    ]

    return (
        <Swiper
            spaceBetween={30}
            effect={'fade'}
            loop={true}
            navigation={true}
            pagination={{
                clickable: true,
            }}
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            modules={[EffectFade, Navigation, Pagination, Autoplay]}
            className="mySwiper h-full w-full">
            {imageData?.map((banner) => (
                <SwiperSlide key={Math.random()}>
                    <figure className='w-full h-full relative rounded-md'>
                        <img alt='Shop Online' src={banner.image} className='rounded-md absolute h-full w-full object-cover' />
                    </figure>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}
