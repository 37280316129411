import { useState, useContext } from 'react'
import { isEmpty } from 'lodash'

//context provider
import { CartContext } from '../../../context/cart/cartContext'

//images
import cartIcon from '../../../assets/icons/wcart.svg'
import placeholder from '../../../assets/icons/placeholder.svg';
import cartgadd from '../../../assets/icons/grayadd.svg'
import cartremove from '../../../assets/icons/grayremove.svg'
import times from '../../../assets/icons/wtimes.svg'
import rtimes from '../../../assets/icons/rtimes.svg'
import deleteIcon from '../../../assets/icons/delete.svg'

//user data
import { Link } from 'react-router-dom';

export const CartPopUp = () => {
    const [state, setState] = useState({
        showcart: false,
        searchTerm: '',
        viewStores: '',
        viewCategpories: '',
    })

    const cartContext = useContext(CartContext);
    const {
        cartItems,
        removeFromCart,
        editCartItem,
        incrementItem,
        decrementItem,
        clearCart,
    } = cartContext;

    const handleIncrement = (itemId) => incrementItem(itemId)

    const handleDecrement = (itemId) => decrementItem(itemId);

    const removeItem = (itemId) => removeFromCart(itemId);

    const toggleCart = () => setState({ ...state, showcart: !state.showcart })

    const handleQuantityChange = (event, itemId) => {
        const { value } = event.target;

        const parsedValue = parseInt(value, 10);

        if (!isNaN(parsedValue) && parsedValue >= 0) {
            editCartItem(itemId, parsedValue);
        }
    };

    return (
        <>
            {
                !isEmpty(cartItems) &&
                <figure className={`fixed ${state.showcart ? 'bg-red w-10 hover:w-10' : 'bg-purple hover:w-20'} h-14 rounded-s w-10 lg:ease-in-out duration-300 p-2 lg:flex items-center justify-center right-2 top-[50%] cursor-pointer shadow-2xl hidden z-40`} key={'popup-icon'} onClick={toggleCart}>
                    <img src={state.showcart ? times : cartIcon} alt='Up' loading='lazy' className='w-8 h-8' />
                </figure>
            }

            {
                (state.showcart && !isEmpty(cartItems)) &&
                <div className='max-h-[565px] h-full w-[450px] z-[99] right-16 bottom-5 fixed rounded shadow-2xl hidden lg:flex flex-col justify-between bg-white fade-in' key={Math.random}>
                    <div className='bg-purple rounded-t p-2 lg:p-4 w-full h-[10%] flex items-center justify-between'>
                        <p className='text-xl font-medium text-white'>{`Your Cart${cartItems.length === 0 ? ':' : cartItems.length === 1 ? `: ${cartItems.length} Item` : `: ${cartItems.length} Items`}`}</p>
                        <img src={deleteIcon} alt='' loading='lazy' className='w-8 cursor-pointer' onClick={clearCart} />
                    </div>
                    <div className='flex flex-col justify-start gap-1 h-[78%] overflow-y-scroll px-2 divide-y'>
                        {
                            cartItems.map((product, index) =>
                                <>
                                    <div className='flex items-start justify-center w-full gap-2 p-2' key={product.id}>
                                        <p className='flex items-center justify-center w-1/12 h-full font-medium text-gray-500'>{index + 1}</p>
                                        <figure className='flex items-center justify-center w-4/12 h-full rounded-lg'>
                                            <img
                                                src={`${process.env.REACT_APP_IMAGE_ENDPOINT}/${product.uid}.jpg`}
                                                onError={(e) => {
                                                    e.target.onError = null;
                                                    e.target.src = placeholder;
                                                }}
                                                alt=''
                                                loading='lazy' className='object-contain w-16' />
                                        </figure>
                                        <div className='flex flex-col justify-start w-5/12 gap-2'>
                                            <p className='font-medium leading-none text-md'>{product.name}</p>
                                            <p className='text-sm text-gray-500 leading-1'>{product.description && product.description.substring(0, 30)}...</p>
                                            <div className='flex items-center justify-start gap-2'>
                                                <button className='cursor-pointer' onClick={() => handleDecrement(product.uid)}>
                                                    <img src={cartremove} alt='' loading='lazy' className='w-8' />
                                                </button>
                                                <input
                                                    type='text'
                                                    name='quantity'
                                                    placeholder={product.quantity}
                                                    value={product.quantity}
                                                    className='flex items-center justify-center text-center w-[60px] h-[35px] border-[1px] hover:border-purple text-sm'
                                                    onInput={(e) => handleQuantityChange(e, product.uid)}
                                                />
                                                <button className='cursor-pointer' onClick={() => handleIncrement(product.uid)}>
                                                    <img src={cartgadd} alt='' loading='lazy' className='w-8' />
                                                </button>
                                            </div>
                                            <p className='text-xl font-medium text-gray-700'>R{Number(product.incsell * product.quantity).toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                                            <p className={`text-sm ${Number(product.One) ? 'text-gray-500' : 'text-red'} font-medium`}>{Number(product.One) ? `Stock for ${(Number(product.quantity) / Number(product.One)).toFixed(2)} days` : 'No data'}</p>
                                        </div>
                                        <figure className='flex items-center justify-center w-1/5 h-full'>
                                            <img src={rtimes} alt='' loading='lazy' className='cursor-pointer w-9' onClick={() => removeItem(product.uid)} />
                                        </figure>
                                    </div>
                                    <hr className='p-[1px] bg-grey' key={Math.random()} />
                                </>
                            )
                        }
                    </div>
                    <div className='flex flex-col justify-end gap-3 w-full h-[10%] p-2 bg-grey rounded'>
                        <Link to='/cart' className={`bg-green text-white p-2 rounded w-full uppercase text-base font-medium text-center`}>Go To Checkout</Link>
                    </div>
                </div >
            }
        </>
    )
}