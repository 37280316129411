//components
import { Tabs } from "../components/tabs/Tabs";
import { Layout } from "../layout/layout";

//tab components
import { CreateSurveys } from "../modules/surveys/create-survey";
import { ViewSurveys } from "../modules/surveys/view-surveys";

const tabs = [
    {
        label: 'Create Survey',
        content: <CreateSurveys />,
    },
    {
        label: 'View Surveys',
        content: <ViewSurveys />,
    },
];

export const Survey = () => {
    return (
        <Layout>
            <div className="p-2">
                <Tabs tabs={tabs} />
            </div>
        </Layout>
    )
}