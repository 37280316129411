import { isEmpty } from 'lodash';
import {
    Label,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

//hooks
import { useQuery } from '../../../../hooks/useQuery';

//images	
import alert from '../../../../assets/icons/alert.svg'
import emptyIcon from '../../../../assets/icons/empty.svg'
import { useEffect, useState } from 'react';

export const CancelledVsCompleted = () => {
    const [state, setState] = useState({
        pageWidth: window.innerWidth
    })

    const handleResize = () => {
        setState({ ...state, pageWidth: window.innerWidth });
    };

    useEffect(() => {
        // Add event listener to listen for window resize events
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/analytics/orderscreatedvsorderscancelled`, "get");

    if (isLoading) {
        return (
            <div className="bg-white p-2 h-[500px] w-full rounded-lg shadow border-2 flex items-center justify-center text-sm text-gray-500 font-medium relative">
                <div className='w-full h-full flex flex-col justify-center items-center gap-2'>
                    <span className='p-3 animate-pulse animate-ping w-3/4 rounded bg-black-skeleton'></span>
                    <span className='p-3 animate-pulse animate-ping w-[15px] rounded bg-black-skeleton absolute top-8 right-7 hidden md:flex'></span>
                    <div className='w-full h-[260px] flex items-center justify-between gap-1 pr-5'>
                        <div className='w-[3%] h-full bg-black-skeleton animate-ping animate-pulse rounded hidden md:flex'></div>
                        <div className='w-full md:w-[95%] h-full bg-black-skeleton animate-ping animate-pulse rounded'></div>
                    </div>
                    <span className='p-3 animate-pulse animate-ping w-1/4 rounded bg-black-skeleton'></span>
                </div>
            </div>
        )
    }

    if (error) {
        return (
            <div className="bg-white p-2 h-[500px] w-full rounded-lg shadow border-2 flex items-center justify-center text-sm text-gray-500 font-medium relative">
                <div className='w-full h-full flex flex-col justify-center items-center gap-2'>
                    <span className='p-3 animate-pulse animate-ping w-3/4 rounded bg-black-skeleton'></span>
                    <span className='p-3 animate-pulse animate-ping w-[15px] rounded bg-black-skeleton absolute top-8 right-7 hidden md:flex'></span>
                    <div className='w-full h-[260px] flex items-center justify-between gap-1 pr-5'>
                        <div className='w-[3%] h-full bg-black-skeleton animate-ping animate-pulse rounded hidden md:flex'></div>
                        <div className='w-full md:w-[95%] h-full bg-black-skeleton rounded relative flex flex-col justify-center items-center'>
                            <div className='bg-white p-2 rounded flex items-center justify-start gap-2'>
                                <img src={alert} loading='lazy' alt='Filter' className='w-10' />
                                <p>We could not load this chart's data</p>
                            </div>
                        </div>
                    </div>
                    <span className='p-3 animate-pulse animate-ping w-1/4 rounded bg-black-skeleton'></span>
                </div>
            </div>
        )
    }

    if (isEmpty(data)) {
        return (
            <div className="bg-white p-2 h-[500px] w-full rounded-lg shadow border-2 flex items-center justify-center text-sm text-gray-500 font-medium relative">
                <div className='w-full h-full flex flex-col justify-center items-center gap-2'>
                    <span className='p-3 animate-pulse animate-ping w-3/4 rounded bg-black-skeleton'></span>
                    <span className='p-3 animate-pulse animate-ping w-[15px] rounded bg-black-skeleton absolute top-8 right-7 hidden md:flex'></span>
                    <div className='w-full h-[260px] flex items-center justify-between gap-1 pr-5'>
                        <div className='w-[3%] h-full bg-black-skeleton animate-ping animate-pulse rounded hidden md:flex'></div>
                        <div className='w-full md:w-[95%] h-full bg-black-skeleton rounded relative flex flex-col justify-center items-center'>
                            <div className='bg-green text-white p-2 rounded flex items-center justify-start gap-2'>
                                <img src={emptyIcon} loading='lazy' alt='Filter' className='w-10' />
                                <p>There is no data for this chart</p>
                            </div>
                        </div>
                    </div>
                    <span className='p-3 animate-pulse animate-ping w-1/4 rounded bg-black-skeleton'></span>
                </div>
            </div>
        )
    }

    //preps data to be mapped into the chart
    const chartData = data?.map((property) => {
        const shortenedName = property.company_name.replace(/store|outlet|Outlet|plus/gi, '').trim();

        return {
            'Completed': property?.completed_count,
            'Cancelled': property?.cancelled_count,
            'Company Name': shortenedName,
        };
    });

    const ItalizeLabels = (props) => {
        const { x, y, payload } = props;
        return (
            <g transform={`translate(${x},${y})`} >
                <text x={0} y={0} dy={16} textAnchor="end" transform="rotate(-15)" className='text-xs'>
                    {payload?.value}
                </text>
            </g>
        );
    };

    const barValue = ({ x, y, width, value }) => {
        return <text x={x + width / 2} y={y} fill="#353535" textAnchor="middle" dy={-6} className='text-xs text-gray-500'>{`${value}`}</text>;
    };

    return (
        <div className="bg-white p-2 h-[500px] w-full rounded-lg shadow border-2 flex items-center justify-center text-sm text-gray-500 font-medium relative">
            <div className='w-full h-[80%] flex flex-col justify-center items-center gap-2'>
                <h2 className='text-center text-base md:text-lg'>Orders Completed vs Cancelled <span className='text-base font-normal'>(Qty)</span></h2>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={250}
                        data={chartData}
                        margin={{
                            top: 30,
                            right: 10,
                            left: 10,
                            bottom: 30,
                        }}
                        barSize={state.pageWidth > 500 ? 30 : 10}>
                        <XAxis dataKey="Company Name" scale="point" padding={{ left: state.pageWidth >= 500 ? 40 : 20, right: state.pageWidth >= 500 ? 40 : 20, }} tick={<ItalizeLabels />} allowDuplicatedCategory={true} interval={0} />
                        <YAxis tick={{ fontSize: 12 }}>
                            <Label value="Quantity" angle={-90} position="insideLeft" style={{ textAnchor: 'middle' }} />
                        </YAxis>
                        <Tooltip
                            content={({ payload, label, active }) => {
                                if (active) {
                                    const completed = payload.find(entry => entry.dataKey === 'Completed');
                                    const cancelled = payload.find(entry => entry.dataKey === 'Cancelled');

                                    if (!completed || !cancelled) {
                                        return null;
                                    }

                                    const completedValue = completed?.value || 0;
                                    const cancelledValue = cancelled?.value || 0;

                                    return (
                                        <div className="bg-white border border-grey-darker p-2 rounded shadow max-w-xs">
                                            <p className="label">{`${label}`}</p>
                                            <div className="value">
                                                <p style={{ color: 'var(--green)' }}>
                                                    {`Completed orders: ${completedValue}`}
                                                </p>
                                                <p style={{ color: 'var(--red)' }}>
                                                    {`Cancelled orders: ${cancelledValue}`}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                }
                                return null;
                            }}
                        />
                        <Legend />
                        <CartesianGrid strokeDasharray="3 3" stroke="#9f9f9f" vertical={false} />
                        <Bar
                            fill="var(--green)"
                            dataKey="Completed"
                            label={barValue}
                            background={{ fill: 'transparent' }} />
                        <Bar
                            fill="var(--red)"
                            dataKey="Cancelled"
                            label={barValue}
                            background={{ fill: 'transparent' }} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};
