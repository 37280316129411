//images
import emptyIcon from '../../../../assets/icons/empty.svg';

export const EmptyStore = ({ content }) => {
    return (
        <div className='w-full h-full flex items-center justify-around flex-wrap gap-2 relative'>
            {[...Array(18)].map((_, index) => (
                <div key={index} className={`w-[48%] mx-auto sm:w-[32%] md:w-[18%] lg:w-[16%] h-[360px] shadow-2xl bg-white rounded-lg p-2 cursor-pointer flex flex-col justify-start gap-2`}>
                    <div className="w-full h-[200px] rounded-lg border flex justify-center items-center bg-black-skeleton animate-pulse animate-ping"></div>
                    <div className="h-[140px] w-full flex flex-col justify-between">
                        <div className="flex justify-start gap-1 md:gap-2 flex-col bg">
                            <p className="p-3 bg-black-skeleton animate-pulse animate-ping w-10/12 rounded"></p>
                            <p className="p-2 bg-black-skeleton animate-pulse animate-ping w-6/12 rounded"></p>
                        </div>
                        <div className="flex items-center justify-between">
                            <p className="p-3 bg-black-skeleton animate-pulse animate-ping w-2/12 rounded"></p>
                            <p className="text-gray-500 font-medium flex items-center justify-end gap-2">
                                SOH:
                                <span className="p-3 bg-black-skeleton animate-pulse animate-ping rounded"></span>
                            </p>
                        </div>
                        <div className="w-full flex items-center justify-between">
                            <p className="text-xl md:text-2xl font-medium flex items-center justify-start gap-2">
                                R
                                <span className="py-3 px-6 bg-black-skeleton animate-pulse animate-ping rounded"></span>
                            </p>
                            <span className="py-4 px-6 bg-black-skeleton animate-pulse animate-ping rounded"></span>
                        </div>
                    </div>
                    <div className='h-[50px] flex items-start justify-between gap-1'>
                        <div className='flex flex-col justify-start gap-1 items-center w-3/12'>
                            <span className='h-1 w-full bg-red rounded-lg'></span>
                            <p className='leading-none text-gray-500 text-sm font-medium text-center'>7 Days</p>
                            <p className='py-2 px-6 bg-black-skeleton animate-pulse animate-ping rounded'></p>
                        </div>
                        <div className='flex flex-col justify-start gap-1 items-center w-3/12'>
                            <span className='h-1 w-full bg-yellow rounded-lg'></span>
                            <p className='leading-none text-gray-500 text-sm font-medium text-center'>14 Days</p>
                            <p className='py-2 px-6 bg-black-skeleton animate-pulse animate-ping rounded'></p>
                        </div>
                        <div className='flex flex-col justify-start gap-1 items-center w-3/12'>
                            <span className='h-1 w-full bg-green rounded-lg'></span>
                            <p className='leading-none text-gray-500 text-sm font-medium text-center'>21 Days</p>
                            <p className='py-2 px-6 bg-black-skeleton animate-pulse animate-ping rounded'></p>
                        </div>
                    </div>
                </div>
            ))}
            <div className='rounded-lg flex items-center justify-center fixed bg-green text-white p-6 w-11/12 md:6/12 lg:w-6/12 xl:4/12 gap-4'>
                <figure className='flex items-center justify-center'>
                    <img src={emptyIcon} alt='Empty Store' loading='lazy' className='object-contain w-20 md:w-28' />
                </figure>
                <p className='text-md font-medium leading-5'>{content}</p>
            </div>
        </div>
    )
}
