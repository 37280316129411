import { useState } from "react"
import { AllVehicles } from "../helpers/getVehicles";
import { isEmpty } from "lodash";
import { useVehicleContext } from "../context/map-context";

import truckIcon from '../../../assets/icons/truck-front.svg'

export const LocateVehicle = () => {
    const [vehicle, setVehicle] = useState()
    const [isOpen, setOpen] = useState(false)

    const vehicles = AllVehicles();
    const { getVehicleLocation } = useVehicleContext();

    if (!vehicles || isEmpty(vehicles)) {
        return;
    }

    const toggleDropDown = () => setOpen(!isOpen)
    const closeDropDown = () => setOpen(false)

    const locateVehicle = async (vehicle) => {
        setVehicle(vehicle)

        closeDropDown()

        getVehicleLocation(vehicle)
    }

    return (
        <div className="flex items-end justify-between w-full gap-6 p-2 rounded shadow bg-grey">
            <div className="relative flex flex-col justify-start w-full gap-2">
                <div className="flex flex-col justify-start w-full gap-0">
                    <p className="text-base font-medium text-gray-500" >Select A Vehicle</p>
                    <span className="p-3 font-medium bg-white rounded shadow cursor-pointer" onClick={toggleDropDown}>{vehicle ? `${vehicle?.make} - ${vehicle?.license_number}` : 'Select A Vehicle'}</span>
                </div>
                {
                    isOpen &&
                    <ul className="flex flex-col justify-start w-full h-56 overflow-y-scroll bg-white rounded">
                        {vehicles?.map((vehicle) =>
                            <li className="flex items-center justify-start w-full gap-2 p-2 text-sm font-medium text-gray-500 bg-white cursor-pointer lg:hover:bg-grey lg:ease-in-out lg:duration-300 first:rounded-t last:rounded-b" key={vehicle?.uid} onClick={() => locateVehicle(vehicle)}>
                                <img src={truckIcon} alt='StoreIcon' loading="lazy" className="w-6" />
                                <span className="text-base">{vehicle?.make}</span> -
                                <span>{vehicle?.model}</span>
                                <span> # ({vehicle?.license_number})</span>
                            </li>
                        )}
                    </ul>
                }
            </div>
        </div>
    )
}
