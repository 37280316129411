import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

//main styling
import './styles/theme.css';

//main app
import { App } from './App';

//vitals
import reportWebVitals from './reportWebVitals';

//context providers
import { SessionProvider } from './context/session/sessionContext';
import { CartProvider } from './context/cart/cartContext';
import { VehicleProvider } from './helpers/map/context/map-context';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SessionProvider>
        <VehicleProvider>
          <CartProvider>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </CartProvider>
        </VehicleProvider>
      </SessionProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
