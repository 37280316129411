import { Tabs } from "../../components/tabs/Tabs";
import { useSessionPolling } from "../../hooks/useSessionPolling";

//tab components
import { General } from "../profile/settings-pages/general-settings";
import { Analytics } from "../profile/settings-pages/analytics-settings";
import { Map } from "../profile/settings-pages/map-settings";
import { Promotions } from "./settings-pages/promotions-settings";
import { Survey } from "./settings-pages/survey-settings";
import { Logo } from "./settings-pages/logo-settings";

export const ProfileSettings = () => {

    const user = useSessionPolling('session')

    const tabs = [
        {
            label: 'General',
            content: <General />,
        },
        ...(user?.type?.toLowerCase()?.includes('super admin') || user?.type?.toLowerCase()?.includes('dc user') ?
            [
                {
                    label: 'Analytics',
                    content: <Analytics />,
                },
                {
                    label: 'Map',
                    content: <Map />,
                },
                {
                    label: 'Promotions',
                    content: <Promotions />,
                },
                {
                    label: 'Survey',
                    content: <Survey />,
                },
                {
                    label: 'Logo',
                    content: <Logo />,
                }
            ]
            :
            []
        ),

    ];

    return (
        <div className="w-full h-full">
            <Tabs tabs={tabs} />
        </div>
    )
}
