import axios from 'axios';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import Geocode from "react-geocode";
import { toast } from 'react-hot-toast';
import React, { createContext, useContext } from 'react';

const apikey = 'AIzaSyCTACIZ7vZRgzIO6BkVHB62R0J5CzO_ubs'

Geocode.setApiKey(apikey);
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP")

const VehicleContext = createContext();

export const useVehicleContext = () => {
    const context = useContext(VehicleContext);

    if (!context) {
        throw new Error('useVehicleContext must be used within a VehicleProvider');
    }

    return context;
};

export const VehicleProvider = ({ children }) => {
    const [clearMap, setClearMap] = useState(false)
    const [wayPoints, setWayPoints] = useState()
    const [invoiceData, setInvoiceData] = useState()
    const [liveLocation, setLiveLocation] = useState()
    const [trackingType, setTrackingType] = useState()

    const apiEndpoint = process.env.REACT_APP_ENDPOINT

    const replocation = (repLocation) => setInvoiceData(repLocation)

    const clearCanvas = () => setClearMap(true)

    const getVehicleLocation = async (vehicle) => {
        if (!vehicle) {
            return;
        }

        try {
            const user = JSON.parse(sessionStorage.getItem('session'));

            const config = {
                headers: {
                    'token': user?.token,
                    mode: 'no-cors'
                }
            };

            const { uid } = vehicle

            const liveLocation = await axios.get(`${apiEndpoint}/api/v1/vehicle/top/${uid}`, config)

            if (liveLocation?.data && !isEmpty(liveLocation?.data)) {

                const { latitude, longitude, date_time } = liveLocation?.data?.[0]
                const { license_number, make, model } = vehicle

                Geocode.fromLatLng(latitude, longitude)
                    .then(
                        (response) => {
                            const address = response?.results[0]?.formatted_address

                            const locationData = {
                                make: make,
                                model: model,
                                date: date_time,
                                address: address,
                                latitude: latitude,
                                longitude: longitude,
                                licenseNumber: license_number,
                            }

                            setTrackingType('history')
                            setLiveLocation(locationData)

                            toast(`Plotting last known location`, {
                                icon: '⌛',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });

                        },
                        (error) => {
                            toast('failed to decode the address, please refresh and try again', {
                                icon: '❌',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });
                        }
                    );
            }
            else {
                toast(`There is no vehicle data between these dates`, {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });
            }

        }
        catch (error) {
            toast(`Please try again`, {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }
    }

    const getVehicleHistory = async (dates, vehicle) => {

        if (!vehicle || !dates?.startDate || !dates?.endDate) {
            return;
        }

        try {
            const user = JSON.parse(sessionStorage.getItem('session'));

            const config = {
                headers: {
                    'token': user?.token,
                    mode: 'no-cors'
                }
            };

            const { uid } = vehicle
            const { startDate, endDate } = dates

            const tripData = await axios.get(`${apiEndpoint}/api/v1/track/getallbyuidfordates/${uid}/${startDate?.slice(0, 10)}/${endDate?.slice(0, 10)}`, config)

            if (tripData?.data && !isEmpty(tripData?.data)) {

                const tripCOORDS = tripData?.data.map((property) => {
                    return {
                        latitude: property.latitude,
                        longitude: property.longitude,
                    }
                })

                setTrackingType('history')
                setWayPoints(tripCOORDS)

                toast(`Plotting trip history`, {
                    icon: '⌛',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });
            }

        }
        catch (error) { }
    }

    const getRepTripHistory = async (dates, rep) => {
        if (!dates || !rep) {
            return;
        }

        try {
            const user = JSON.parse(sessionStorage.getItem('session'));

            const config = {
                headers: {
                    'token': user?.token,
                    mode: 'no-cors'
                }
            };

            const { uid } = rep

            const tripData = await axios.get(`${apiEndpoint}/api/v1/track/rep/${uid}`, config)

            if (tripData?.data && !isEmpty(tripData?.data)) {

                const tripCOORDS = tripData?.data.map((property) => {
                    return {
                        latitude: property.latitude,
                        longitude: property.longitude,
                    }
                })

                setTrackingType('rep')
                setWayPoints(tripCOORDS)
            }
        }
        catch (error) { }
    }

    return <VehicleContext.Provider value={{
        getVehicleLocation,
        getVehicleHistory,
        getRepTripHistory,
        replocation,
        invoiceData,
        liveLocation,
        wayPoints,
        trackingType,
        clearMap,
        clearCanvas
    }}>{children} </VehicleContext.Provider>
};