import axios from 'axios';
import { useEffect, useState } from "react";
import { toast } from 'react-hot-toast';
import PlacesAutocomplete from "react-places-autocomplete";
import { useNavigate, useParams } from "react-router-dom";

//hooks
import { InputHandler } from "../../hooks/useInputHandler";
import { useQuery } from "../../hooks/useQuery";

//components
import { isEmpty } from "lodash";
import { Layout } from "../../layout/layout";
import { Stores } from "./sections/create-stores";
import { Division } from "./sections/division";
import { Roles } from "./sections/roles";
import { Error, IsEmpty, IsLoading } from "./sections/states";
import { UserType } from "./sections/user-type";

//images
import loadingIcon from "../../assets/icons/wloader.svg";

export const EditUser = () => {
    const { query } = useParams()

    const [state, setState] = useState({
        status: null,
        activeStatus: null,
        isLoading: '',
        role: '',
        division: '',
        userType: '',
        assignedStores: '',
        errorID: '',
        errorUsername: '',
        bAddress: '',
        dAddress: '',
        operationCompleted: '',
        tempStoresToSend: []
    })

    const navigate = useNavigate()

    const { inputValues, handleChange } = InputHandler();

    const handleBusinessAddress = async (value) => setState({ ...state, bAddress: value })

    const handleDeliveryAddress = async (value) => setState({ ...state, dAddress: value })

    const toggleStatus = () => setState((prevState) => ({ ...prevState, status: prevState.status === 0 ? 1 : 0 }));

    const toggleActive = () => setState((prevState) => ({ ...prevState, activeStatus: prevState.activeStatus === 0 ? 1 : 0 }));

    //grabs and sets values from drop down menus
    const handleRoles = (params) => setState({ ...state, role: params })
    const handleDivision = (params) => setState({ ...state, division: params })
    const handleUserTypes = (params) => setState({ ...state, userType: params })

    const handleAssignedStores = (params) => {
        setState((prevState) => {
            // Check if the store UID already exists in the assignedStores array
            const uidExists = prevState.assignedStores.includes(params);

            if (uidExists) {
                // Remove the UID from the assignedStores state
                return {
                    ...prevState,
                    assignedStores: prevState.assignedStores.filter((uid) => uid !== params),
                };
            } else {
                // Add the UID to the assignedStores state
                return {
                    ...prevState,
                    assignedStores: [...prevState.assignedStores, params],
                };
            }
        });
    };

    const goBack = () => navigate(-1);

    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/user/getuserinformation/${query}`, 'get');

    useEffect(() => {
        if (data) {
            setState({ ...state, activeStatus: data[0]?.active, status: data[0]?.internal_employee });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (isLoading) {
        return (
            <IsLoading />
        )
    }

    if (error) {
        return (
            <Error />
        )
    }

    if (isEmpty(data)) {
        return (
            <IsEmpty />
        )
    }

    console.log(data)

    const editUser = async (e) => {
        e.preventDefault()

        setState({ ...state, isLoading: true });

        try {
            const payLoad = {
                uid: data[0]?.uid,
                name: inputValues?.name || data[0]?.name,
                surname: inputValues?.surname || data[0]?.surname,
                email_address: inputValues?.email || data[0]?.email_address,
                username: data[0]?.username,
                password: inputValues?.password || data[0]?.password,
                telegram_number: inputValues?.telegramNum || data[0]?.telegram_number,
                contact_number: inputValues?.contact || data[0]?.contact_number,
                internal_employee: state?.status,
                active: state?.activeStatus,
                business_address: state?.bAddress || data[0]?.business_address,
                home_address: state?.dAddress || data[0]?.home_address,
                user_type_uid: state?.userType?.uid || data[0]?.user_type_uid,
                role_uid: state?.role?.uid || data[0]?.role_uid,
                division_uid: state?.division?.uid || data[0]?.division_uid,
            }

            const session = JSON.parse(sessionStorage.getItem('session'));

            const config = {
                headers: {
                    'token': session?.token,
                    mode: 'no-cors'
                }
            };

            const editUser = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/user/updateuser`, payLoad, config)

            if (editUser?.data?.message === 'Success') {
                try {
                    if (state.assignedStores.length > 0) {

                        for (let x = 0; x < state.assignedStores.length; x++) {
                            state.tempStoresToSend.push(state.assignedStores[x].uid)
                        }

                        const payLoad = {
                            stores: state?.tempStoresToSend
                        }

                        const assignStoreToUser = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/user/setAssignedStoresForUser/${data[0]?.uid}`, payLoad, config)

                        if (assignStoreToUser?.data?.message === 'Success') {

                            setState({ ...state, isLoading: false })

                            toast(`User updated successfully`, {
                                icon: '👋',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });

                            setTimeout(() => {
                                window.location.reload()
                            }, 3100);

                        }

                        else {
                            setState({ ...state, isLoading: false })

                            toast('Failed to update user, please try again', {
                                icon: '❌',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });
                        }
                    } else {

                        setState({ ...state, isLoading: false })

                        toast(`User updated successfully`, {
                            icon: '👋',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                            duration: 3000,
                        });

                        setTimeout(() => {
                            window.location.reload()
                        }, 3100);
                    }

                }
                catch (error) {

                    setState({ ...state, isLoading: false });

                    toast('Error updating user, try again later', {
                        icon: '❌',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });
                }

            } else {
                setState({ ...state, isLoading: false });
                toast('Failed to update user, please try again', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

            }
        }
        catch (error) {
            setState({ ...state, isLoading: false });
            toast('Error updating user, try again later', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }
    }

    return (
        <Layout>
            {data?.map((value) =>
                <div className='w-full h-[90vh] flex flex-col justify-start gap-4 p-2'>
                    <div className='flex items-center justify-between'>
                        <div className='flex flex-col justify-start gap-0'>
                            <p className="text-purple font-semibold text-2xl lg:text-3xl">
                                Edit User
                            </p>
                            <p className="text-lg font-bold text-gray-500">#{`${value.name} - ${value.surname} ( ${value.email_address} )`}</p>
                        </div>
                        <div className="flex items-center justify-start lg:justify-end gap-2 flex-wrap w-full lg:w-6/12">
                            <button className="border border-red p-1 md:p-2 w-[30%] md:w-[20%] text-red hover:bg-red hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer" onClick={goBack}>Close</button>
                        </div>
                    </div>
                    <div className="w-full h-[90vh]">
                        <form onSubmit={editUser} className="w-full h-full flex flex-col justify-between items-start">
                            <div className="flex items-start justify-start flex-wrap gap-2">
                                <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                    <label className="text-base font-medium text-gray-500">User ID</label>
                                    <input
                                        type='text'
                                        readonly="readonly"
                                        name="userID"
                                        placeholder="LS27"
                                        onChange={handleChange}
                                        value={value?.uid}
                                        className={`w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow ${state.errorID && 'border border-red'}`}
                                    />
                                </div>
                                <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                    <label className="text-base font-medium text-gray-500">Name</label>
                                    <input
                                        type='text'
                                        name="name"
                                        defaultValue={value?.name}
                                        onChange={handleChange}

                                        className="w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow"
                                    />
                                </div>
                                <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                    <label className="text-base font-medium text-gray-500">Surname</label>
                                    <input
                                        type='text'
                                        name="surname"
                                        defaultValue={value?.surname}
                                        onChange={handleChange}
                                        className="w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow"
                                    />
                                </div>
                                <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                    <label className="text-base font-medium text-gray-500">Email Address</label>
                                    <input
                                        type='text'
                                        name="email"
                                        defaultValue={value?.email_address}
                                        onChange={handleChange}
                                        className="w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow"
                                    />
                                </div>
                                <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                    <label className="text-base font-medium text-gray-500">Username</label>
                                    <input
                                        type='text'
                                        readonly="readonly"
                                        name="username"
                                        placeholder="test"
                                        onChange={handleChange}
                                        value={value?.username}
                                        className={`w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow ${state.errorID && 'border border-red'}`}
                                    />
                                </div>
                                <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                    <label className="text-base font-medium text-gray-500">Password <span className='text-sm italic'>(change password)</span></label>
                                    <input
                                        type='password'
                                        name="password"
                                        placeholder="New Password"
                                        onChange={handleChange}
                                        className="w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow"
                                    />
                                </div>
                                <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                    <label className="text-base font-medium text-gray-500">Contact</label>
                                    <input
                                        type='contact'
                                        name="contact"
                                        defaultValue={value?.contact_number}
                                        onChange={handleChange}
                                        className="w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow"
                                    />
                                </div>
                                <div className='flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300'>
                                    <label className='text-base font-medium text-gray-500'>{state.status === 0 ? 'External' : 'Internal'}</label>
                                    <label className='relative inline-block w-20 h-9 rounded-full'>
                                        <input type='checkbox' className='peer opacity-0 w-0 h-0' defaultChecked={value?.internal_employee} onClick={toggleStatus} />
                                        <span className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-${state.status === 0 ? 'red' : 'green'} rounded-full duration-300 before:content-[''] before:absolute before:w-7 before:h-7 before:bottom-1 before:left-1 before:rounded-full before:bg-white before:duration-300 peer-checked:before:translate-x-11 peer-checked:bg-green`} ></span>
                                    </label>
                                </div>
                                <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                    <label className="text-base font-medium text-gray-500">Role</label>
                                    <Roles handleRoles={handleRoles} operationStatus={state.operationCompleted} />

                                </div>
                                <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                    <label className="text-base font-medium text-gray-500">Division</label>
                                    <Division handleDivision={handleDivision} operationStatus={state.operationCompleted} />

                                </div>
                                <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                    <label className="text-base font-medium text-gray-500">User Type</label>
                                    <UserType handleUserTypes={handleUserTypes} operationStatus={state.operationCompleted} />
                                </div>
                                <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                    <label className="text-base font-medium text-gray-500">Assign Stores</label>
                                    <Stores handleAssignedStores={handleAssignedStores} assignedStores={state.assignedStores} operationStatus={state.operationCompleted} />
                                </div>
                                <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                    <label className="text-base font-medium text-gray-500">Business Address</label>
                                    <PlacesAutocomplete
                                        value={state.bAddress}
                                        onChange={(value) => setState({ ...state, bAddress: value })}
                                        onSelect={handleBusinessAddress}>
                                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                            <div>
                                                {data.map((value) => (
                                                    <input
                                                        {...getInputProps({
                                                            defaultInputValue: value?.business_address,
                                                            placeholder: "Enter your new business address",
                                                            className: "w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow",
                                                        })}
                                                    />
                                                ))}
                                                <div className="">
                                                    {suggestions.map((suggestion) => {
                                                        const style = { backgroundColor: suggestion.active ? "#e3e3e3" : "#ffffff", cursor: "pointer", };
                                                        return (
                                                            <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })}>
                                                                <p className="text-sm text-gray-500 font-medium">{suggestion.description}</p>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                </div>
                                <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                    <label className="text-base font-medium text-gray-500">Delivery Address</label>
                                    <PlacesAutocomplete
                                        value={state.dAddress}
                                        onChange={(value) => setState({ ...state, dAddress: value })}
                                        onSelect={handleDeliveryAddress}>
                                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                            <div>
                                                {data.map((value) => (
                                                    <input
                                                        {...getInputProps({
                                                            defaultInputValue: value?.home_address,
                                                            placeholder: "Enter your new delivery address",
                                                            className: "w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow",
                                                        })}
                                                    />
                                                ))}
                                                <div className="">
                                                    {suggestions.map((suggestion) => {
                                                        const style = { backgroundColor: suggestion.active ? "#e3e3e3" : "#ffffff", cursor: "pointer", };
                                                        return (
                                                            <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })}>
                                                                <p className="text-sm text-gray-500 font-medium">{suggestion.description}</p>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                </div>
                                <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                    <label className="text-base font-medium text-gray-500">Telegram Number</label>
                                    <input
                                        type='number'
                                        name="telegramNum"
                                        onChange={handleChange}
                                        placeholder='78126312487'
                                        defaultValue={value?.telegram_number}
                                        className={`w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow ${state.errorID && 'border border-red'}`}
                                    />
                                </div>
                                <div className='flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300'>
                                    <label className='text-base font-medium text-gray-500'>{state.activeStatus === 0 ? 'In-Active' : 'Active'}</label>
                                    <label className='relative inline-block w-20 h-9 rounded-full'>
                                        <input type='checkbox' className='peer opacity-0 w-0 h-0' defaultChecked={value?.active} onClick={toggleActive} />
                                        <span className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-${state.activeStatus === 0 ? 'red' : 'green'} rounded-full duration-300 before:content-[''] before:absolute before:w-7 before:h-7 before:bottom-1 before:left-1 before:rounded-full before:bg-white before:duration-300 peer-checked:before:translate-x-11 peer-checked:bg-green`} ></span>
                                    </label>
                                </div>
                                <button type='submit' className='bg-green text-white py-2 px-10 rounded cursor-pointer text-[1.2rem] mt-2'>
                                    {
                                        state.isLoading ?
                                            <p className='flex items-center justify-center gap-2'>
                                                Saving
                                                <img src={loadingIcon} alt='Loading' loading='lazy' className='w-6 animate-spin' />
                                            </p>
                                            :
                                            <p className='flex items-center justify-center gap-2'>
                                                Save Changes
                                            </p>
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Layout>
    )
}
