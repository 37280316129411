import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PickingSlipTable from "./pickingSlipTable";
import QRCode from "react-qr-code";
import axios from "axios";// import pluslogo from '../assets/pluslogo.svg';
import { useReactToPrint } from 'react-to-print';

const extractTokenFromSession = () => {
    const session = JSON.parse(sessionStorage.getItem('session'));
    const token = session?.token || null;
    return token;
};

const api3 = axios.create({

    baseURL: `${process.env.REACT_APP_ENDPOINT}/api/v1/deliverynote/deliverynotemetadata/`,
    mode: 'no-cors',
    headers: { 'token': extractTokenFromSession() }
})

function PickingSlip() {

    const { query } = useParams();

    const pdfExportComponent = React.useRef(null);

    const [Data, setData] = useState([])

    function GetData() {
        api3.get(`/${query}`).then(res => {
            // this.setState({ Clients: res.data })

            setData(res.data)
        })
    }

    const handlePrint = useReactToPrint({
        content: () => pdfExportComponent.current,
        documentTitle: 'Picking Slip',
        onAfterPrint: () => console.log('Printed PDF successfully!'),
    });

    useEffect(() => {
        GetData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="flex flex-col justify-start gap-4">
            <div className="bg-[#616161] w-full fixed top-0">
                <input type="button" value="Print" style={{
                    width: '75px',
                    padding: '5px',
                    margin: '12px',
                    backgroundColor: '#a17efa',
                    color: '#fff',
                    outline: 'none',
                    border: '1px solid #a17efa',
                    borderRadius: '10px'
                }} onClick={handlePrint}></input>
            </div>
            <div style={{
                fontFamily: 'Inter, sans-serif',
                width: '21cm', minHeight: '29.7cm',
                margin: '1cm auto',
                border: '1px #D3D3D3 solid',
                borderRadius: '5px',
                background: 'white',
                boxShadow: '0 0 5px rgba(255, 255, 255, 0.1)'
            }}>
                <div style={{
                    fontFamily: 'Inter, sans-serif',
                    padding: '2cm'
                }} ref={pdfExportComponent}>
                    <div>
                        <div style={{ float: 'left', marginBottom: '-20px' }}>
                            <div style={{ fontSize: '24px', marginBottom: '-10px' }}>Picking Slip</div>
                            <div style={{ fontSize: '27px', marginBottom: '-30px' }}>Order: {query}</div>
                        </div>

                        <div style={{ float: 'right', marginTop: '20px' }}>
                            {/* <img style={{width: '25px', height: '25px'}} src={pluslogo}></img> */}
                        </div>
                    </div>

                    <br />
                    <br />

                    <hr />

                    {Data.map((item) => {
                        return (
                            <>
                                <hr className="bg-grey-darker h-[2px] my-3 w-full" />
                                <div style={{
                                    height: 'auto',
                                    width: '100%',
                                    display: 'grid',
                                    gridTemplateColumns: '350px 350p',
                                    gridTemplateRows: '20px 20px',
                                    gridTemplateAreas: `'top top'`
                                }}>
                                    <label style={{ marginTop: '0px', fontSize: '9px' }}>Client Name : {item.company_name}</label>
                                    <label style={{ marginTop: '0px', fontSize: '9px' }}>Credit Limit: R{(parseInt(item.approved_credit_limit))?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</label>

                                    <label style={{ marginTop: '-9px', fontSize: '9px' }}>Account No: {item.registration_number}</label>
                                    <label style={{ marginTop: '-8px', fontSize: '9px' }}>Credit Used: R{(item.credit_used)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</label>

                                    <label style={{ marginTop: '-18px', fontSize: '9px' }}>Address Line 1: {item.business_street_address}</label>
                                    <label style={{ marginTop: '-16px', fontSize: '9px' }}>Available Balance: R{(item.available_balance)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</label>

                                    <label style={{ marginTop: '-8px', fontSize: '9px' }}>Suburb: {item.business_address_suburb}</label>
                                    <label></label>

                                    <label style={{ marginTop: '-2px', fontSize: '9px' }}>City: {item.business_address_city}</label>
                                    <label></label>

                                    <label style={{ marginTop: '-2px', fontSize: '9px' }}>Tel No: {item.company_contact_number}</label>
                                    <label></label>

                                    <label style={{ marginTop: '-2px', fontSize: '9px' }}>VAT No: {item.vat_number}</label>
                                    <label></label>

                                    <label style={{ marginTop: '-3px', fontSize: '9px' }}>Reg No: {item.registration_number}</label>
                                    <label></label>
                                </div>


                                <div style={{
                                    height: 'auto',
                                    width: '100%',
                                    display: 'grid',
                                    gridTemplateColumns: '250px 300px 200px',
                                    gridTemplateRows: ' 20px 20px 20px',
                                    gridTemplateAreas: 'top top top'
                                }}>

                                    <div style={{ fontSize: '18px', marginTop: '20px' }}>
                                        Date Required:
                                    </div>


                                    <div style={{ fontSize: '18px', marginTop: '-6px' }}>

                                    </div>

                                    <div>

                                    </div>
                                </div>
                                <hr className="bg-grey-darker h-[2px] my-3" />

                                <div style={{ background: 'white', float: 'right', marginTop: '-146px' }}>
                                    <QRCode size={100} renderAs="svg" value={query + "," + query + "," + item.company_name + ", 4"} />
                                </div>

                            </>
                        )
                    })}

                    <hr style={{ marginTop: '-25px' }} />

                    <PickingSlipTable query={query} />

                    <br />
                    <br />

                    <div style={{
                        height: 'auto',
                        width: '100%',
                        display: 'grid',
                        gridTemplateColumns: '350px 350px',
                        gridTemplateRows: '20px 20px',
                        gridTemplateAreas: 'top top'
                    }}>
                        <label>
                            Picked By
                        </label>

                        <label>
                            Checked By
                        </label>

                        <label style={{ marginTop: '40px' }}>
                            __________________________________
                        </label>

                        <label style={{ marginTop: '40px' }}>
                            __________________________________
                        </label>
                    </div>

                    <div style={{ marginBottom: '50px' }}></div>
                </div>
            </div>
        </div >

    )
}

export default PickingSlip;