//components
import { Layout } from "../layout/layout"

//images
import empty from '../assets/icons/empty.svg';

export const NotFound = () => {
    return (
        <Layout>
            <div className="w-full h-[91vh] lg:h-full p-4 relative flex items-center justify-center">
                <div className='rounded-lg flex items-center justify-center fixed bg-green text-white p-6 w-11/12 md:6/12 lg:w-5/12 xl:4/12 gap-4'>
                    <figure className='flex items-center justify-center'>
                        <img src={empty} alt='Empty' loading='lazy' className='object-contain w-20 md:w-32' />
                    </figure>
                    <div className="flex flex-col justify-center gap-0">
                        <h1 className="font-medium leading-none">404</h1>
                        <p className='text-md font-medium leading-1 max-w-sm'>The page you are looking for does not exist explore more from the menu</p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
