//charts
import { PercentPurchaseStoreDC } from "./charts/store/percent-purchase-store-dc"
import { TotalVSDCOrders } from "./charts/store/total-orders-vs-dc-orders"
import { OrdersPerStore } from "./charts/store/orders-per-store"

export const StoreReports = () => {
    return (
        <div className="w-full pb-6 h-[91vh] lg:h-[83vh] overflow-y-scroll pr-2 flex items-start justify-between flex-wrap gap-y-7">
            <PercentPurchaseStoreDC />
            <TotalVSDCOrders />
            <OrdersPerStore />
        </div>
    )
}
