import axios from 'axios';
import { useContext, useState } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import Validator from 'validatorjs';

//images
import eye from '../../assets/icons/faEye.svg';
import eyeSlash from '../../assets/icons/faEyeSlash.svg';
import loader from '../../assets/icons/wloader.svg';
import logo from '../../assets/logos/llogo.png';

//hooks
import { InputHandler } from '../../hooks/useInputHandler';

//components
import { SessionContext } from '../../context/session/sessionContext';
import { MultiStepForm } from '../../components/multi-step-form/form';

export const LoginPage = () => {
    const [state, setState] = useState({
        showPswd: '',
        isLoading: '',
        reqAccess: false
    })

    const [errors, setErrors] = useState({});

    const sessionContext = useContext(SessionContext);

    const { login } = sessionContext;

    const { inputValues, handleChange } = InputHandler();

    const togglePswd = () => setState({ ...state, showPswd: !state.showPswd });

    const loginUser = (e) => {
        e.preventDefault()
        const input = { email: 'required', password: 'required' }
        const validation = new Validator(inputValues, input, { required: 'Your :attribute is required' });

        if (validation.fails()) {
            setErrors(validation.errors.all());
            return;
        }

        if (validation.passes()) {
            setErrors('');
            setState({ ...state, isLoading: true });

            try {
                const userCredentials = { username: inputValues?.email, password: inputValues?.password }

                axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/user/login`, userCredentials)
                    .then((res) => {

                        if (res?.data?.msg === 'Logged in!') {
                            login(res.data);
                            setState({ ...state, isLoading: false });
                        }

                    })
                    .catch((err) => {
                        setState({ ...state, isLoading: false });

                        toast('Login failed, please try again', {
                            icon: '❌',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                            duration: 3000,
                        });
                    })
            }
            catch (err) {
                setState({ ...state, isLoading: false });

                toast('Login failed, please try again', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });
            }
        }
    }

    const reqAccess = () => setState({ ...state, reqAccess: true });

    const cancelAccessRequest = () => setState({ ...state, reqAccess: false });

    return (
        <div className='h-[100vh] w-full flex items-center justify-end lg:between fixed fade-in z-10 bg-black-skeleton'>
            {state.reqAccess && <MultiStepForm cancelAccessRequest={cancelAccessRequest} />}
            <div className='flex items-center justify-center w-full h-full mx-auto duration-500 shadow-2xl lg:ease-in-out sm:w-8/12 sm:mx-0 md:w-8/12 lg:w-4/12'>
                <form className='flex flex-col items-center justify-center w-full h-full gap-6 duration-700 bg-white lg:ease-in-out' onSubmit={loginUser}>
                    <div className='flex items-center justify-start gap-2'>
                        <img src={logo} alt='notification' loading='lazy' className='w-56 cursor-pointer' />
                    </div>
                    <div className='flex flex-col w-11/12 gap-4 mx-auto rounded lg:p-4'>
                        <div className='text-center'>
                            <h4 className='font-bold text-3xl lg:text-4xl flex items-center gap-4 w-[100%] justify-center'>Welcome! 👋</h4>
                            <p className='text-sm font-medium text-gray-500'>Please sign in with your credentials</p>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <label className='text-base font-medium text-gray-500'>Username</label>
                            <input
                                type='text'
                                placeholder='tom@legendordering.co.za'
                                name='email'
                                value={inputValues.email || ''}
                                onChange={handleChange}
                                className={`border-[1px] lg:ease-in-out duration-300 outline-none rounded py-2 lg:py-[14px] lg:px-4 placeholder:${errors.password ? 'normal' : 'italic'} placeholder:text-sm w-[100%]`} />
                            {errors.email && <span className="text-base text-red">{errors.email[0]}</span>}
                        </div>
                        <div className='flex flex-col gap-1'>
                            <label className='text-base font-medium text-gray-500'>Password</label>
                            <div className='relative flex items-center justify-between'>
                                <input
                                    type={state.showPswd ? 'text' : 'password'}
                                    name="password"
                                    value={inputValues.password || ''}
                                    onChange={handleChange}
                                    placeholder='*************'
                                    className={`placeholder:text-[${errors.password ? 'var(--red)' : 'var(--green)'}] border-[1px] lg:ease-in-out duration-300 outline-none rounded py-2 lg:py-[14px] lg:px-4 placeholder:${errors.password ? 'normal' : 'italic'} placeholder:text-sm w-[100%]`} />
                                <img src={state.showPswd ? eye : eyeSlash} alt='' loading='lazy' className='absolute w-6 cursor-pointer right-3' onClick={togglePswd} />
                            </div>
                            {errors.password && <span className="text-base text-red">{errors.password[0]}</span>}
                        </div>
                        <button className='flex items-center justify-center gap-2 px-3 py-2 text-white duration-500 rounded outline-none cursor-pointer lg:ease-in-out text-md lg:text-xl bg-purple hover:shadow-2xl' type='submit' disabled={state.isLoading}>
                            {state.isLoading ? 'Signing In' : 'Sign In'}
                            <img src={loader} alt='' loading='lazy' className={`w-6 animate-spin ${state.isLoading ? 'block' : 'hidden'}`} />
                        </button>
                        <p className='w-full text-center'>New to Legend Ordering?, <span className='cursor-pointer text-purple' onClick={reqAccess}>Request Access Now.</span></p>
                    </div>
                </form>
                <Toaster
                    position="bottom-center"
                    reverseOrder={false}
                    toastOptions={{
                        className: 'flex items-start justify-center gap-2 text-center'
                    }}
                />
            </div>
        </div>
    )
}
