export const InvoiceTable = ({ headers, data, sizing }) => {
	const headerWidth = `${100 / headers?.length}%`;

	return (
		<div className="flex flex-col gap-2 w-full">
			<div className="flex items-center justify-between text-center divide-x w-full bg-white">
				{headers?.map((header, index) => <div key={index} className={`text-center ${sizing?.includes(index) ? 'hidden md:block' : ''} w-full text-center text-xs font-medium text-gray-500 uppercase tracking-wider p-2`} style={{ width: headerWidth }}>{header}</div>)}
			</div>
			<div className="flex flex-col gap-2 w-full">
				{data?.map((item) => {
					const { uid, ...itemData } = item;

					return (
						<div key={uid} className="p-2 cursor-pointer hover:bg-gray-50 flex items-center justify-between rounded w-full">
							{Object?.values(itemData)?.map((cell, cellIndex) => (
								<div key={cellIndex} className={`whitespace-nowrap text-xs sm:text-sm text-center text-gray-500 ${sizing?.includes(cellIndex) ? 'hidden md:block' : ''} w-full`} style={{ width: headerWidth }}>{cell}</div>
							))}
						</div>
					);
				})}
			</div>
		</div>
	);
};
