import { Tabs } from "../components/tabs/Tabs";

//components
import { Layout } from "../layout/layout"

//tab components
import { AverageDistance } from "../modules/reports/average-distance";
import { AverageDeliveryTime } from "../modules/reports/average-delivery-time";
import { TimeSpent } from "../modules/reports/time-spent";

const tabs = [
    {
        label: 'KM(s) Per Vehicle',
        content: <AverageDistance />,
    },
    {
        label: 'Delivery Time (min)',
        content: <AverageDeliveryTime />,
    },
    {
        label: 'Time Spent (min)',
        content: <TimeSpent />,
    },
];

export const Reports = () => {

    return (
        <Layout>
            <div className="p-2">
                <Tabs tabs={tabs} />
            </div>
        </Layout>
    )
}
