import { Layout } from "../../../layout/layout"

//images
import walert from '../../../assets/icons/walert.svg';
import { useNavigate } from "react-router-dom";

export const IsLoading = () => {
    const navigate = useNavigate()

    const goBack = () => navigate(-1);
    return (
        <Layout>
            <div className="w-full h-full overflow-y-scroll p-2 flex flex-col justify-start">
                <div className='flex items-center justify-between'>
                    <div className='flex flex-col justify-start gap-0'>
                        <p className="text-purple font-semibold text-2xl lg:text-3xl">
                            Edit Clients
                        </p>
                        <p className="text-lg font-bold text-gray-500">#</p>
                    </div>
                    <div className="flex items-center justify-start lg:justify-end gap-2 flex-wrap w-full lg:w-6/12">
                        <button className="border border-red p-1 md:p-2 w-[30%] md:w-[20%] text-red hover:bg-red hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer" onClick={goBack}>Close</button>
                    </div>
                </div>
                <form className="w-full flex flex-col justify-between gap-4 items-start pr-1 md:p-0 h-[90%]">
                    <div className="flex flex-col justify-start gap-6 border-red w-full">
                        <div className="flex items-start justify-start flex-wrap gap-2">
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">User ID</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Company Name</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Registered Company Name</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">REG Number</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">VAT Number</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Contact Person</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Contact Number</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Email</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Delivery Target Time <span className="text-sm">(mins)</span></label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Role</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Store Type</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                        </div>
                        <div className="flex items-start justify-start flex-wrap gap-2 w-full">
                            <div className="w-full border-b-2 border-purple"></div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Business Address</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Shipping Address</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                        </div>
                        <div className="flex items-start justify-start flex-wrap gap-2 w-full">
                            <div className="w-full border-b-2 border-purple"></div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Credit Warning</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Approved Credit Limit</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                        </div>
                    </div>
                    <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full md:w-2/12 rounded"></p>
                </form >
            </div >
        </Layout>
    )
}

export const Error = () => {
    const navigate = useNavigate()

    const goBack = () => navigate(-1);
    return (
        <Layout>
            <div className="w-full h-full overflow-y-scroll p-2 flex flex-col justify-start">
                <div className='flex items-center justify-between'>
                    <div className='flex flex-col justify-start gap-0'>
                        <p className="text-purple font-semibold text-2xl lg:text-3xl">
                            Edit Clients
                        </p>
                        <p className="text-lg font-bold text-gray-500">#</p>
                    </div>
                    <div className="flex items-center justify-start lg:justify-end gap-2 flex-wrap w-full lg:w-6/12">
                        <button className="border border-red p-1 md:p-2 w-[30%] md:w-[20%] text-red hover:bg-red hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer" onClick={goBack}>Close</button>
                    </div>
                </div>
                <form className="w-full flex flex-col justify-between gap-4 items-start pr-1 md:p-0 h-[90%]">
                    <div className="flex flex-col justify-start gap-6 border-red w-full">
                        <div className="flex items-start justify-start flex-wrap gap-2">
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">User ID</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Company Name</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Registered Company Name</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">REG Number</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">VAT Number</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Contact Person</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Contact Number</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Email</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Delivery Target Time <span className="text-sm">(mins)</span></label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Role</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Store Type</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                        </div>
                        <div className="flex items-start justify-start flex-wrap gap-2 w-full">
                            <div className="w-full border-b-2 border-purple"></div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Business Address</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Shipping Address</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                        </div>
                        <div className="flex items-start justify-start flex-wrap gap-2 w-full">
                            <div className="w-full border-b-2 border-purple"></div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Credit Warning</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Approved Credit Limit</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                        </div>
                    </div>
                    <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full md:w-2/12 rounded"></p>
                </form >
                <div className='rounded-lg flex items-center justify-center fixed bg-red text-white p-6 w-11/12 md:6/12 lg:w-6/12 xl:4/12 gap-4'>
                    <figure className='flex items-center justify-center'>
                        <img src={walert} alt='Error' loading='lazy' className='object-contain w-20 md:w-32' />
                    </figure>
                    <p className='text-md font-medium leading-5'>We could not load this client's data</p>
                </div>
            </div >
        </Layout>
    )
}


export const IsEmpty = () => {
    const navigate = useNavigate()

    const goBack = () => navigate(-1);
    return (
        <Layout>
            <div className="w-full h-full overflow-y-scroll p-2 flex flex-col justify-start">
                <div className='flex items-center justify-between'> 
                    <div className='flex flex-col justify-start gap-0'>
                        <p className="text-purple font-semibold text-2xl lg:text-3xl">
                            Edit Clients
                        </p>
                        <p className="text-lg font-bold text-gray-500">#</p>
                    </div>
                    <div className="flex items-center justify-start lg:justify-end gap-2 flex-wrap w-full lg:w-6/12">
                        <button className="border border-red p-1 md:p-2 w-[30%] md:w-[20%] text-red hover:bg-red hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer" onClick={goBack}>Close</button>
                    </div>
                </div>
                <form className="w-full flex flex-col justify-between gap-4 items-start pr-1 md:p-0 h-[90%]">
                    <div className="flex flex-col justify-start gap-6 border-red w-full">
                        <div className="flex items-start justify-start flex-wrap gap-2">
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">User ID</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Company Name</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Registered Company Name</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">REG Number</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">VAT Number</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Contact Person</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Contact Number</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Email</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Delivery Target Time <span className="text-sm">(mins)</span></label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Role</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Store Type</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                        </div>
                        <div className="flex items-start justify-start flex-wrap gap-2 w-full">
                            <div className="w-full border-b-2 border-purple"></div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Business Address</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Shipping Address</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                        </div>
                        <div className="flex items-start justify-start flex-wrap gap-2 w-full">
                            <div className="w-full border-b-2 border-purple"></div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Credit Warning</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                            <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                                <label className="text-base font-medium text-gray-500">Approved Credit Limit</label>
                                <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full rounded"></p>
                            </div>
                        </div>
                    </div>
                    <p className="animate-pulse animate-ping bg-black-skeleton p-6 w-full md:w-2/12 rounded"></p>
                </form >
                <div className='rounded-lg flex items-center justify-center fixed bg-red text-white p-6 w-11/12 md:6/12 lg:w-6/12 xl:4/12 gap-4'>
                    <figure className='flex items-center justify-center'>
                        <img src={walert} alt='Error' loading='lazy' className='object-contain w-20 md:w-32' />
                    </figure>
                    <p className='text-md font-medium leading-5'>Client data not found</p>
                </div>
            </div >
        </Layout>
    )
}

