import React, { createContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';

export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
    const [session, setSession] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    let logoutTimer;

    useEffect(() => {
        if (location.pathname?.includes('/tracking')) {
            return;
        }

        const storedSession = sessionStorage.getItem('session');

        if (storedSession) {
            setSession(JSON.parse(storedSession));
        } else {
            navigate('/login');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            checkIdleTimeout();
        }, 120000);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const login = (userData) => {
        if (userData) {
            setSession(userData);
            sessionStorage.setItem('session', JSON.stringify(userData));

            toast(`Welcome ${userData.name}!`, {
                icon: '👋',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 2000,
            });

            setTimeout(() => {
                navigate('/getting-ready')
            }, 2000);

            resetIdleTimeout();
        }
    };

    const logout = () => {
        setSession(null);
        toast(`Loggin you out.`, {
            icon: '👋',
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
            duration: 900,
        });

        sessionStorage.removeItem('session');
        sessionStorage.removeItem('cartItems');
        sessionStorage.removeItem('store');
        sessionStorage.removeItem('cartItems');
        sessionStorage.removeItem('lastActivity');

        setTimeout(() => {
            navigate('/login');
        }, 1000);
    };

    const resetIdleTimeout = () => {
        clearTimeout(logoutTimer);
        logoutTimer = setTimeout(logout, 3600000); // 1 hour in milliseconds
    };

    const checkIdleTimeout = () => {
        const lastActivity = sessionStorage.getItem('lastActivity');
        const currentTime = Date.now();
        const idleTime = currentTime - lastActivity;
        if (idleTime >= 3600000) { // 1 hour in milliseconds
            logout();
        }
    };

    const handleUserActivity = () => {
        sessionStorage.setItem('lastActivity', Date.now());
        resetIdleTimeout();
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        resetIdleTimeout();

        return () => {
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
            clearTimeout(logoutTimer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SessionContext.Provider value={{ session, login, logout }}>
            {children}
        </SessionContext.Provider>
    );
};