import { useState, useRef } from "react"
import { isEmpty } from 'lodash'

//hooks - enable this when there is a route to pull in all pricing tiers
// import { useQuery } from "../../../hooks/useQuery"

//images
import bchev from '../../../assets/icons/chevup.svg'
import alert from '../../../assets/icons/alert.svg'

//delete this once th route has been implemented
export const pricingTiers = [
    {
        uid: 'tier1',
        tier_name: 'Basic Tier',
    },
    {
        uid: 'tier2',
        tier_name: 'Standard Tier',
    },
    {
        uid: 'tier3',
        tier_name: 'Premium Tier',
    },
];

export const PriceList = ({ handleAssignedTiers }) => {
    const [activeContainer, setActiveContainer] = useState(null);
    const containerRef = useRef([]);
    const [state, setState] = useState({
        tier: null
    })

    //delete this once the route has been implemented
    // const user = JSON.parse(sessionStorage.getItem('session'));

    // const { data, isLoading } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/user/getassignedstoresforuser/${user?.uid}`, 'get');

    // if (isLoading) {
    //     return (
    //         <div className="p-[1.35rem] bg-black-skeleton animate-pulse animate-ping w-1/2 rounded"></div>
    //     )
    // }    

    const assignTier = (tier) => {
        setState({ ...state, tier: tier })
        handleAssignedTiers(tier)
        closeDropDown()
    }

    const toggleContainer = (index) => activeContainer === index ? setActiveContainer(null) : setActiveContainer(index);

    const toggleDropDown = (index) => toggleContainer(index)

    const closeDropDown = () => setActiveContainer(null)

    return (
        <div className="relative z-10 flex flex-col justify-start w-full gap-4">
            <span className="flex items-center justify-between w-full p-3 text-sm font-medium text-center text-gray-500 bg-white border rounded shadow cursor-pointer" onClick={() => toggleDropDown(0)}>
                <span className='flex items-center justify-start gap-2'>
                    <span className="text-left">
                        {state?.tier?.tier_name || 'Select A Pricing Tier'}
                    </span>
                </span>
                <img src={bchev} alt='Up' loading='lazy' className={`w-5 ${activeContainer === 0 && 'rotate-180'} lg:ease-in-out duration-300`} />
            </span>
            <div className={`absolute bg-white shadow rounded top-14 w-full h-[200px] overflow-y-scroll ${activeContainer === 0 ? '' : 'hidden'} fade-in__left`} ref={(ref) => (containerRef.current[0] = ref)}>
                {
                    isEmpty(pricingTiers) ?
                        <p className='flex items-center justify-start w-full gap-2 p-2 text-sm font-medium leading-none text-gray-500 cursor-pointer hover:bg-grey lg:p-4 last:rounded-b first:rounded-t'>
                            <img src={alert} alt='Up' loading='lazy' className={`w-8`} />
                            No stores pricing tiers available
                        </p>
                        :
                        pricingTiers?.map((tier) => (
                            <p className='flex items-center justify-start w-full gap-2 p-2 text-sm font-medium leading-none text-gray-500 cursor-pointer hover:bg-grey lg:p-4 last:rounded-b first:rounded-t' key={tier?.uid} onClick={() => assignTier(tier)}>
                                {`${tier.tier_name?.replace(/\b\w/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); })}`}
                            </p>
                        ))
                }
            </div>
        </div>
    )
}
