
import { useEffect } from "react";
import { Layout } from "../layout/layout";
import { useQuery } from "../hooks/useQuery";
import { useNavigate } from "react-router-dom";
import Table from "../components/tables/orders-table";
import { InvoiceTableHeaders } from "../utils/app-settings";
import { IOTCard } from "../modules/iot/card";

export const IOT = () => {
    const navigate = useNavigate();

    useEffect(() => {
        Notification.requestPermission()
    }, []);

    const headers = InvoiceTableHeaders[0]?.approvals[6]?.iot;
    const sizing = InvoiceTableHeaders[0]?.approvals[6]?.sizing;

    const user = JSON.parse(sessionStorage.getItem('session'));

    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/iot/getopenclose`, 'get', 10000);

    const dummyData = [];

    for (let i = 1; i <= 6; i++) {
        dummyData.push({
            uid: i,
            storeName: `Store ${i}`,
            closeTime: '20:00',
            openTime: '06:00',
            frierOne: Math.floor(Math.random() * 500),
            frierTwo: Math.floor(Math.random() * 500),
            frierThree: Math.floor(Math.random() * 500),
            frierFour: Math.floor(Math.random() * 500),
            // friers: [
            //     {
            //         uid: 1,
            //         temp: Math.floor(Math.random() * 500)
            //     },
            //     {
            //         uid: 2,
            //         temp: Math.floor(Math.random() * 500)
            //     },
            //     {
            //         uid: 3,
            //         temp: Math.floor(Math.random() * 500)
            //     },
            //     {
            //         uid: 4,
            //         temp: Math.floor(Math.random() * 500)
            //     },
            // ]
        });
    }

    const handleInvoiceClick = (invoice) => navigate(`invoice / ${invoice?.order_number}`)

    const renderSections = () => {
        if (user?.type?.toLowerCase()?.includes('super admin') || user?.type?.toLowerCase()?.includes('dc user')) {
            return (
                <div className="w-full h-[88vh]">
                    <Table headers={headers} data={dummyData} onInvoiceClick={handleInvoiceClick} sizing={sizing} />
                </div>
            )
        }
        else {
            return (
                <div className="flex flex-wrap items-start justify-between w-full h-[88vh] pr-1 overflow-y-scroll gap-y-7 gap-x-4 pb-4">
                    <IOTCard data={dummyData} />
                </div>
            )
        }
    }

    return (
        <Layout>
            <div className="p-2">
                {renderSections()}
            </div>
        </Layout>
    )
}
