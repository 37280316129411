import { Tabs } from "../components/tabs/Tabs";

//components
import { Layout } from "../layout/layout"

//tab components
import { GeneralReports } from "../modules/analytics/general";
import { DistributorReports } from "../modules/analytics/distributor";
import { StoreReports } from "../modules/analytics/store";

const tabs = [
    {
        label: 'General',
        content: <GeneralReports />,
    },
    {
        label: 'Store',
        content: <StoreReports />,
    },
    {
        label: 'DC',
        content: <DistributorReports />,
    },
];

export const Analytics = () => {

    return (
        <Layout>
            <div className="p-2">
                <Tabs tabs={tabs} />
            </div>
        </Layout>
    )
}
