import { isEmpty } from 'lodash'
import { useRef, useState } from "react"

//hooks
import { useQuery } from "../../../hooks/useQuery"

//images
import bchev from '../../../assets/icons/chevup.svg'

export const Division = ({ handleDivision }) => {
    const [state, setState] = useState({
        division: ''
    })

    const [activeContainer, setActiveContainer] = useState(null);
    const containerRef = useRef([]);

    const { data, isLoading } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/division`, 'get');

    if (isLoading) {
        return (
            <div className="p-[1.35rem] bg-black-skeleton animate-pulse animate-ping w-full rounded"></div>
        )
    }

    const toggleContainer = (index) => activeContainer === index ? setActiveContainer(null) : setActiveContainer(index);

    const toggleDropDown = (index) => toggleContainer(index)

    const closeModal = () => setActiveContainer(null)

    const handleInput = (division) => {
        setState({ ...state, division: division })

        handleDivision(division)
        closeModal()
    }

    return (
        <div className="flex flex-col justify-start gap-4 z-10 relative w-full">
            <span className="bg-white p-3 border shadow rounded text-sm font-medium text-gray-500 w-full text-center cursor-pointer flex items-center justify-between" onClick={() => toggleDropDown(0)}>
                <span className='flex items-center justify-start gap-2'>
                    <span className="text-left">
                        {state.division?.division || 'Select A Division'}
                    </span>
                </span>
                <img src={bchev} alt='Up' loading='lazy' className={`w-5 ${activeContainer === 0 && 'rotate-180'} lg:ease-in-out duration-300`} />
            </span>
            <div className={`absolute bg-white shadow rounded top-14 w-full h-[200px] overflow-y-scroll ${activeContainer === 0 ? '' : 'hidden'} fade-in__left`} ref={(ref) => (containerRef.current[0] = ref)}>
                {
                    isEmpty(data) ?
                        <p className='w-full hover:bg-grey p-2 lg:p-4 cursor-pointer last:rounded-b first:rounded-t flex items-center justify-start leading-none text-sm font-medium text-gray-500 gap-2'>
                            No roles available
                        </p>
                        :
                        data?.map((division) => (
                            <p className='w-full hover:bg-grey p-2 lg:p-4 cursor-pointer last:rounded-b first:rounded-t flex items-center justify-start leading-none text-sm font-medium text-gray-500 gap-2' name='role' key={division?.uid} onClick={() => handleInput(division)}>
                                {division?.division}
                            </p>
                        ))
                }
            </div>
        </div>
    )
}
