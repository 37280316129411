import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DeliveryNoteTable from "./deliveryNoteTable";
import axios from "axios";
import QRCode from "react-qr-code";
import logo from '../../../assets/logos/favicon.png';
import { useReactToPrint } from 'react-to-print';

const extractTokenFromSession = () => {
    const session = JSON.parse(sessionStorage.getItem('session'));
    const token = session?.token || null;
    return token;
};

const api3 = axios.create({
    baseURL: `${process.env.REACT_APP_ENDPOINT}/api/v1/deliverynote/deliverynotemetadata/`,
    mode: 'no-cors',
    headers: { 'token': extractTokenFromSession() }
})

function DeliveryNote() {

    const { query } = useParams();

    const pdfExportComponent = React.useRef(null);
    const [Data, setData] = useState([])

    function GetData() {
        api3.get(`/${query}`)
            .then(res => setData(res.data))
    }


    const handlePrint = useReactToPrint({
        content: () => pdfExportComponent.current,
        documentTitle: 'Picking Slip',
        onAfterPrint: () => console.log('Printed PDF successfully!'),
    });


    useEffect(() => {
        GetData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="flex flex-col justify-start gap-4">
            <div className="bg-[#616161] w-full fixed top-0">
                <input type="button" value="Print" style={{
                    width: '75px',
                    padding: '5px',
                    margin: '12px',
                    backgroundColor: '#a17efa',
                    color: '#fff',
                    outline: 'none',
                    border: '1px solid #a17efa',
                    borderRadius: '10px',
                    fontFamily: 'Inter, sans-serif',
                }} onClick={handlePrint}></input>
            </div>
            <div style={{
                fontFamily: 'Inter, sans-serif',
                width: '21cm', minHeight: '29.7cm',
                margin: '1cm auto',
                border: '1px #D3D3D3 solid',
                borderRadius: '5px',
                background: 'white',
                boxShadow: '0 0 5px rgba(255, 255, 255, 0.1)'
            }}>
                <div style={{
                    fontFamily: 'Inter, sans-serif',
                    padding: '2cm'
                }} ref={pdfExportComponent}>

                    <div>
                        <div style={{ float: 'left', marginBottom: '-20px' }}>
                            <div style={{ fontSize: '24px', marginBottom: '-10px', fontWeight: '500' }}>Delivery Note</div>
                            <div style={{ fontSize: '27px', marginBottom: '-30px' }}></div>
                        </div>

                        <div style={{
                            float: 'right', marginTop: '20px', margin: '-5px 0 0 20px',
                            maxWidth: '120px',
                            width: '100%',
                            height: 'auto'
                        }}>
                            <img src={logo} loading='lazy' alt="Logo" style={{ marginBottom: '6px' }} />
                        </div>
                    </div>

                    <br />
                    <br />

                    {Data.map((item) => {
                        return (
                            <>
                                <hr className="bg-grey-darker h-[2px] my-3 w-full" />
                                <div style={{
                                    marginTop: '20px',
                                    height: 'auto',
                                    width: '100%',
                                    display: 'grid',
                                    gridTemplateColumns: '210px 210px 10px 250px',
                                    gridTemplateRows: '20px 20px 20px 20px',
                                    gridTemplateAreas: 'top top top top'
                                }}>

                                    <label style={{ marginTop: '-10px', fontSize: '9px', fontWeight: '500' }}>Company Name: </label>
                                    <label style={{ marginTop: '-10px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <div style={{ height: '120px', marginTop: '-10px', borderLeft: '2px solid #a17efa' }}></div>
                                    <label style={{ marginTop: '-10px', fontSize: '9px', fontWeight: '500' }}>Customer Name: {item.company_name}</label>

                                    <label style={{ marginTop: '-20px', fontSize: '9px', fontWeight: '500' }}>Address Line 1: </label>
                                    <label style={{ marginTop: '-20px', fontSize: '9px', fontWeight: '500' }}>Tel. No: </label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-20px', fontSize: '9px', fontWeight: '500' }}>Acc. No: {item.registration_number}</label>

                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}>Address Line 2:</label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}>Email: {item.company_contact_email_address}</label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}>Address Line 1: {item.business_street_address}</label>

                                    <label style={{ marginTop: '-40px', fontSize: '9px', fontWeight: '500' }}>Address Line 3: </label>
                                    <label style={{ marginTop: '-40px', fontSize: '9px', fontWeight: '500' }}>Invoiced By: </label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-40px', fontSize: '9px', fontWeight: '500' }}>Suburb: {item.business_address_suburb}</label>

                                    <label style={{ marginTop: '-50px', fontSize: '9px', fontWeight: '500' }}>Suburb:
                                    </label>
                                    <label style={{ marginTop: '-50px', fontSize: '9px', fontWeight: '500' }}>Invoice No:</label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-50px', fontSize: '9px', fontWeight: '500' }}>City: {item.business_address_city}</label>

                                    <label style={{ marginTop: '-40px', fontSize: '9px', fontWeight: '500' }}>City:</label>
                                    <label style={{ marginTop: '-40px', fontSize: '9px', fontWeight: '500' }}>Invoiced Date:</label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-40px', fontSize: '9px', fontWeight: '500' }}>Tel. {item.company_contact_number}</label>

                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}>VAT No:</label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}>Due Date:</label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}>VAT No: {item.vat_number}</label>

                                    <label style={{ marginTop: '-20px', fontSize: '9px', fontWeight: '500' }}>Reg No:</label>
                                    <label style={{ marginTop: '-20px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-20px', fontSize: '9px', fontWeight: '500' }}>Reg No: {item.registration_number}</label>

                                    <label style={{ marginTop: '0px', fontSize: '9px', fontWeight: '500' }}>Payment Term:</label>
                                    <label style={{ marginTop: '0px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '0px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '0px', fontSize: '9px', fontWeight: '500' }}>Credit Limit: R{(Number(item.approved_credit_limit))?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</label>

                                    <label style={{ marginTop: '-5px', fontSize: '9px', fontWeight: '500' }}>Settlement Discount: {item.early_settlement_discount}</label>
                                    <label style={{ marginTop: '0px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '0px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-5px', fontSize: '9px', fontWeight: '500' }}>Credit Used: R{(item.approved_credit_limit - item.available_balance)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</label>

                                    <label style={{ marginTop: '-4px', fontSize: '9px', fontWeight: '500' }}>Early Settlement Discount:</label>
                                    <label style={{ marginTop: '0px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '0px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-4px', fontSize: '9px', fontWeight: '500' }}>Available Balance: R{(item.available_balance)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</label>
                                </div>
                            </>
                        )
                    })}

                    <hr className="bg-grey-darker h-[2px] my-3" />

                    <DeliveryNoteTable query={query} />

                    <br />
                    <br />

                    <div style={{
                        height: 'auto',
                        width: '100%',
                        display: 'grid',
                        gridTemplateColumns: '120px 300px 300px',
                        gridTemplateRows: '20px 20px 20px',
                        gridTemplateAreas: "top top top"
                    }}>

                        {Data.map((item) => {
                            return (

                                <div style={{ background: 'white', float: 'right', marginTop: '-20px' }}>
                                    <QRCode size={100} renderAs="svg" value={query + "," + query + "," + item.company_name + ", 6"} />
                                </div>

                            )
                        })}

                        <label style={{ fontSize: '16px', marginBottom: '-10px', fontWeight: '500' }}>
                            Picked By
                        </label>

                        <label style={{ fontSize: '16px', marginBottom: '-10px', fontWeight: '500' }}>
                            Checked By
                        </label>

                        <label>

                        </label>

                        <label style={{ marginTop: '40px' }}>
                            ___________________________
                        </label>

                        <label style={{ marginTop: '40px' }}>
                            ___________________________
                        </label>

                    </div>

                    <div style={{ marginBottom: '50px' }}></div>

                </div>
            </div >
        </div >

    )
}

export default DeliveryNote;