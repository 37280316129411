// icons 
import { useEffect } from 'react';
import loadericon from '../assets/icons/splashloader.svg'
import { useNavigate } from 'react-router-dom';

export const SplashScreen = () => {
    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            navigate('/');
        }, 3000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="h-screen bg-white flex items-center justify-center">
            <div className='flex flex-col justify-start items-center gap-2'>
                <figure className='w-20'>
                    <img src={loadericon} alt='' loading='lazy' className='w-full animate-spin__custom' />
                </figure>
                <p className='text-gray-500 font-medium text-xl'>Getting Ready...</p>
            </div>
        </div>
    );
};
