import { useState } from "react"
import { isEmpty } from "lodash";
import { useVehicleContext } from "../context/map-context";
import { AllClients } from "../helpers/getClients";

import storeIcon from '../../../assets/icons/clients.svg'
import axios from "axios";

export const TrackOrders = () => {
    const [store, setStore] = useState()
    const [isOpen, setOpen] = useState(false)
    const [invoices, setInvoices] = useState()

    const { replocation } = useVehicleContext();

    const stores = AllClients();

    if (!stores || isEmpty(stores)) {
        return;
    }

    const toggleDropDown = () => setOpen(!isOpen)
    const closeDropDown = () => setOpen(false)

    const assignStore = async (store) => {
        setStore(store)

        if (!store) {
            return;
        }

        closeDropDown()

        const { uid } = store

        try {
            const user = JSON.parse(sessionStorage.getItem('session'));

            const config = {
                headers: {
                    'token': user?.token,
                    mode: 'no-cors'
                }
            };

            const availableInvoices = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/map/getordertracking/${uid}`, config)

            if (availableInvoices?.data) {

                const invoicesData = availableInvoices?.data?.map((invoice) => {
                    return {
                        uid: invoice.uid,
                        name: invoice?.name,
                        amount: invoice?.amount,
                        orderNumber: invoice.number,
                        latitude: invoice?.latitude,
                        longitude: invoice?.longitude,
                        email: invoice?.email_address,
                        companyName: invoice?.company_name,
                        creationDate: invoice?.creation_date,
                        contactNumber: invoice.contact_number,
                    }
                })

                setInvoices(invoicesData)
            }
        }
        catch (error) { }
    }

    const viewLocations = (invoice) => {
        if (!invoice) {
            return;
        }

        replocation(invoice)

        closeDropDown()
    }

    return (
        <div className="flex flex-col items-start justify-between w-full gap-2 p-2 rounded shadow bg-grey">
            <div className="relative flex flex-col justify-start w-full gap-2 ">
                <div className="flex flex-col justify-start w-full gap-0">
                    <p className="text-base font-medium text-gray-500" >Select A Store</p>
                    <span className="p-3 font-medium bg-white rounded shadow cursor-pointer" onClick={toggleDropDown}>{store ? `${store?.storeName}` : 'Select A Store'}</span>
                </div>
                {
                    isOpen &&
                    <ul className="absolute flex flex-col justify-start w-full h-56 overflow-y-scroll bg-white rounded shadow top-[83px]">
                        {stores?.map((store) =>
                            <li className="flex items-center justify-start w-full gap-2 p-2 text-sm font-medium text-gray-500 bg-white cursor-pointer lg:hover:bg-grey lg:ease-in-out lg:duration-300 first:rounded-t last:rounded-b" key={store?.uid} onClick={() => assignStore(store)}>
                                <img src={storeIcon} alt='StoreIcon' loading="lazy" className="w-6" />
                                <span className="text-base">{store?.storeName}</span>
                            </li>
                        )}
                    </ul>
                }
            </div>
            {
                invoices &&
                <div className="flex flex-col justify-start w-full gap-0 lg:duration-300 lg:ease-in-out">
                    <p className="text-base font-medium text-gray-500" >Select An Invoice</p>
                    <ul className="flex flex-col justify-start w-full h-56 gap-2 pr-1 overflow-y-scroll">
                        {invoices?.map((invoice) => <li key={invoice?.uid} className="w-full p-2 font-medium text-gray-500 cursor-pointer lg:hover:bg-white lg:ease-in-out lg:duration-300" onClick={() => viewLocations(invoice)}>INV #{invoice?.orderNumber}</li>)}
                    </ul>
                </div>}
        </div >
    )
}
