import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import Validator from 'validatorjs';

//hooks
import { InputHandler } from '../../hooks/useInputHandler';

//images
import loadingIcon from '../../assets/icons/wloader.svg'

const validEntry = {
    uid: 'required',
    make: 'required',
    model: 'required',
    licenseNumber: 'required',
    vehRegNum: 'required',
    vin: 'required',
    engineNumber: 'required',
    gvm: 'required',
    tare: 'required',
    AAVehicleRateFee: 'required',
    active: 'required',
    fuelEfficiency: 'required',
}

export const CreateVehicle = () => {
    const [state, setState] = useState({
        isLoading: '',
        status: 1,
    })

    const [errors, setErrors] = useState({});
    const { inputValues, clearValues, handleChange } = InputHandler();

    const toggleStatus = () => setState((prevState) => ({ ...prevState, status: prevState.status === 0 ? 1 : 0 }));

    const createVehicle = async (e) => {
        e.preventDefault()

        const validation = new Validator(inputValues, validEntry);

        if (validation.fails()) {
            setErrors(validation.errors.all());
            return;
        }

        if (validation.passes()) {
            setState({ ...state, isLoading: true });
            try {
                const payLoad = {
                    uid: inputValues?.uid,
                    make: inputValues?.make,
                    model: inputValues?.model,
                    license_number: inputValues?.licenseNumber,
                    registration_number: inputValues?.vehRegNum,
                    vin: inputValues?.vin,
                    engine_number: inputValues?.engineNumber,
                    gvm: inputValues?.gvm,
                    tare: inputValues?.tare,
                    aa_rate_fee: inputValues?.AAVehicleRateFee,
                    active: state.status,
                    fuel_efficiency: inputValues?.fuelEfficiency,
                };

                const session = JSON.parse(sessionStorage.getItem('session'));

                const config = {
                    headers: {
                        'token': session?.token,
                        mode: 'no-cors'
                    }
                };

                const createVehicle = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/vehicle/`, payLoad, config)

                if (createVehicle?.data?.message === 'Success') {
                    setState({ ...state, isLoading: false });
                    toast(`Vehicle created successfully`, {
                        icon: '👋',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });

                    clearValues()
                }
                else {
                    setState({ ...state, isLoading: false });
                    toast('Failed to create vehicle, please try again', {
                        icon: '❌',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });
                }
            }
            catch (error) {
                setState({ ...state, isLoading: false });
                toast('Error creating vehicle, try again later', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });
            }
        }
    }

    return (
        <div className="w-full md:h-[83.5vh] overflow-y-scroll lg:overflow-hidden">
            <form className='w-full flex flex-col justify-between items-start gap-4 h-full pb-6 md:pb-0' onSubmit={createVehicle}>
                <div className='flex flex-col justify-start gap+-4'>
                    <div className='flex items-center justify-between'>
                        <div className='flex flex-col justify-start gap-0 w-full'>
                            <p className="text-purple font-semibold text-2xl lg:text-3xl">
                                Create Vehicles
                            </p>
                        </div>
                    </div>
                    <div className='flex items-start justify-start flex-wrap gap-2'>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>UID</label>
                            <input
                                value={inputValues.uid || ''}
                                onChange={handleChange}
                                type='text'
                                name='uid'
                                placeholder='VH23'
                                className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                            />
                            {errors.uid && <span className='text-[var(--red)] text-sm'>{errors.uid[0]}</span>}
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Make</label>
                            <input
                                value={inputValues.make || ''}
                                onChange={handleChange}
                                type='text'
                                name='make'
                                placeholder='KIA'
                                className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                            />
                            {errors.make && <span className='text-[var(--red)] text-sm'>{errors.make[0]}</span>}
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Model</label>
                            <input
                                value={inputValues.model || ''}
                                onChange={handleChange}
                                type='text'
                                name='model'
                                placeholder='Sorento'
                                className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                            />
                            {errors.model && <span className='text-[var(--red)] text-sm'>{errors.model[0]}</span>}
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>License Number</label>
                            <input
                                value={inputValues.licenseNumber || ''}
                                onChange={handleChange}
                                type='text'
                                name='licenseNumber'
                                placeholder='GH45JKGP'
                                className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                            />
                            {errors.licenseNumber && <span className='text-[var(--red)] text-sm'>{errors.licenseNumber[0]}</span>}
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Veh. register Number</label>
                            <input
                                value={inputValues.vehRegNum || ''}
                                onChange={handleChange}
                                type='text'
                                name='vehRegNum'
                                placeholder='1H41JXMN186'
                                className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                            />
                            {errors.vehRegNum && <span className='text-[var(--red)] text-sm'>{errors.vehRegNum[0]}</span>}
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>VIN</label>
                            <input
                                value={inputValues.vin || ''}
                                onChange={handleChange}
                                type='text'
                                name='vin' placeholder='1HGBH41JXMN109186'
                                className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                            />
                            {errors.vin && <span className='text-[var(--red)] text-sm'>{errors.vin[0]}</span>}
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Engine Number</label>
                            <input
                                value={inputValues.engineNumber || ''}
                                onChange={handleChange}
                                type='text'
                                name='engineNumber'
                                placeholder='1HGBH41JXMN109186'
                                className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                            />
                            {errors.engineNumber && <span className='text-[var(--red)] text-sm'>{errors.engineNumber[0]}</span>}
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>GVM</label>
                            <input
                                value={inputValues.gvm || ''}
                                onChange={handleChange}
                                type='number'
                                name='gvm'
                                placeholder='3400'
                                className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                            />
                            {errors.gvm && <span className='text-[var(--red)] text-sm'>{errors.gvm[0]}</span>}
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Tare</label>
                            <input
                                value={inputValues.tare || ''}
                                onChange={handleChange}
                                type='number'
                                name='tare'
                                placeholder='2280'
                                className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                            />
                            {errors.tare && <span className='text-[var(--red)] text-sm'>{errors.tare[0]}</span>}
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>AA Vehicle Rate Fee</label>
                            <input
                                value={inputValues.AAVehicleRateFee || ''}
                                onChange={handleChange}
                                type='aaratefee'
                                name='AAVehicleRateFee'
                                placeholder='5'
                                className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                            />
                            {errors.AAVehicleRateFee && <span className='text-[var(--red)] text-sm'>{errors.AAVehicleRateFee[0]}</span>}
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Fuel Efficiency <span className='text-sm'>(KM/L as per EOM)</span></label>
                            <input
                                value={inputValues.fuelEfficiency || ''}
                                onChange={handleChange}
                                type='number'
                                name='fuelEfficiency'
                                placeholder='4.5'
                                className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                            />
                            {errors.fuelEfficiency && <span className='text-[var(--red)] text-sm'>{errors.fuelEfficiency[0]}</span>}
                        </div>
                        <div className='flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300'>
                            <label className='text-base font-medium text-gray-500'>{state.status === 0 ? 'In-Active' : 'Active'}</label>
                            <label className='relative inline-block w-20 h-9 rounded-full'>
                                <input type='checkbox' className='peer opacity-0 w-0 h-0' defaultChecked='active' onClick={toggleStatus} />
                                <span className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-${state.status === 0 ? 'red' : 'green'} rounded-full duration-300 before:content-[''] before:absolute before:w-7 before:h-7 before:bottom-1 before:left-1 before:rounded-full before:bg-white before:duration-300 peer-checked:before:translate-x-11 peer-checked:bg-green`} ></span>
                            </label>
                        </div>
                        <button type='submit' className='bg-green text-white py-2 px-10 rounded cursor-pointer text-[1.2rem] mt-2'>
                            {
                                state.isLoading ?
                                    <p className='flex items-center justify-center gap-2'>
                                        Creating
                                        <img src={loadingIcon} alt='Loading' loading='lazy' className='w-6 animate-spin' />
                                    </p>
                                    :
                                    <p className='flex items-center justify-center gap-2'>
                                        Create Vehicle
                                    </p>
                            }
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}