import { useState } from 'react'
import { Toaster, toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

//images
import logo from '../assets/logos/plusLogo.svg'
import loader from '../assets/icons/wloader.svg'

//hooks
import { InputHandler } from '../hooks/useInputHandler';


export const ForgotPassword = () => {
    //to-do: - add state setter
    const [state] = useState({
        isLoading: '',
    })

    const navigate = useNavigate();

    //to-do: - add state setter
    const [errors] = useState({});

    const { inputValues, handleChange } = InputHandler()

    const requestChange = (e) => {
        e.preventDefault();

        setTimeout(() => {
            toast(`This feature in currently in development!`, {
                icon: '👋',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 2900,
            });
        }, 1000);

        setTimeout(() => {
            navigate('/login');
        }, 3000);
    }

    return (
        <div className='w-[100vw] h-screen flex login z-[1] overflow-hidden'>
            <div className='w-full full flex justify-end z-[2] loginbanner bg-cover bg--no-repeat'>
                <div className='w-full h-full hidden md:flex items-center justify-center'>
                </div>
                <form className='w-full md:w-10/12 lg:w-1/2 xl:w-1/2 h-full flex flex-col justify-center items-center gap-6 lg:ease-in-out duration-700 ' onSubmit={requestChange}>
                    <div className='flex items-center justify-start gap-2'>
                        <img src={logo} alt='notification' loading='lazy' className='w-40 cursor-pointer' />
                    </div>
                    <div className='w-11/12 md:w-11/12 lg:w-10/12 mx-auto lg:p-4 rounded-lg flex flex-col gap-4'>
                        <div className='text-center'>
                            <p className='text-gray-500 font-medium text-sm'>Please enter your email to get a password reset link</p>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <label className='text-base lg:text-xl'>Email</label>
                            <input
                                type='text'
                                placeholder='tom@ferreirafresh.co.za'
                                name='email'
                                value={inputValues.email || ''}
                                onChange={handleChange}
                                className={`border-[1px] lg:ease-in-out duration-300 outline-none rounded-lg py-[14px] px-4 placeholder:${errors.password ? 'normal' : 'italic'} placeholder:text-sm w-[100%]`} />
                            {errors.email && <span className="text-base text-[var(--red)]">{errors.email[0]}</span>}
                        </div>
                        <button className='rounded-lg py-3 px-3 text-md lg:text-xl cursor-pointer lg:ease-in-out duration-500 bg-purple text-white hover:shadow-2xl outline-none flex items-center justify-center gap-2' type='submit' disabled={state.isLoading}>
                            Send
                            <img src={loader} alt='' loading='lazy' className={`w-6 animate-spin ${state.isLoading ? 'block' : 'hidden'}`} />
                        </button>
                    </div>
                    <Toaster
                        position="bottom-center"
                        reverseOrder={false}
                        toastOptions={{
                            className: 'flex items-start justify-center gap-2 text-center'
                        }}
                    />
                </form>
            </div>
        </div>
    )
}