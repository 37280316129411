import axios from "axios";
import moment from "moment";
import { isEmpty } from "lodash";
import Validator from 'validatorjs';
import { toast } from "react-hot-toast";
import { useRef, useState } from "react";

import { DatePicker } from "../../shared/date-picker";
import { InputHandler } from "../../hooks/useInputHandler";
import { useTokenFromSession } from "../../helpers/token-extractor";

//images
import bchev from '../../assets/icons/chevup.svg';
import wloader from '../../assets/icons/wloader.svg';
import addIcon from '../../assets/icons/grayadd.svg';
import removeIcon from '../../assets/icons/times.svg';
import { Stores } from "../users/sections/create-stores";

export const CreateSurveys = () => {
    const containerRef = useRef([]);
    const [activeContainer, setActiveContainer] = useState(null);

    const [questions, setQuestions] = useState([]);
    const [questionCounter, setQuestionCounter] = useState(1);

    const [answersCounter, setAnswersCounter] = useState(1);

    const [state, setState] = useState({
        step: 1,
        errors: {},
        endDate: null,
        startDate: null,
        isLoading: false,
        store: null,
        action: [],

        openIndex: null,
        openAnswer: null,
    })

    const token = useTokenFromSession();

    const { inputValues, handleChange } = InputHandler();

    const accessDates = async ({ startDate, endDate }) => setState({ ...state, startDate: startDate, endDate: endDate })

    //creates a survey - step 1
    const createSurvey = async (e) => {
        e.preventDefault();

        const surveyData = { surveyName: 'required' }
        const validation = new Validator(inputValues, surveyData, { required: 'The :attribute is required' });

        if (validation.fails()) {
            setState({ ...state, errors: validation.errors.all() });
            return;
        }

        if (validation.passes()) {
            setState({ ...state, errors: '', isLoading: true });

            if (state.startDate && state.endDate) {

                try {
                    const payLoad = {
                        name: inputValues.surveyName,
                        start_date: state.startDate,
                        end_date: state.endDate,
                        date_created: moment().format('YYYY-MM-DD')
                    }

                    const config = {
                        headers: {
                            'token': token,
                            mode: 'no-cors'
                        }
                    };

                    const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/survey/insertsurvey`, payLoad, config)

                    if (response?.status === 200 && !isEmpty(response?.data) && response?.statusText === '') {

                        toast('Survey created, add survey questions', {
                            icon: '✅',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                            duration: 3000,
                        });

                        setState({ ...state, errors: '', isLoading: false, step: 2 });
                    }
                }
                catch (error) {
                    toast('Failed to create survey names, please retry', {
                        icon: '❌',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });

                    setState({ ...state, errors: '', isLoading: false, step: 1 });
                }

            }
            else {
                toast('Please select start and end dates', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                setState({ ...state, errors: '', isLoading: false, step: 1 });
            }
        }
    }

    const toggleContainer = (index) => activeContainer === index ? setActiveContainer(null) : setActiveContainer(index);

    const toggleDropDown = (index, questionID, answerID) => {
        toggleContainer(index)
        setState({ ...state, openIndex: questionID, openAnswer: answerID })
    }

    const closeModal = () => setActiveContainer(null)

    const handleActions = (selectedAction, questionID, answerID) => {
        closeModal();

        const answerAction = {
            answerID: answerID,
            action: selectedAction?.action,
            questionID: questionID,
        };

        setState(prevState => {
            const updatedAction = [...prevState.action];

            const indexToUpdate = updatedAction.findIndex(item => item.answerID === answerID && item.questionID === questionID);

            if (indexToUpdate !== -1) {
                updatedAction[indexToUpdate] = answerAction;
            }
            else {
                updatedAction.push(answerAction);
            }

            return {
                ...prevState,
                action: updatedAction,
            };
        });
    };

    const options = [{ id: 1, action: 'Continue' }, { id: 3, action: 'End Survey' }, { id: 3, action: 'Add Comment' }]

    //manage questions and answers for the survey
    const addQuestion = (e) => {
        e.preventDefault();

        if (questions?.length < 5) {
            const newQuestion = {
                id: questionCounter,
                text: `Question ${questionCounter}`,
                answers: [
                    // Add one answer by default when adding a new question
                    {
                        id: answersCounter,
                        text: `Answer ${answersCounter}`,
                        answers: [],
                    },
                ],
            };

            setQuestions([...questions, newQuestion]);
            setQuestionCounter(questionCounter + 1);
            setAnswersCounter(answersCounter + 1);
        } else {
            toast('Maximum is 5 questions', {
                icon: '🚨',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }
    };

    const addAnswer = (questionID) => {
        const questionToUpdate = questions.find(question => question.id === questionID);

        if (questionToUpdate) {
            if (questionToUpdate.answers.length < 3) {
                const newAnswer = {
                    id: answersCounter,
                    text: `Answer ${answersCounter}`,
                    answers: [],
                };

                // Update the question with the new answer
                const updatedQuestions = questions.map(question => {
                    if (question.id === questionID) {
                        return {
                            ...question,
                            answers: [...question.answers, newAnswer],
                        };
                    }
                    return question;
                });

                setQuestions(updatedQuestions);
                setAnswersCounter(answersCounter + 1);
            } else {
                toast('Maximum is 3 answers for this question', {
                    icon: '🚨',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });
            }
        }
    };

    const removeQuestion = (questionID) => {
        const updatedQuestions = questions.filter((question) => question.id !== questionID);
        setQuestions(updatedQuestions);
    };

    const removeAnswer = (questionID, answerID) => {
        const updatedQuestions = questions.map((question) => {
            if (question.id === questionID) {
                const updatedAnswers = question.answers.filter(answer => answer.id !== answerID);
                return { ...question, answers: updatedAnswers };
            }
            return question;
        });

        setQuestions(updatedQuestions);
    };
    //manage questions and answers for the survey

    const handleAssignedStores = (params) => console.log(params)

    const simulatedExecution = (e) => {
        e.preventDefault();
        setTimeout(() => {

            toast('Survey saved successfully', {
                icon: '✅',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });

            window.location.reload();

            setState({ ...state, errors: '', isLoading: false, step: 1 });

        }, 2000);
    };

    const renderSections = () => {
        if (state.step === 1) {
            return (
                <form className="flex flex-col justify-start w-full h-full gap-4">
                    <div className='flex items-end justify-start w-full gap-4'>
                        <div className="flex items-end justify-start w-1/3 gap-4 lg:w-8/12">
                            <div className='w-full md:w-6/12'>
                                <label className="text-base font-medium text-gray-500" >Survey Name</label>
                                <input
                                    className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                                    type='text'
                                    name="surveyName"
                                    placeholder="Summer Promo 2023"
                                    value={inputValues.surveyName || ''}
                                    onChange={handleChange}
                                />
                                {state.errors.surveyName && <span className="text-base text-[var(--red)]">{state.errors.surveyName[0]}</span>}
                            </div>
                            <div className='relative flex flex-col justify-start w-full gap-1 md:w-6/12'>
                                <DatePicker accessDates={accessDates} />
                            </div>
                        </div>
                        <div className='relative flex flex-col justify-start w-1/3 gap-2 md:w-7/12'>
                            <button className='bg-green text-white text-sm p-[12px] shadow cursor-pointer rounded w-full md:w-1/4' type='submit' onClick={createSurvey}>
                                {state.isLoading ?
                                    <div className="flex items-center justify-center gap-2">
                                        <p>Saving</p>
                                        <img src={wloader} alt='loading' loading="lazy" className="w-5 animate-spin" />
                                    </div>
                                    :
                                    <p>Next</p>
                                }
                            </button>
                        </div>
                    </div>
                </form>
            )
        }
        if (state.step === 2) {
            return (
                <form className="flex flex-col justify-start h-full gap-4 lg:mt-4">
                    <div className="w-full h-[87%] flex items-start justify-between gap-2">
                        <div className="flex flex-col justify-start w-8/12 h-full gap-2">
                            <button className="w-4/12 p-2 text-sm font-medium text-center text-white rounded cursor-pointer bg-purple lg:w-2/12" onClick={addQuestion}>Add Question</button>
                            <div className="flex flex-col justify-start w-full h-full gap-4 pb-56 pr-1 overflow-y-scroll">
                                {questions.map((question, questionIndex) =>
                                    <div className='flex flex-col w-full gap-0' key={question?.id}>
                                        <div className="">
                                            <label className='text-base font-medium text-gray-500'>{question?.text}</label>
                                            <div className="flex items-end justify-start w-1/2 gap-2">
                                                <input
                                                    type='text'
                                                    placeholder='Did you see our summer promotion?'
                                                    name={`question${question?.id}`}
                                                    value={inputValues[`question${question?.id}`] || ''}
                                                    onChange={handleChange}
                                                    className={`border-[1px] lg:ease-in-out duration-300 outline-none rounded p-2 placeholder:text-sm w-full`} />
                                                <figure className="p-1 border rounded cursor-pointer border-grey hover:border-red">
                                                    <img src={removeIcon} loading="lazy" alt='Remove Question' className="w-10" onClick={() => removeQuestion(question?.id)} />
                                                </figure>
                                            </div>
                                        </div>
                                        <div className="flex flex-col justify-start w-full gap-2">
                                            {question.answers.map((answer, answerIndex) =>
                                                <div className="flex items-end justify-start w-full gap-2">
                                                    <div className="flex flex-col justify-start w-1/2 gap-0">
                                                        <label className='text-base font-medium text-gray-500'>Answer {questionIndex + 1}.{answerIndex + 1}</label>
                                                        <input
                                                            type='text'
                                                            placeholder={`Yes`}
                                                            name={`answer${questionIndex + 1}.${answerIndex + 1}`}
                                                            value={inputValues[`answer${questionIndex + 1}.${answerIndex + 1}`] || ''}
                                                            onChange={handleChange}
                                                            className={`border-[1px] lg:ease-in-out duration-300 outline-none rounded p-2 placeholder:text-sm w-full`} />
                                                    </div>
                                                    <div className="w-1/4">
                                                        <label className='text-base font-medium text-gray-500'>Action</label>
                                                        <div className="relative flex flex-col justify-start w-full gap-4">
                                                            <span className="bg-white p-[13px] border shadow rounded text-sm font-medium text-gray-500 w-full text-center cursor-pointer flex items-center justify-between" onClick={() => toggleDropDown(0, question?.id, answer?.id)}>
                                                                <span className='flex items-center justify-start gap-2'>
                                                                    <span className="text-left">
                                                                        {state.action.filter(action => action.questionID === question?.id && action.answerID === answer?.id)[0]?.action || 'Select An Action'}
                                                                    </span>
                                                                </span>
                                                                <img src={bchev} alt='Up' loading='lazy' className={`w-5 ${activeContainer === 0 && 'rotate-180'} lg:ease-in-out duration-300`} />
                                                            </span>
                                                            <div className={`absolute bg-white shadow rounded top-14 w-full overflow-y-scroll z-40 ${(activeContainer === 0 && state.openIndex === question?.id && state.openAnswer === answer?.id) ? '' : 'hidden'} fade-in__left`} ref={(ref) => (containerRef.current[0] = ref)}>
                                                                {options?.map((action, index) => <p className='flex items-center justify-start w-full gap-2 p-2 text-sm font-medium leading-none text-gray-500 cursor-pointer hover:bg-grey lg:p-4 last:rounded-b first:rounded-t' name='action' key={index} onClick={() => handleActions(action, question?.id, answer?.id)}>{action?.action}</p>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`flex items-center justify-start gap-2 w-1/4`}>
                                                        {answerIndex === question.answers.length - 1 ?
                                                            <figure className="p-1 border rounded cursor-pointer border-grey hover:border-green" onClick={() => addAnswer(question.id)}>
                                                                <img src={addIcon} loading="lazy" alt="Add Answer" className="w-8" />
                                                            </figure>
                                                            :
                                                            <figure className="p-1 border rounded cursor-pointer border-grey hover:border-green" onClick={() => removeAnswer(question.id, answer?.id)}>
                                                                <img src={removeIcon} loading="lazy" alt='Remove Question' className="w-8" />
                                                            </figure>
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="w-2/12">
                            <label className='text-base font-medium text-gray-500'>Assign Stores</label>
                            <Stores handleAssignedStores={handleAssignedStores} />
                        </div>
                        <div className="w-2/12">
                            <label className='text-base font-medium text-gray-500'>Submit Survey</label>
                            <button className="px-10 py-2 text-sm font-medium text-center text-white rounded cursor-pointer bg-purple" onClick={simulatedExecution}>Complete Set-Up</button>
                        </div>
                    </div>
                </form>
            )
        }
    }

    return (
        <div className="flex flex-col justify-start w-full h-full gap-4">
            {renderSections()}
        </div>
    )
}