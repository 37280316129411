export const Portal = ({ content, closeModal, processAssigner, handleCheckbox }) => {
    return (
        <>
            <div className={`absolute w-full h-5/6 flex items-center justify-center rounded-lg bg-black-light fade-in lg:ease-in-out duration-500`}>
                <div className='max-w-sm w-full bg-white p-4 rounded-md flex flex-col justify-center gap-4'>
                    <div className='flex flex-col justify-start gap-4'>
                        <p className='text-gray-500 font-medium text-xl'>Are you sure you want to <span className='text-md font-bold'>{content?.action === 'prevStage' ? 'return to previuos stage' : content?.action}</span> Order No:{content?.invoiceNo?.uid}</p>
                        <label htmlFor="sms-check" className='text-gray-500 font-medium  text-[16px] flex items-center justify-start gap-2'>
                            <input type="checkbox" htmlFor="sms-check" className="accent-green w-6 h-6 border border-green rounded" onChange={handleCheckbox} />
                            Send SMS notification
                        </label>
                    </div>
                    <div className='flex items-center justify-between'>
                        <button className='bg-white text-red hover:bg-red hover:text-white border border-red rounded px-4 py-1 lg:ease-in-out duration-300 cursor-pointer w-1/3 text-[1.2rem] font-medium' onClick={closeModal}>No</button>
                        <button className='bg-white text-green hover:bg-green hover:text-white border border-green rounded px-4 py-1 lg:ease-in-out duration-300 cursor-pointer w-1/3 text-[1.2rem] font-medium' onClick={processAssigner}>Yes</button>
                    </div>
                </div>
            </div>
        </>
    );
}