import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-hot-toast';

//hooks
import { useQuery } from "../../hooks/useQuery.js";
import { InvoiceTable } from "../../components/tables/invoice-table.js";

//components
import { QuantityUpdater } from "./quantity-updater.js";

//images
import feather from '../../assets/icons/feather.svg'

//table headers
import { InvoiceTableHeaders } from "../../utils/app-settings";

export const InStock = ({ invoiceStage }) => {
	const [state, setState] = useState({
		product: '',
		action: '',
		isVisible: null,
		invoice: '',
		sendSMS: false,
	})
	const { query } = useParams();

	const { data } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/ordermodel/items/${query}`, 'get', 20000)

	//determines the headers to use based on invoice stage
	const headers = invoiceStage?.toLowerCase()?.includes('picking') ? InvoiceTableHeaders[0]?.approvals[2]?.picking : invoiceStage?.toLowerCase()?.includes('stock') ? InvoiceTableHeaders[0]?.approvals[1]?.stock : invoiceStage?.toLowerCase()?.includes('loading') ? InvoiceTableHeaders[0]?.approvals[3]?.loading : invoiceStage?.toLowerCase()?.includes('delivery') ? InvoiceTableHeaders[0]?.approvals[4]?.delivery : invoiceStage?.toLowerCase()?.includes('delivered') ? InvoiceTableHeaders[0]?.approvals[5]?.delivered : InvoiceTableHeaders[0]?.approvals[0].defaultHeaders;

	const responsiveSizing = invoiceStage?.toLowerCase()?.includes('picking') ? [0, 2, 3, 5, 6] : invoiceStage?.toLowerCase()?.includes('stock') ? [0, 3, 4, 5] : [0, 3, 4, 5];

	//determines what to display in the tables
	const inStock = data?.map((property) => {
		const exclsell = property.exclsell && parseFloat(property.exclsell).toLocaleString(undefined, {
			minimumFractionDigits: 2,
		});

		const incsell = property.incsell && parseFloat(property.incsell).toLocaleString(undefined, {
			minimumFractionDigits: 2,
		});

		if (invoiceStage?.toLowerCase()?.includes('picking')) {
			return {
				order_number: property.uid,
				product: property.name?.length >= 20 ? `${property.name?.slice(0, 20)}...` : property.name,
				quantity: property.quantity_ordered,
				appproved_quantity: property.quantity_stock_provisioned,
				quantity_picked: (
					<div className="flex items-center justify-center w-full gap-2" onClick={() => openModal(property)}>
						<p className="text-xs">{property.quantity_picked}</p>
						<img src={feather} alt='edit' loading="lazy" className="w-5" />
					</div>
				),
				price_excl: 'R ' + exclsell,
				price_incl: 'R ' + incsell,
				total: 'R ' + parseFloat(property.incsell * property.quantity_ordered).toLocaleString(undefined, {
					minimumFractionDigits: 2,
				})
			};
		}
		else if (invoiceStage?.toLowerCase()?.includes('stock')) {
			return {
				order_number: property.uid,
				product: property.name?.length >= 20 ? `${property.name?.slice(0, 20)}...` : property.name,
				quantity: property.quantity_ordered,
				quantity_picked: (
					<div className="flex items-center justify-center w-full gap-2" onClick={() => openModal(property)}>
						<p className="text-xs">{property.quantity_stock_provisioned}</p>
						<img src={feather} alt='edit' loading="lazy" className="w-5" />
					</div>
				),
				price_excl: 'R ' + exclsell,
				price_incl: 'R ' + incsell,
				total: 'R ' + parseFloat(property.incsell * property.quantity_stock_provisioned).toLocaleString(undefined, {
					minimumFractionDigits: 2,
				})
			};
		}
		else if (invoiceStage?.toLowerCase()?.includes('loading')) {
			return {
				order_number: property.uid,
				product: property.name?.length >= 20 ? `${property.name?.slice(0, 20)}...` : property.name,
				quantity_picked: (
					<div className="flex items-center justify-center w-full gap-2">
						<p className="text-xs">{property.quantity_picked}</p>
					</div>
				),
				price_excl: 'R ' + exclsell,
				price_incl: 'R ' + incsell,
				total: 'R ' + parseFloat(property.incsell * property.quantity_ordered).toLocaleString(undefined, {
					minimumFractionDigits: 2,
				})
			};
		}
		else if (invoiceStage?.toLowerCase()?.includes('delivered')) {
			return {
				order_number: property.uid,
				product: property.name?.length >= 20 ? `${property.name?.slice(0, 20)}...` : property.name,
				quantity_picked: (
					<div className="flex items-center justify-center w-full gap-2" onClick={() => openModal(property)}>
						<p className="text-xs">{property.quantity_delivered}</p>
						<img src={feather} alt='edit' loading="lazy" className="w-5" />
					</div>
				),
				price_excl: 'R ' + exclsell,
				price_incl: 'R ' + incsell,
				total: 'R ' + parseFloat(property.incsell * property.quantity_ordered).toLocaleString(undefined, {
					minimumFractionDigits: 2,
				})
			};
		}
		else if (invoiceStage?.toLowerCase()?.includes('delivery')) {
			return {
				order_number: property.uid,
				product: property.name?.length >= 20 ? `${property.name?.slice(0, 20)}...` : property.name,
				quantity: property.quantity_picked,
				quantity_picked: (
					<div className="flex items-center justify-center w-full gap-2" onClick={() => openModal(property)}>
						<p className="text-xs">{property.quantity_delivered}</p>
						<img src={feather} alt='edit' loading="lazy" className="w-5" />
					</div>
				),
				price_excl: 'R ' + exclsell,
				price_incl: 'R ' + incsell,
				total: 'R ' + parseFloat(property.incsell * property.quantity_ordered).toLocaleString(undefined, {
					minimumFractionDigits: 2,
				})
			};
		}
		else {
			return {
				order_number: property.uid,
				product: property.name?.length >= 20 ? `${property.name?.slice(0, 20)}...` : property.name,
				quantity_picked: (
					<div className="flex items-center justify-center w-full gap-2" onClick={() => openModal(property)}>
						<p className="text-xs">{property.quantity_ordered}</p>
						<img src={feather} alt='edit' loading="lazy" className="w-5" />
					</div>
				),
				price_excl: 'R ' + exclsell,
				price_incl: 'R ' + incsell,
				total: 'R ' + parseFloat(property.incsell * property.quantity_ordered).toLocaleString(undefined, {
					minimumFractionDigits: 2,
				})
			};
		}
	});

	//token extractor from session storage 
	const extractTokenFromSession = () => {
		const session = JSON.parse(sessionStorage.getItem('session'));
		const token = session?.token || null;
		return token;
	};

	useEffect(() => {
		extractTokenFromSession()
	})

	//api req header configuration
	const config = {
		headers: {
			'token': extractTokenFromSession(),
			mode: 'no-cors'
		}
	};

	//handles quantity updaters
	const updateQuantity = async (product, inputValues) => {
		if (inputValues?.adjustValue && product) {

			try {
				const payLoad = {
					amount: inputValues?.adjustValue,
					fk_composite_order_uid: query,
					fk_composite_item_uid: product?.uid,
				}

				const stage = invoiceStage?.toLowerCase()
				const url = stage?.includes('stock') ? 'api/v1/ordermodel/udatequantityofitem' : stage?.includes('picking') ? 'api/v1/ordermodel/updateitemquantitypicked' : stage?.includes('delivery') ? 'api/v1/ordermodel/updateitemquantitydelivered' : `api/v1/ordermodel/updateitemquantityordered`

				const valueAdjust = await axios.put(`${process.env.REACT_APP_ENDPOINT}/${url}`, payLoad, config)

				if (valueAdjust?.data?.message === "Success") {

					closeModal()

					toast(`Quantity succesfully updated`, {
						icon: '✅',
						style: {
							borderRadius: '10px',
							background: '#333',
							color: '#fff',
						},
						duration: 3000,
					});

				}
				else {
					toast(`Failed to update quantity, please try again`, {
						icon: '❌',
						style: {
							borderRadius: '10px',
							background: '#333',
							color: '#fff',
						},
						duration: 3000,
					});
				}
			}
			catch (e) {
				toast(`Failed to update quantity, please try again`, {
					icon: '❌',
					style: {
						borderRadius: '10px',
						background: '#333',
						color: '#fff',
					},
					duration: 3000,
				});
			}
		}
	};

	const openModal = (property) => setState({ ...state, isVisible: !state.isVisible, product: property });

	const closeModal = () => setState((prevState) => ({ ...prevState, isVisible: false, sendSMS: false }));

	return (
		<div className="relative flex flex-col justify-start w-full gap-2">
			<p className='font-semibold text-gray-500 text-md'>Available Stock</p>
			{state.isVisible && <QuantityUpdater product={state.product} closeModal={closeModal} updateQuantity={updateQuantity} stage={invoiceStage} />}
			<InvoiceTable headers={headers} data={inStock} sizing={responsiveSizing} />
		</div>
	);
};
