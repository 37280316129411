import { useState, useEffect } from "react"
import { AllVehicles } from "../helpers/getVehicles";
import { isEmpty } from "lodash";
import { useVehicleContext } from "../context/map-context";
import { DatePicker } from "../../../shared/date-picker";

import truckIcon from '../../../assets/icons/truck-front.svg'

export const VehicleHistory = () => {
    const [vehicle, setVehicle] = useState()
    const [isOpen, setOpen] = useState(false)
    const [dates, setDates] = useState({
        startDate: '',
        endDate: '',
    })

    useEffect(() => {
        if (dates?.startDate && dates?.endDate && vehicle) {

            getVehicleHistory(dates, vehicle)

            closeDropDown()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dates, vehicle])

    const vehicles = AllVehicles();
    const { getVehicleHistory } = useVehicleContext();

    if (!vehicles || isEmpty(vehicles)) {
        return;
    }

    const toggleDropDown = () => setOpen(!isOpen)
    const closeDropDown = () => setOpen(false)

    const accessDates = async ({ startDate, endDate }) => setDates({ ...dates, startDate: startDate, endDate: endDate })

    const assignVehicle = async (vehicle) => {
        setVehicle(vehicle)
        closeDropDown()
    }

    return (
        <div className="flex items-end justify-between w-full gap-4 p-2 rounded shadow bg-grey">
            <div className="relative flex flex-col justify-start w-1/2 gap-2 ">
                <div className="flex flex-col justify-start w-full gap-0">
                    <p className="text-base font-medium text-gray-500" >Select A Vehicle</p>
                    <span className="p-3 font-medium bg-white rounded shadow cursor-pointer" onClick={toggleDropDown}>{vehicle ? `${vehicle?.make} - ${vehicle?.license_number}` : 'Select A Vehicle'}</span>
                </div>
                {
                    isOpen &&
                    <ul className="absolute flex flex-col justify-start w-full h-56 overflow-y-scroll bg-white rounded shadow top-[83px]">
                        {vehicles?.map((vehicle) =>
                            <li className="flex items-center justify-start w-full gap-2 p-2 text-sm font-medium text-gray-500 bg-white cursor-pointer lg:hover:bg-grey lg:ease-in-out lg:duration-300 first:rounded-t last:rounded-b" key={vehicle?.uid} onClick={() => assignVehicle(vehicle)}>
                                <img src={truckIcon} alt='StoreIcon' loading="lazy" className="w-6" />
                                <span className="text-base">{vehicle?.make}</span> -
                                <span>{vehicle?.model}</span>
                                <span> # ({vehicle?.license_number})</span>
                            </li>
                        )}
                    </ul>
                }
            </div>
            <div className="flex flex-col justify-start w-1/2 gap-0 ">
                <DatePicker accessDates={accessDates} />
            </div>
        </div>
    )
}
