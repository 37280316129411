import axios from 'axios';
import { isEmpty } from "lodash";
import { toast } from 'react-hot-toast';

//hooks
import { useQuery } from "../../../hooks/useQuery";

//request configuration
import { config } from '../../../utils/app-settings';

import ploadImage from '../../../assets/icons/uploadImage.svg';

export const Logo = () => {

    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/documents/getlogo`, 'get', 10000);

    if (isLoading) { }

    if (error) { }

    if (isEmpty(data)) {
    }


    const logoUploader = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const fileType = e.target.files[0].type;
        if (file?.size / 1000000 < 3.5) {
            try {
                let currentDate = new Date();
                let year = currentDate.getFullYear();
                let month = String(currentDate.getMonth() + 1).padStart(2, '0');
                let day = String(currentDate.getDate()).padStart(2, '0');
                let formattedDate = `${year}-${month}-${day}`;
                const payload = {
                    uid: Number(e.target.id),
                    image: file,
                    image_name: e.target.name,
                    file_type: fileType,
                    date: formattedDate,
                };
                const formData = new FormData();
                formData.append('uid', payload.uid);
                formData.append('image', payload.image);
                formData.append('image_name', payload.image_name);
                formData.append('file_type', payload.file_type);
                formData.append('date', payload.date);

                const uploadFile = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/documents/uploadlogo`, formData, config);

                if (uploadFile?.data === 'Success') {
                    toast(`Logo uploaded successfully`, {
                        icon: ':wave:',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });
                } else {
                    toast('Failed to upload logo, please try again', {
                        icon: ':x:',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });
                }
            } catch (error) {
                toast('Error uploading logo, try again later', {
                    icon: ':x:',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });
            } finally {
                e.target.value = null;
            }
        } else {
            toast(
                `Image size too large, yours was ${(file?.size / 1000000).toFixed(2)}mb, the max limit is 3.5mb`,
                {
                    duration: 6000,
                }
            );
            e.target.value = null;
        }
    };

    return (
        <div className="flex flex-col justify-start w-full h-full gap-4">
            <div className="text-lg">
                <p className="text-xl font-medium text-gray-500">App Logo</p>
            </div>
            <hr className="w-full border border-grey-darker" />
            <div className="flex items-start justify-start w-full md:w-6/12">
                <div className="flex flex-col w-1/2 md:px-0">
                    <p className="text-base font-bold text-center md:text-left">
                        New Logo <span className="text-sm font-medium">(image size limit 3.5 mb)</span>
                    </p>
                    <div className="flex items-center justify-between mt-2">
                        <div className="relative max-w-xs bg-white rounded-lg shadow w-10/12">
                            <input type="file" id="5" name="Logo" className="hidden" onChange={(e) => logoUploader(e)} />
                            <label htmlFor="5" className="z-20 flex flex-col-reverse items-center justify-center w-full cursor-pointer h-20">
                                <p className="z-10 text-xs font-light text-center text-gray-500">Upload Logo</p>
                                <figure className="w-10">
                                    <img src={ploadImage} alt="Upload" loading="lazy" className="w-full" />
                                </figure>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-1/2 md:px-0 ml-4">
                    <p className="text-base font-bold text-center md:text-left">Current Logo</p>
                    <div className="relative w-10/12 max-w-xs bg-white rounded-lg shadow mt-2">
                        <figure className="relative w-full h-20 rounded-md">
                            <img
                                alt="Logo"
                                src={`${process.env.REACT_APP_LOGO_ENDPOINT}/${data[0]?.image_name}`}
                                className="absolute object-cover w-full h-full rounded-md"
                            />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    )
}
