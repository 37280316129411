import { useState } from 'react';
import { toast } from 'react-hot-toast';
import Validator from 'validatorjs';

import cancelIcon from '../../assets/icons/cancel.svg'

import { InputHandler } from '../../hooks/useInputHandler';
import axios from 'axios';

export const MultiStepForm = ({ cancelAccessRequest }) => {
    const [state, setState] = useState({
        isLoading: false,
    })

    const [errors, setErrors] = useState({});

    const { inputValues, handleChange, clearValues } = InputHandler();

    const requestAccess = async (e) => {
        e.preventDefault()

        const input = { name: 'required', email: 'required', contact: 'required' }
        const validation = new Validator(inputValues, input, { required: 'Your :attribute is required' });

        if (validation.fails()) {
            setErrors(validation.errors.all());
            return;
        }


        if (validation.passes()) {
            setErrors('');
            setState({ ...state, isLoading: true });

            try {
                const userCredentials = { Subject: 'Access Request - Legend Ordering ', Text: `${inputValues?.name} with email ${inputValues.email} is requesting access to Legend Ordering.\nPlease contact them via their email or cell: ${inputValues.contact}.\n\nKind Regards\n\nLegend Systems` }

                const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/sms/sendmail`, userCredentials)

                if (response?.data === 'Success') {
                    toast('Request sent successfully, please await a call from us', {
                        icon: '👋',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });

                    setState({ ...state, isLoading: false });

                    clearValues()
                }
            }
            catch (error) {
                toast('Please refresh and try again', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                setState({ ...state, isLoading: false });
            }
        }
    }

    return (
        <div className="absolute z-40 flex items-center justify-center w-full h-screen bg-black-skeleton">
            <div className="z-50 flex flex-col justify-between w-11/12 gap-4 p-2 bg-white rounded lg:p-4 md:w-8/12">
                <div className="flex flex-col justify-start gap-4">
                    <div className='flex items-center justify-between'>
                        <p className="flex items-center justify-between text-base font-medium text-gray-500 lg:text-3xl">Legend Ordering</p>
                        <img src={cancelIcon} alt='Cancel' loading="lazy" className="w-10 cursor-pointer" onClick={cancelAccessRequest} />
                    </div>
                    <form className='flex flex-col justify-start gap-4'>
                        <div className='flex flex-wrap items-center justify-between w-full gap-6 lg:flex-nowrap'>
                            <div className='flex flex-col w-full gap-1 lg:w-1/3'>
                                <label className='text-base font-medium text-gray-500'>Name</label>
                                <input
                                    id='name'
                                    type='text'
                                    name='name'
                                    placeholder='Tom Doe'
                                    onChange={handleChange}
                                    value={inputValues.name || ''}
                                    className={`border lg:ease-in-out duration-300 outline-none rounded p-3 placeholder:text-sm placeholder:italic placeholder:font-light w-full text-base`} />
                                {errors.name && <span className="text-base text-red">{errors.name[0]}</span>}
                            </div>
                            <div className='flex flex-col w-full gap-1 lg:w-1/3'>
                                <label className='text-base font-medium text-gray-500'>Email</label>
                                <input
                                    id="email"
                                    type='email'
                                    name='email'
                                    onChange={handleChange}
                                    value={inputValues.email || ''}
                                    placeholder='tom@legendordering.co.za'
                                    className={`border lg:ease-in-out duration-300 outline-none rounded p-3 placeholder:text-sm placeholder:italic placeholder:font-light w-full text-base`} />
                                {errors.email && <span className="text-base text-red">{errors.email[0]}</span>}
                            </div>
                            <div className='flex flex-col w-full gap-1 lg:w-1/3'>
                                <label className='text-base font-medium text-gray-500'>Contact Number</label>
                                <input
                                    id="email"
                                    type='number'
                                    name='contact'
                                    onChange={handleChange}
                                    placeholder='011 287 2367'
                                    value={inputValues.contact || ''}
                                    className={`border lg:ease-in-out duration-300 outline-none rounded p-3 placeholder:text-sm placeholder:italic placeholder:font-light w-full text-base`} />
                                {errors.contact && <span className="text-base text-red">{errors.contact[0]}</span>}
                            </div>
                        </div>
                        <button className='flex self-end justify-center w-full p-2 text-center text-white rounded cursor-pointer lg:w-3/12 bg-purple' onClick={requestAccess}>{state.isLoading ? 'Submitting Request...' : 'Submit Request'}</button>
                    </form>
                </div>
            </div>
        </div>
    );
};
