'use client'

import { useEffect, useState } from 'react';

export const useLocation = () => {
    const [location, setLocation] = useState({
        latitude: null,
        longitude: null,
        accuracy: null,
        failure: null,
    });

    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude.toFixed(7),
                        longitude: position.coords.longitude.toFixed(7),
                        accuracy: position.coords.accuracy,
                        failure: null,
                    });
                },
                (failure) => {
                    setLocation({
                        latitude: null,
                        longitude: null,
                        accuracy: null,
                        failure: failure.message,
                    });
                },
                { enableHighAccuracy: true }
            );
        } else {
            setLocation({
                latitude: null,
                longitude: null,
                accuracy: null,
                failure: 'Geolocation is not available in your browser.',
            });
        }
    }, []);

    return location;
};
