import { useParams } from "react-router-dom";

//hooks
import { useQuery } from "../../hooks/useQuery.js";
import { InvoiceTable } from "../../components/tables/invoice-table.js";

//table headers
import { InvoiceTableHeaders } from "../../utils/app-settings";

export const OutOfStock = ({ invoiceStage }) => {
	const { query } = useParams();

	const { data } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/ordermodel/itemsoutofstock/${query}`, "get", 20000);

	const headers = invoiceStage?.toLowerCase()?.includes('picking') ? InvoiceTableHeaders[0]?.approvals[2]?.picking : invoiceStage?.toLowerCase()?.includes('stock') ? InvoiceTableHeaders[0]?.approvals[1]?.stock : invoiceStage?.toLowerCase()?.includes('loading') ? InvoiceTableHeaders[0]?.approvals[3]?.loading : invoiceStage?.toLowerCase()?.includes('delivery') ? InvoiceTableHeaders[0]?.approvals[4]?.delivery : invoiceStage?.toLowerCase()?.includes('delivered') ? InvoiceTableHeaders[0]?.approvals[5]?.delivered : InvoiceTableHeaders[0]?.approvals[0].defaultHeaders;

	const responsiveSizing = invoiceStage?.toLowerCase()?.includes('picking') ? [0, 2, 3, 5, 6,] : invoiceStage?.toLowerCase()?.includes('stock') ? [0, 2, 4, 5] : [0, 3, 4, 5];

	const outOfStock = data?.map((property) => {
		const exclsell = property.exclsell && parseFloat(property.exclsell).toLocaleString(undefined, {
			minimumFractionDigits: 2,
		});

		const incsell = property.incsell && parseFloat(property.incsell).toLocaleString(undefined, {
			minimumFractionDigits: 2,
		});

		if (invoiceStage?.toLowerCase()?.includes('picking')) {
			return {
				order_number: property.uid,
				product: property.name?.length >= 20 ? `${property.name?.slice(0, 20)}...` : property.name,
				quantity: property.quantity_ordered,
				appproved_quantity: property.quantity_picked,
				quantity_picked: property.quantity_ordered,
				price_excl: 'R ' + exclsell,
				price_incl: 'R ' + incsell,
				total: 'R ' + parseFloat(property.incsell * property.quantity_ordered).toLocaleString(undefined, {
					minimumFractionDigits: 2,
				})
			};
		} else if (invoiceStage?.toLowerCase()?.includes('stock')) {
			return {
				order_number: property.uid,
				product: property.name?.length >= 20 ? `${property.name?.slice(0, 20)}...` : property.name,
				quantity: property.quantity_ordered,
				quantity_picked: property.quantity_stock_provisioned,
				price_excl: 'R ' + exclsell,
				price_incl: 'R ' + incsell,
				total: 'R ' + parseFloat(property.incsell * property.quantity_stock_provisioned).toLocaleString(undefined, {
					minimumFractionDigits: 2,
				})
			};
		}
		else if (invoiceStage?.toLowerCase()?.includes('loading')) {
			return {
				order_number: property.uid,
				product: property.name?.length >= 20 ? `${property.name?.slice(0, 20)}...` : property.name,
				quantity_picked: (
					<div className="flex items-center justify-center w-full gap-2">
						<p className="text-xs">{property.quantity_picked}</p>
					</div>
				),
				price_excl: 'R ' + exclsell,
				price_incl: 'R ' + incsell,
				total: 'R ' + parseFloat(property.incsell * property.quantity_ordered).toLocaleString(undefined, {
					minimumFractionDigits: 2,
				})
			};
		}
		else if (invoiceStage?.toLowerCase()?.includes('delivered')) {
			return {
				order_number: property.uid,
				product: property.name?.length >= 20 ? `${property.name?.slice(0, 20)}...` : property.name,
				quantity_picked: property.quantity_delivered,
				price_excl: 'R ' + exclsell,
				price_incl: 'R ' + incsell,
				total: 'R ' + parseFloat(property.incsell * property.quantity_ordered).toLocaleString(undefined, {
					minimumFractionDigits: 2,
				})
			};
		}
		else if (invoiceStage?.toLowerCase()?.includes('delivery')) {
			return {
				order_number: property.uid,
				product: property.name?.length >= 20 ? `${property.name?.slice(0, 20)}...` : property.name,
				quantity: property.quantity_picked,
				quantity_picked: property.quantity_delivered,
				price_excl: 'R ' + exclsell,
				price_incl: 'R ' + incsell,
				total: 'R ' + parseFloat(property.incsell * property.quantity_ordered).toLocaleString(undefined, {
					minimumFractionDigits: 2,
				})
			};
		} else {
			return {
				order_number: property.uid,
				product: property.name?.length >= 20 ? `${property.name?.slice(0, 20)}...` : property.name,
				quantity_picked: property.quantity_ordered,
				price_excl: 'R ' + exclsell,
				price_incl: 'R ' + incsell,
				total: 'R ' + parseFloat(property.incsell * property.quantity_ordered).toLocaleString(undefined, {
					minimumFractionDigits: 2,
				})
			};
		}
	});

	return (
		<div className={`w-full flex flex-col justify-start gap-2`}>
			<p className='font-semibold text-gray-500 text-md'>Out of Stock</p>
			<InvoiceTable headers={headers} data={outOfStock} sizing={responsiveSizing} />
		</div>
	);
};
