    import { InputHandler } from "../../hooks/useInputHandler";

export const QuantityUpdater = ({ product, closeModal, updateQuantity, stage }) => {
    const { inputValues, handleChange } = InputHandler();

    const message = stage?.toLocaleLowerCase()?.includes('stock') ? `${product.quantity_stock_provisioned}` : stage?.toLocaleLowerCase()?.includes('picking') ? `${product.quantity_picked}` : stage?.toLocaleLowerCase()?.includes('delivery') ? `${product.quantity_delivered}` : `${product.quantity_ordered}`

    const field = stage?.toLocaleLowerCase()?.includes('stock') ? 'quantity stock provisioned' : stage?.toLocaleLowerCase()?.includes('picking') ? 'quantity picked' : stage?.toLocaleLowerCase()?.includes('delivery') ? 'quantity delivered' : 'quantity'

    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-green text-white p-4 rounded-md flex flex-col justify-center gap-4 w-1/5">
                <div className="flex flex-col justify-start gap-4">
                    <p className="font-medium text-xl">
                        You are changing the {field} of {product?.name} from {(String(message) === 'null') ? '0' : message} to:
                    </p>
                    <div className="flex items-center justify-start gap-2">
                        <p className="font-medium">New Quantity:</p>
                        <input
                            type="number"
                            placeholder="20"
                            name="adjustValue"
                            value={inputValues.adjustValue || ""}
                            onChange={handleChange}
                            className="w-20 h-8 placeholder-italic placeholder-text-sm rounded text-sm focus-border-green hover-border-green text-black"
                        />
                    </div>
                </div>
                <div className="flex items-center justify-between">
                    <button
                        className="bg-white text-red hover-bg-red hover-text-white rounded px-4 py-1 lg:ease-in-out duration-300 cursor-pointer w-1/3 text-1.2rem font-medium"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button
                        className="bg-white text-green hover-bg-white hover-text-green rounded px-4 py-1 lg:ease-in-out duration-300 cursor-pointer w-1/3 text-1.2rem font-medium"
                        onClick={() => updateQuantity(product, inputValues)}
                    >
                        Update
                    </button>
                </div>
            </div>
        </div>
    );
};
