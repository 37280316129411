import { useState, useEffect } from "react"
import { isEmpty } from "lodash";
import { useVehicleContext } from "../context/map-context";
import { DatePicker } from "../../../shared/date-picker";
import { AllReps } from "../helpers/getReps";

import storeIcon from '../../../assets/icons/userlocation.svg'

export const TrackSalesRep = () => {
    const [rep, setRep] = useState()
    const [isOpen, setOpen] = useState(false)
    const [dates, setDates] = useState({
        startDate: '',
        endDate: '',
    })

    useEffect(() => {
        if (dates?.startDate && dates?.endDate && rep) {

            getRepTripHistory(dates, rep)

            closeDropDown()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dates, rep])

    const salesReps = AllReps();
    const { getRepTripHistory } = useVehicleContext();

    if (!salesReps || isEmpty(salesReps)) {
        return;
    }

    const closeDropDown = () => setOpen(false)
    const toggleDropDown = () => setOpen(!isOpen)

    const accessDates = async ({ startDate, endDate }) => setDates({ ...dates, startDate: startDate, endDate: endDate })

    const assignRep = async (rep) => {
        setRep(rep)
        closeDropDown()
    }

    return (
        <div className="flex items-end justify-between w-full gap-4 p-2 rounded shadow bg-grey">
            <div className="relative flex flex-col justify-start w-1/2 gap-2 ">
                <div className="flex flex-col justify-start w-full gap-0">
                    <p className="text-base font-medium text-gray-500" >Select A Rep</p>
                    <span className="p-3 font-medium bg-white rounded shadow cursor-pointer" onClick={toggleDropDown}>{rep ? `${rep?.name} ${rep?.surname}` : 'Select A Rep'}</span>
                </div>
                {
                    isOpen &&
                    <ul className="absolute flex flex-col justify-start w-full h-56 overflow-y-scroll bg-white rounded shadow top-[83px]">
                        {salesReps?.map((rep) =>
                            <li className="flex items-center justify-start w-full gap-2 p-2 text-sm font-medium text-gray-500 bg-white cursor-pointer lg:hover:bg-grey lg:ease-in-out lg:duration-300 first:rounded-t last:rounded-b" key={rep?.uid} onClick={() => assignRep(rep)}>
                                <img src={storeIcon} alt='StoreIcon' loading="lazy" className="w-6" />
                                <span className="text-base">{rep?.name}</span>
                                <span className="text-base">{rep?.surname}</span>
                            </li>
                        )}
                    </ul>
                }
            </div>
            <div className="flex flex-col justify-start w-1/2 gap-0 ">
                <DatePicker accessDates={accessDates} />
            </div>
        </div >
    )
}
