import React from "react";
import axios from "axios";

const extractTokenFromSession = () => {
  const session = JSON.parse(sessionStorage.getItem('session'));
  const token = session?.token || null;
  return token;
};


const api3 = axios.create({
  baseURL: `${process.env.REACT_APP_ENDPOINT}/api/v1/deliverynote/deliveryitems/`,
  mode: "no-cors",
  headers: { token: extractTokenFromSession() },
});

const api10 = axios.create({
  baseURL: `${process.env.REACT_APP_ENDPOINT}/api/v1/orderstage/draftsearch`,
  mode: "no-cors",
  headers: { token: extractTokenFromSession() },
});

class DeliveryNoteTable extends React.Component {

  state = {
    Clients: [],
    UserTypes: [],
    SearchBoxValue: "",
  };

  constructor(props) {


    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    const query = this.props.query;

    api3
      .get(`/${query}`)
      .then((res) => {
        ;
        this.setState({ Clients: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange(event) {
    alert(this.state.Clients);
  }

  handleSearch(event) {
    this.setState({ SearchBoxValue: event.target.value });

    if (this.state.SearchBoxValue === "") {
      api3.get("/" + this.state.SearchBoxValue).then((res) => {
        this.setState({ Clients: res.data });
      });
    } else {
      api10.get("/" + this.state.SearchBoxValue).then((res) => {
        this.setState({ Clients: res.data });
      });
    }
  }

  render() {
    return (
      <div>
        <table className="w-full">
          <thead>
            <tr className="border-b-[2px] border-black w-full">
              <th style={{ width: "15%", fontFamily: 'Inter, sans-serif', fontWeight: 'bolder', fontSize: '9px', lineHeight: '15px', textAlign: 'left' }}>
                Barcode
              </th>
              <th style={{ width: "40%", fontFamily: 'Inter, sans-serif', fontWeight: 'bolder', fontSize: '9px', lineHeight: '15px', textAlign: 'left' }}>
                Description
              </th>
              <th style={{ width: "10%", fontFamily: 'Inter, sans-serif', fontWeight: 'bolder', fontSize: '9px', lineHeight: '10px', textAlign: 'left' }}>
                Pack Size
              </th>
              <th style={{ width: "10%", fontFamily: 'Inter, sans-serif', fontWeight: 'bolder', fontSize: '9px', lineHeight: '10px', textAlign: 'left' }}>
                QTY Loaded
              </th>
              <th style={{ width: "10%", fontFamily: 'Inter, sans-serif', fontWeight: 'bolder', fontSize: '9px', lineHeight: '15px', textAlign: 'left' }}>
                Delivered
              </th>
            </tr>
          </thead>

          {this.state.Clients.map((item, index) => {
            return (
              <tr className={`border-b-2 border-t-2 border-grey h-[2px] w-full p-2 ${index > 0 && index % 38 === 0 ? 'break-after-page' : ''}`}>
                <td>
                  <div style={{
                    maxHeight: '23px',
                    overflow: 'hidden',
                    fontSize: '9px',
                    whiteSpace: 'nowrap',
                    tableLayout: 'fixed',
                    fontWeight: '500'
                  }}
                    name={item.uid}
                  >
                    {item.uid}
                  </div>

                </td>

                <td>

                  <div
                    style={{
                      maxHeight: '23px',
                      overflow: 'hidden',
                      fontSize: '9px',
                      whiteSpace: 'nowrap',
                      tableLayout: 'fixed',
                      fontWeight: '500',
                    }}
                    name={item.uid}
                  >
                    {item.name}
                  </div>

                </td>

                <td>

                  <div
                    style={{
                      maxHeight: '23px',
                      overflow: 'hidden',
                      fontSize: '9px',
                      whiteSpace: 'nowrap',
                      tableLayout: 'fixed',
                      fontWeight: '500'
                    }}
                    name={item.uid}
                  >
                    {item.packsize}
                  </div>

                </td>

                <td>

                  <div
                    style={{
                      maxHeight: '23px',
                      overflow: 'hidden',
                      fontSize: '9px',
                      whiteSpace: 'nowrap',
                      tableLayout: 'fixed',
                      fontWeight: '500'
                    }}
                    name={item.uid}
                  >
                    {item.quantity_picked}
                  </div>

                </td>

                <td>

                  <div
                    style={{
                      maxHeight: '23px',
                      overflow: 'hidden',
                      fontSize: '9px',
                      whiteSpace: 'nowrap',
                      tableLayout: 'fixed',
                      fontWeight: '500'
                    }}
                    name={item.uid}

                  >
                    <div style={{
                      display: 'inline - block',
                      width: '28px',
                      height: '12px',
                      backgroundColor: 'white',
                      border: '1px solid black'
                    }} />

                  </div>

                </td>
              </tr>
            );
          })}
        </table>
      </div >
    );
  }
}

export default DeliveryNoteTable;
