import { useContext, useState } from "react"
import { Link, useLocation } from "react-router-dom"

//context
import { SessionContext } from "../../context/session/sessionContext"

//images
import order from '../../assets/icons/orders.svg'
import gorder from '../../assets/icons/gorders.svg'
import approvals from '../../assets/icons/approvals.svg'
import gapprovals from '../../assets/icons/gapprovals.svg'
import map from '../../assets/icons/map.svg'
import gmap from '../../assets/icons/gmap.svg'
import analyticsIcon from '../../assets/icons/analyticsIcon.svg'
import ganalytics from '../../assets/icons/ganalytics.svg'
import clients from '../../assets/icons/clients.svg'
import gclients from '../../assets/icons/gclients.svg'
import logoutIcon from '../../assets/icons/logout.svg'
import reports from '../../assets/icons/reports.svg'
import greports from '../../assets/icons/greports.svg'
import users from '../../assets/icons/users.svg'
import gusers from '../../assets/icons/gusers.svg'
import vehicles from '../../assets/icons/vehicles.svg'
import gvehicles from '../../assets/icons/gvehicles.svg'
import survey from '../../assets/icons/survey.svg'
import gsurvey from '../../assets/icons/gsurvey.svg'
import iot from '../../assets/icons/iot.svg'
import giot from '../../assets/icons/giot.svg'

export const Navigation = () => {
    const [state, setState] = useState({ isOpen: '' })
    const location = useLocation();
    const path = location.pathname

    const sessionContext = useContext(SessionContext)
    const { logout } = sessionContext;

    const user = JSON.parse(sessionStorage.getItem('session'));

    const toggleModal = () => setState({ ...state, isOpen: !state.isOpen })

    return (
        <nav className='hidden lg:flex lg:w-[4%] h-full bg-white rounded flex-col justify-between z-40 relative py-2'>
            <ul className="flex flex-col justify-start w-full gap-3">
                <li>
                    <Link to='/' className={`flex items-center justify-center gap-2 grlg:ease-in-out duration-300 rounded-lg w-11/12 mx-auto group relative`}>
                        <img src={path === '/' || path === '/cart' ? order : gorder} alt='Orders' loading="lazy" className="duration-300 cursor-pointer w-9 lg:ease-in-out" />
                    </Link>
                </li>
                {
                    (user?.type?.toLowerCase()?.includes('super admin') || user?.type?.toLowerCase()?.includes('dc user')) &&
                    <>
                        <li>
                            <Link to='/approvals' className={`flex items-center justify-center gap-2 grlg:ease-in-out duration-300 rounded-lg w-11/12 mx-auto group relative`}>
                                <img src={path === '/approvals' ? approvals : gapprovals} alt='approvals' loading="lazy" className="duration-300 cursor-pointer w-9 lg:ease-in-out" />
                            </Link>
                        </li>
                        <li>
                            <Link to='/map' className={`flex items-center justify-center gap-2 grlg:ease-in-out duration-300 rounded-lg w-11/12 mx-auto group relative`}>
                                <img src={path === '/map' ? map : gmap} alt='map' loading="lazy" className="duration-300 cursor-pointer w-9 lg:ease-in-out" />
                            </Link>
                        </li>
                        <li>
                            <Link to='/analytics' className={`flex items-center justify-center gap-2 grlg:ease-in-out duration-300 rounded-lg w-11/12 mx-auto group relative`}>
                                <img src={path === '/analytics' ? analyticsIcon : ganalytics} alt='analytics' loading="lazy" className="duration-300 cursor-pointer w-9 lg:ease-in-out" />
                            </Link>
                        </li>
                        <li>
                            <Link to='/reports' className={`flex items-center justify-center gap-2 grlg:ease-in-out duration-300 rounded-lg w-11/12 mx-auto group relative`}>
                                <img src={path === '/reports' ? reports : greports} alt='eports' loading="lazy" className="duration-300 cursor-pointer w-9 lg:ease-in-out" />
                            </Link>
                        </li>
                        <li>
                            <Link to='/clients' className={`flex items-center justify-center gap-2 grlg:ease-in-out duration-300 rounded-lg w-11/12 mx-auto group relative`}>
                                <img src={path === '/clients' || path.includes('/edit-client') ? clients : gclients} alt='clients' loading="lazy" className="duration-300 cursor-pointer w-9 lg:ease-in-out" />
                            </Link>
                        </li>
                        <li>
                            <Link to='/users' className={`flex items-center justify-center gap-2 grlg:ease-in-out duration-300 rounded-lg w-11/12 mx-auto group relative`}>
                                <img src={path === '/users' || path.includes('/edit-user') ? users : gusers} alt='users' loading="lazy" className="duration-300 cursor-pointer w-9 lg:ease-in-out" />
                            </Link>
                        </li>
                        <li>
                            <Link to='/vehicles' className={`flex items-center justify-center gap-2 grlg:ease-in-out duration-300 rounded-lg w-11/12 mx-auto group relative`}>
                                <img src={path === '/vehicles' || path.includes('/edit-vehicle') ? vehicles : gvehicles} alt='vehicles' loading="lazy" className="duration-300 cursor-pointer w-9 lg:ease-in-out" />
                            </Link>
                        </li>
                        {/* <li>
                            <Link to='/survey' className={`flex items-center justify-center gap-2 grlg:ease-in-out duration-300 rounded-lg w-11/12 mx-auto group relative`}>
                                <img src={path === '/how-to' || path.includes('/survey') ? survey : gsurvey} alt='vehicles' loading="lazy" className="duration-300 cursor-pointer w-9 lg:ease-in-out" />
                            </Link>
                        </li> */}
                        {/* <li>
                            <Link to='/iot' className={`flex items-center justify-center gap-2 grlg:ease-in-out duration-300 rounded-lg w-11/12 mx-auto group relative`}>
                                <img src={path === '/iot' ? iot : giot} alt='approvals' loading="lazy" className="duration-300 cursor-pointer w-7 lg:ease-in-out" />
                            </Link>
                        </li> */}
                    </>
                }
            </ul>
            <div className="flex items-center justify-center">
                <img src={logoutIcon} alt='logout' loading="lazy" className="w-10 cursor-pointer" onClick={toggleModal} />
            </div>
            {
                state.isOpen &&
                <div className="absolute bottom-4 left-4 bg-red text-white px-4 py-6 w-[300px] shadow-2xl rounded-lg fade_in flex flex-col justify-center gap-4">
                    <p className="font-medium text-center text-sx ">You are about to log out <br />Are you sure?</p>
                    <div className="flex items-center justify-between gap-2">
                        <button className="w-1/2 p-2 text-sm font-medium text-black bg-white rounded-md outline-none cursor-pointer" onClick={toggleModal}>Cancel</button>
                        <button className="w-1/2 p-2 text-sm font-medium text-white rounded-md outline-none cursor-pointer bg-green" onClick={logout}>Yes</button>
                    </div>
                </div>
            }
        </nav>
    )
}
