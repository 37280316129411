export const IOTCard = ({ data }) => {

    return (
        <>
            {
                data?.map((store) =>
                    <div className="flex flex-col justify-center w-full gap-2 p-4 bg-white rounded shadow-md lg:w-[32%] hover:cursor-pointer" key={store?.uid}>
                        <p className="text-4xl font-bold text-gray-500 whitespace-nowrap">{store?.storeName}</p>
                        <hr className="border border-dashed bg-grey-mid" />
                        <div className="flex items-center justify-between ">
                            <div className="flex flex-col justify-start w-1/2 gap-0">
                                <span className="font-medium tracking-tighter text-gray-500">Yesterday Close Time</span>
                                <p className="text-3xl font-bold text-gray-500">{store?.closeTime}</p>
                            </div>
                            <div className="flex flex-col justify-start w-1/2 gap-0">
                                <span className="font-medium tracking-tighter text-gray-500">Today Open Time</span>
                                <p className="text-3xl font-bold text-gray-500">{store?.openTime}</p>
                            </div>
                        </div>
                        <hr className="border border-dashed bg-grey-mid" />
                        <p className="text-2xl font-bold text-gray-500 whitespace-nowrap">Frier Oil Temperature</p>
                        <div>
                            <div className="flex flex-wrap items-start justify-between w-full gap-x-1 gap-y-5 lg:gap-2">
                                {
                                    store?.friers?.map((frier) =>
                                        <div className={`w-[47%] lg:w-[48%] p-3 border-2 rounded ${frier?.temp > 180 ? 'border-red' : 'border-green'} cursor-pointer lg:ease-in-out lg:duration-300`}>
                                            <span className="text-sm leading-none tracking-tighter text-gray-500">Frier {frier?.uid} Temperature</span>
                                            <p className={`text-3xl font-bold leading-none ${frier?.temp > 180 ? 'text-red' : 'text-green'} lg:ease-in-out lg:duration-300`}>{frier?.temp} °C</p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}