//images
import logo from '../../assets/logos/legendLogo.png'

export const Footer = () => {
    const addressLink = 'https://goo.gl/maps/WwV8PBSBnxLBLBUT6'

    return (
        <footer className="bg-grey text-black py-4 md:py-4 w-full flex flex-col justify-center gap-6">
            <div className="w-full md:w-11/12 lg:w-10/12 mx-auto h-full flex items-start justify-between flex-wrap p-2 gap-6 md:gap-2">
                <div className="w-full md:w-[30%] flex flex-col justify-start items-center md:items-start gap-[10px] text-center md:text-left">
                    <figure className='w-56'>
                        <img src={logo} alt='Legend Systems' loading='lazy' className='w-full' />
                    </figure>
                    <p className='text-base text-gray-500 font-medium leading-2 max-w-sm'>Business software that just works. That means more time to focus on nurturing and growing your business.</p>
                </div>
                <div className="w-full md:w-[30%] flex flex-col justify-start items-center md:items-start gap-2 text-center md:text-left">
                    <h3 className='font-semibold text-gray-500 uppercase text-2xl leading-none'>Get In Touch</h3>
                    <ul>
                        <li className='flex items-center justify-start gap-3'>
                            <a href='telto:+27 10 013 2465' className='text-base text-gray-500 font-medium leading-2 max-w-sm hover:text-purple'>+27 10 013 2465</a>
                            <a href='telto:+27 11 425 3616' className='text-base text-gray-500 font-medium leading-2 max-w-sm hover:text-purple'>+27 11 425 3616</a>
                        </li>
                        <li>
                            <a href='mailto:sales@legendsystems.co.za' className='text-base text-gray-500 font-medium leading-2 max-w-sm hover:text-purple'>sales@legendsystems.co.za</a>
                        </li>
                        <li>
                            <a href={addressLink} target='__blank' className='text-sm text-gray-500 font-medium leading-2 max-w-sm hover:text-purple'>C/O, Unit 1 Ganymede Precinct S & J Industrial Estate<br />144 Ganymede Street, Gosforth Rd<br /> Gosforth Park Germiston, 1419</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='w-full text-center'>
                <p className='text-sm text-gray-500 font-medium leading-2'>2023 All rights reserved by <a href='https://www.legendsystems.co.za' target='__blank' className='hover:text-purple'>Legend Systems</a></p>
            </div>
        </footer>
    )
}