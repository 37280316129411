import { useState, useEffect } from "react";
import { toast } from 'react-hot-toast';
import axios from 'axios';

//hooks
import { InputHandler } from "../../../hooks/useInputHandler"
import { useSessionPolling } from "../../../hooks/useSessionPolling"

//images
import loadingIcon from "../../../assets/icons/wloader.svg";

//request configuration
import { config } from '../../../utils/app-settings';

export const Analytics = () => {

    const [state, setState] = useState({
        isLoading: '',
        data: []
    })

    const { inputValues, handleChange } = InputHandler();
    const userID = useSessionPolling("session");

    const extractTokenFromSession = () => {
        const session = JSON.parse(sessionStorage.getItem('session'));
        const token = session?.token || null;
        return token;
    };

    const getTargetLine = axios.create({
        baseURL: `${process.env.REACT_APP_ENDPOINT}/api/v1/settings/targetline/`,
        mode: "no-cors",
        headers: { token: extractTokenFromSession() },
    });


    const fetchData = async () => {
        try {
            getTargetLine.get(`/${userID?.uid}`)
                .then((res) => {
                    setState({ ...state, isLoading: false, data: res.data });
                }
                )

        } catch (error) {
            setState({ ...state, isLoading: false });
            // Handle error
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (userID) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userID]);

    const targetlineChange = async (e) => {
        e.preventDefault()

        setState({ ...state, isLoading: true });

        try {

            const updateTarget = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/settings/targetline/${inputValues?.superTarget || state.data[0]?.SuperTarget}/${inputValues?.expressTarget || state.data[0]?.ExpressTarget}/${inputValues?.butcherTarget || state.data[0]?.ButcherTarget}/${userID?.uid}`, {}, config)
            if (updateTarget?.data?.message === 'Success') {

                setState({ ...state, isLoading: false });

                toast(`Targets updated successfully`, {
                    icon: '👋',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                setTimeout(() => {
                    window.location.reload()
                }, 3100);

            } else {

                setState({ ...state, isLoading: false });

                toast('Failed to updated targets, please try again', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                setTimeout(() => {
                    window.location.reload()
                }, 3100);
            }
        }
        catch (error) {

            setState({ ...state, isLoading: false });

            toast('Error updating targets, try again later', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });

            setTimeout(() => {
                window.location.reload()
            }, 3100);
        }
    }
  
    return (
        <div className="w-full flex flex-col justify-start gap-4">
            <div className='text-lg' >
                <p className="text-gray-500 text-xl font-medium">Category Target</p>
            </div>
            <hr className="border border-grey-darker w-full" />
            <div className="flex flex-col justify-start gap-2 md:w-1/4">
                {
                   
                    state.data?.map((item) => (
                        <>
                            <div className="flex items-center justify-between">
                                <label className="text-base font-medium text-gray-500 w-1/3" >Super Target</label>
                                <input className='text-base border-1 text-center border-purple rounded-md w-16 hover:border-2 focus:border-2' type='number' name="superTarget" placeholder="50" defaultValue={item?.SuperTarget} onChange={handleChange} />
                            </div>
                            <div className="flex items-center justify-between">
                                <label className="text-base font-medium text-gray-500 w-1/3" >Express Target</label>
                                <input className='text-base border-1 text-center border-purple rounded-md w-16 hover:border-2 focus:border-2' type='number' name="expressTarget" placeholder="50" defaultValue={item?.ExpressTarget} onChange={handleChange} />
                            </div>
                            <div className="flex items-center justify-between">
                                <label className="text-base font-medium text-gray-500 w-1/3" >Butcher Target</label>
                                <input className='text-base border-1 text-center border-purple rounded-md w-16 hover:border-2 focus:border-2' type='number' name="butcherTarget" placeholder="50" defaultValue={item?.ButcherTarget} onChange={handleChange} />
                            </div>
                        </>
                    ))
                }
            </div>
            <div className="w-full">
                <button onClick={targetlineChange} className='bg-green text-white px-2 rounded cursor-pointer text-[1.2rem] flex justify-start'>
                    {
                        state.isLoading ?
                            <p className='flex items-center justify-center gap-2'>
                                Saving
                                <img src={loadingIcon} alt='Loading' loading='lazy' className='w-6 animate-spin' />
                            </p>
                            :
                            <p className='flex items-center justify-center gap-2'>
                                Save Changes
                            </p>
                    }
                </button>
            </div>
        </div>
    )
}
