//images
import walert from '../../../assets/icons/walert.svg';
import empty from '../../../assets/icons/empty.svg';

export const Loading = ({ sizing, header }) => {
    return (
        <div className="w-full h-screen relative flex flex-col justify-start items-center gap-2">
            <p className='w-full md:w-3/4 lg:w-1/3 bg-black-skeleton animate-ping animate-pulse p-4 rounded self-start'></p>
            <div className='flex flex-col justify-start gap-2 w-full'>
                <div className="flex items-center justify-between text-center bg-white rounded divide-x shadow w-full p-2">
                    {header?.map((header, index) => (
                        <div
                            key={index}
                            className={`text-center ${sizing?.includes(index) ? 'hidden sm:block' : ''} w-full text-center text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                            {header}
                        </div>
                    ))}
                </div>
                {[...Array(25)].map((_, index) => (
                    <div className="divide-x cursor-pointer hover:bg-gray-50 flex items-center justify-center gap-6 rounded-lg w-full bg-white p-2" key={index}>
                        {[...Array(header?.length)].map((_, index) => (
                            <div className={`w-full text-center ${sizing?.includes(index) ? 'hidden sm:block' : ''}`} key={index}>
                                <p className={`bg-black-skeleton animate-pulse animate-ping rounded p-3 w-full sm:w-3/4 mx-auto`}></p>
                            </div>
                        ))}
                    </div>
                ))}
                <p className='text-gray-500 flex items-end justify-center gap-2'>
                    <span></span>
                    <span>...</span>
                    <span className='h-6 w-6 rounded bg-black-skeleton animate-pulse animate-ping'></span>
                    <span className='h-6 w-6 rounded bg-black-skeleton animate-pulse animate-ping'></span>
                    <span className='h-6 w-6 rounded bg-black-skeleton animate-pulse animate-ping'></span>
                    <span>...</span>
                    <span></span>
                </p>
            </div>
        </div>
    )
}

export const Error = ({ content, sizing, header }) => {
    return (
        <div className="w-full h-screen relative flex flex-col justify-start items-center gap-2">
            <p className='w-full md:w-3/4 lg:w-1/3 bg-black-skeleton animate-ping animate-pulse p-4 rounded self-start'></p>
            <div className='flex flex-col justify-start gap-2 w-full'>
                <div className="flex items-center justify-between text-center bg-white rounded divide-x shadow w-full p-2">
                    {header?.map((header, index) => (
                        <div
                            key={index}
                            className={`text-center ${sizing?.includes(index) ? 'hidden sm:block' : ''} w-full text-center text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                            {header}
                        </div>
                    ))}
                </div>
                {[...Array(25)].map((_, index) => (
                    <div className="divide-x cursor-pointer hover:bg-gray-50 flex items-center justify-center gap-6 rounded-lg w-full bg-white p-2" key={index}>
                        {[...Array(header?.length)].map((_, index) => (
                            <div className={`w-full text-center ${sizing?.includes(index) ? 'hidden sm:block' : ''}`} key={index}>
                                <p className={`bg-black-skeleton animate-pulse animate-ping rounded p-3 w-full sm:w-3/4 mx-auto`}></p>
                            </div>
                        ))}
                    </div>
                ))}
                <p className='text-gray-500 flex items-end justify-center gap-2'>
                    <span></span>
                    <span>...</span>
                    <span className='h-6 w-6 rounded bg-black-skeleton animate-pulse animate-ping'></span>
                    <span className='h-6 w-6 rounded bg-black-skeleton animate-pulse animate-ping'></span>
                    <span className='h-6 w-6 rounded bg-black-skeleton animate-pulse animate-ping'></span>
                    <span>...</span>
                    <span></span>
                </p>
            </div>
            <div className='rounded-lg flex items-center justify-center absolute bg-red text-white p-6 w-11/12 md:6/12 lg:w-6/12 xl:4/12 gap-4 top-1/4'>
                <figure className='flex items-center justify-center'>
                    <img src={walert} alt='Error' loading='lazy' className='object-contain w-20 md:w-32' />
                </figure>
                <p className='text-md font-medium leading-5'>{content}</p>
            </div>
        </div>
    )
}

export const Empty = ({ content, sizing, header }) => {

    return (
        <div className="w-full h-screen relative flex flex-col justify-start items-center gap-2">
            <p className='w-full md:w-3/4 lg:w-1/3 bg-black-skeleton animate-ping animate-pulse p-4 rounded self-start'></p>
            <div className='flex flex-col justify-start gap-2 w-full'>
                <div className="flex items-center justify-between text-center bg-white rounded divide-x shadow w-full p-2">
                    {header?.map((header, index) => (
                        <div
                            key={index}
                            className={`text-center ${sizing?.includes(index) ? 'hidden sm:block' : ''} w-full text-center text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                            {header}
                        </div>
                    ))}
                </div>
                {[...Array(25)].map((_, index) => (
                    <div className="divide-x cursor-pointer hover:bg-gray-50 flex items-center justify-center gap-6 rounded-lg w-full bg-white p-2" key={index}>
                        {[...Array(header?.length)].map((_, index) => (
                            <div className={`w-full text-center ${sizing?.includes(index) ? 'hidden sm:block' : ''}`} key={index}>
                                <p className={`bg-black-skeleton animate-pulse animate-ping rounded p-3 w-full sm:w-3/4 mx-auto`}></p>
                            </div>
                        ))}
                    </div>
                ))}
                <p className='text-gray-500 flex items-end justify-center gap-2'>
                    <span></span>
                    <span>...</span>
                    <span className='h-6 w-6 rounded bg-black-skeleton animate-pulse animate-ping'></span>
                    <span className='h-6 w-6 rounded bg-black-skeleton animate-pulse animate-ping'></span>
                    <span className='h-6 w-6 rounded bg-black-skeleton animate-pulse animate-ping'></span>
                    <span>...</span>
                    <span></span>
                </p>
            </div>
            <div className='rounded-lg flex items-center justify-center absolute bg-green text-white p-6 w-11/12 md:6/12 lg:w-6/12 xl:4/12 gap-4 top-1/4'>
                <figure className='flex items-center justify-center'>
                    <img src={empty} alt='Error' loading='lazy' className='object-contain w-20 md:w-32' />
                </figure>
                <p className='text-md font-medium leading-5'>{content}</p>
            </div>
        </div>
    )
}
