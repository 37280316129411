import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash'

//hooks
import { InputHandler } from '../../hooks/useInputHandler';
import { useQuery } from '../../hooks/useQuery';

//images
import loadingIcon from '../../assets/icons/wloader.svg'

//components
import { Layout } from '../../layout/layout';

//loading states
import { Empty, Error, Loading } from './states';

export const EditVehicle = () => {
    const [state, setState] = useState({
        isLoading: '',
        status: null,
    })
    const navigate = useNavigate()
    const { inputValues, clearValues, handleChange } = InputHandler();

    const { query } = useParams()

    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/vehicle/single/${query}`, "get");

    if (isLoading) {
        return (
            <Loading />
        )
    }

    if (error) {
        return (
            <Error />
        )
    }

    if (isEmpty(data)) {
        return (
            <Empty />
        )
    }

    const goBack = () => navigate(-1);

    const toggleStatus = () => setState((prevState) => ({ ...prevState, status: prevState.status === 0 ? 1 : 0 }));

    const updateVehicle = async (e) => {
        e.preventDefault()

        setState({ ...state, isLoading: true });

        try {
            const payLoad = {
                make: inputValues?.make || data[0]?.make,
                model: inputValues?.model || data[0]?.model,
                license_number: inputValues?.licenseNumber || data[0]?.license_number,
                registration_number: inputValues?.vehRegNum || data[0]?.registration_number,
                vin: inputValues?.vin || data[0]?.vin,
                engine_number: inputValues?.engineNumber || data[0]?.engine_number,
                gvm: inputValues?.gvm || data[0]?.gvm,
                tare: inputValues?.tare || data[0]?.tare,
                aa_rate_fee: inputValues?.AAVehicleRateFee || data[0]?.aa_rate_fee,
                active: state.status !== 'null' ? state.status : data[0]?.active !== null ? data[0]?.active : 0,
                fuel_efficiency: inputValues?.fuelEfficiency || data[0]?.fuel_efficiency,
            };

            const session = JSON.parse(sessionStorage.getItem('session'));

            const config = {
                headers: {
                    'token': session?.token,
                    mode: 'no-cors'
                }
            };

            const createVehicle = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/vehicle/update`, payLoad, config)

            if (createVehicle?.data?.message === 'Vehicle updated Successfully!') {
                setState({ ...state, isLoading: false });

                toast(`Vehicle updated successfully`, {
                    icon: '👋',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                clearValues()
            }
            else {
                setState({ ...state, isLoading: false });

                toast('Failed to update vehicle info, please try again', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });
            }
        }
        catch (error) {
            setState({ ...state, isLoading: false });

            toast('Error updating vehicle info, try again later', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }

    }

    return (
        <Layout>
            {
                data?.map((vehicle) =>
                    <div className='w-full h-[90vh] flex flex-col justify-start gap-4 p-2'>
                        <div className='flex items-center justify-between'>
                            <div className='flex flex-col justify-start gap-0'>
                                <p className="text-purple font-semibold text-2xl lg:text-3xl">
                                    Edit Vehicle
                                </p>
                                <p className="text-lg font-bold text-gray-500">#{`${vehicle.make} - ${vehicle.model} ( ${vehicle.license_number} )`}</p>
                            </div>
                            <div className="flex items-center justify-start lg:justify-end gap-2 flex-wrap w-full lg:w-6/12">
                                <button className="border border-red p-1 md:p-2 w-[30%] md:w-[20%] text-red hover:bg-red hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer" onClick={goBack}>Close</button>
                            </div>
                        </div>
                        <form className='w-full h-full flex flex-col justify-between items-start' onSubmit={updateVehicle}>
                            <div className='flex items-start justify-start flex-wrap gap-2'>
                                <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                                    <label className='text-base font-medium text-gray-500'>UID</label>
                                    <input
                                        defaultValue={vehicle.uid}
                                        type='text'
                                        name='uid'
                                        placeholder='VH23'
                                        className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                                    />
                                </div>
                                <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                                    <label className='text-base font-medium text-gray-500'>Make</label>
                                    <input
                                        value={inputValues.make || data[0]?.make}
                                        onChange={handleChange}
                                        type='text'
                                        name='make'
                                        placeholder='KIA'
                                        className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                                    />
                                </div>
                                <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                                    <label className='text-base font-medium text-gray-500'>Model</label>
                                    <input
                                        value={inputValues.model || data[0]?.model}
                                        onChange={handleChange}
                                        type='text'
                                        name='model'
                                        placeholder='Sorento'
                                        className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                                    />
                                </div>
                                <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                                    <label className='text-base font-medium text-gray-500'>License Number</label>
                                    <input
                                        value={inputValues.licenseNumber || data[0]?.license_number}
                                        onChange={handleChange}
                                        type='text'
                                        name='licenseNumber'
                                        placeholder='GH45JKGP'
                                        className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                                    />
                                </div>
                                <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                                    <label className='text-base font-medium text-gray-500'>Veh. register Number</label>
                                    <input
                                        value={inputValues.vehRegNum || data[0]?.registration_number}
                                        onChange={handleChange}
                                        type='text'
                                        name='vehRegNum'
                                        placeholder='1H41JXMN186'
                                        className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                                    />
                                </div>
                                <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                                    <label className='text-base font-medium text-gray-500'>VIN</label>
                                    <input
                                        value={inputValues.vin || data[0]?.vin}
                                        onChange={handleChange}
                                        type='text'
                                        name='vin' placeholder='1HGBH41JXMN109186'
                                        className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                                    />
                                </div>
                                <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                                    <label className='text-base font-medium text-gray-500'>Engine Number</label>
                                    <input
                                        value={inputValues.engineNumber || data[0]?.engine_number}
                                        onChange={handleChange}
                                        type='text'
                                        name='engineNumber'
                                        placeholder='1HGBH41JXMN109186'
                                        className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                                    />
                                </div>
                                <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                                    <label className='text-base font-medium text-gray-500'>GVM</label>
                                    <input
                                        value={inputValues.gvm || data[0]?.gvm}
                                        onChange={handleChange}
                                        type='number'
                                        name='gvm'
                                        placeholder='3400'
                                        className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                                    />
                                </div>
                                <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                                    <label className='text-base font-medium text-gray-500'>Tare</label>
                                    <input
                                        value={inputValues.tare || data[0]?.tare}
                                        onChange={handleChange}
                                        type='number'
                                        name='tare'
                                        placeholder='2280'
                                        className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                                    />
                                </div>
                                <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                                    <label className='text-base font-medium text-gray-500'>AA Vehicle Rate Fee</label>
                                    <input
                                        value={inputValues.AAVehicleRateFee || data[0]?.aa_rate_fee}
                                        onChange={handleChange}
                                        type='aaratefee'
                                        name='AAVehicleRateFee'
                                        placeholder='5'
                                        className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                                    />
                                </div>
                                <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                                    <label className='text-base font-medium text-gray-500'>Fuel Efficiency <span className='text-sm'>(KM/L as per EOM)</span></label>
                                    <input
                                        value={inputValues.fuelEfficiency || data[0]?.fuel_efficiency}
                                        onChange={handleChange}
                                        type='number'
                                        name='fuelEfficiency'
                                        placeholder='4.5'
                                        className='w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow'
                                    />
                                </div>
                                <div className='flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300'>
                                    <label className='text-base font-medium text-gray-500'>{state.status === 0 ? 'In-Active' : 'Active'}</label>
                                    <label className='relative inline-block w-20 h-9 rounded-full'>
                                        <input type='checkbox' className='peer opacity-0 w-0 h-0' defaultChecked='active' onClick={toggleStatus} />
                                        <span className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-${state.status === 0 ? 'red' : 'green'} rounded-full duration-300 before:content-[''] before:absolute before:w-7 before:h-7 before:bottom-1 before:left-1 before:rounded-full before:bg-white before:duration-300 peer-checked:before:translate-x-11 peer-checked:bg-green`} ></span>
                                    </label>
                                </div>
                                <button type='submit' className='bg-green text-white py-2 px-10 rounded cursor-pointer text-[1.2rem] mt-2'>
                                    {
                                        state.isLoading ?
                                            <p className='flex items-center justify-center gap-2'>
                                                Saving
                                                <img src={loadingIcon} alt='Loading' loading='lazy' className='w-6 animate-spin' />
                                            </p>
                                            :
                                            <p className='flex items-center justify-center gap-2'>
                                                Save Changes
                                            </p>
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                )
            }
        </Layout >
    )
}