import { useState } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from 'lodash'

//images
import logo from '../assets/logos/pnplogo.jpg';

//components
import { Carousel } from "../modules/login/carousel";
import { Footer } from "../modules/login/footer";
import { LoginPage } from "../modules/login/login-page";

//hooks
import { useQuery } from "../hooks/useQuery";

export const Login = () => {
    const [state, setState] = useState({
        isVisible: false
    })

    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/documents/getlogo`, 'get', 10000);

    if (isLoading) { }

    if (error) { }

    if (isEmpty(data)) {
    }


    const handleAction = () => setState({ ...state, isVisible: !state.isVisible })

    return (
        <main className={`w-full relative flex flex-col justify-start`}>
            {state.isVisible && <LoginPage />}
            <header className={`p-2 lg:p-4 absolute w-full top-0 z-50`}>
                <nav className="flex items-center justify-between">
                    <Link to='https://www.legendsystems.co.za'>
                        <figure className="w-36 md:w-56 relative">
                            <img src={`${process.env.REACT_APP_LOGO_ENDPOINT}/${data[0]?.image_name}`} alt='Legend Systems' loading="lazy" className="w-full h-full object-cover" />
                        </figure>
                    </Link>
                    <div className="flex items-center gap-2">
                        <button className='rounded px-10 py-2 uppercase cursor-pointer lg:ease-in-out duration-500 bg-purple text-white hover:shadow-2xl outline-none flex items-center justify-center gap-2 text-sm md:text-base whitespace-nowrap' onClick={handleAction}>{state.isVisible ? 'Explore' : 'Login'}</button>
                    </div>
                </nav>
            </header>
            <section className="bg-grey h-screen overflow-hidden flex flex-col justify-center items-center gap-10">
                <div className="w-11/12 md:w-10/12 h-44 md:h-72 lg:h-1/2 shadow-2xl bg-white rounded flex items-center justify-center lg:ease-in-out duration-500">
                    <Carousel />
                </div>
            </section>
            <Footer />
        </main>
    );
};
