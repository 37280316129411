import { Tabs } from "../components/tabs/Tabs";

//components
import { Layout } from "../layout/layout"
import { CreateUser } from "../modules/users/create-users";
import { ViewUsers } from "../modules/users/view-users";

//tab components
const tabs = [
    {
        label: 'Create User',
        content: <CreateUser />,
    },
    {
        label: 'View All Users',
        content: <ViewUsers />,
    },
];

export const Users = () => {
    return (
        <Layout>
            <div className="p-2">
                <Tabs tabs={tabs} />
            </div>
        </Layout>
    )
}
