//hooks
import { useQuery } from "../../hooks/useQuery";
import { InputHandler } from "../../hooks/useInputHandler";

//components
import Table from "../../components/tables/orders-table";

//configurations 
import { cmsTableHeaders } from '../../utils/app-settings';
import { useNavigate } from "react-router-dom";
import { Loading, Error, Empty } from "../../components/states/orders/states";
import { isEmpty } from "lodash";

export const ViewClients = () => {
    const navigate = useNavigate();

    const headers = cmsTableHeaders[0]?.cms[2]?.clients;
    const sizing = [2, 3, 4, 5, 6];

    const { inputValues, clearValues, handleChange } = InputHandler();

    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/viewclientroutes/all`, 'get');

    if (isLoading) {
        return (
            <Loading sizing={sizing} header={headers} />
        )
    }

    if (error) {
        return (
            <Error content={'We could all users'} sizing={sizing} header={headers} />
        )
    }

    if (isEmpty(data)) {
        return (
            <Empty content={'There are no users as yet'} sizing={sizing} header={headers} />
        )
    }

    const searchResult = data.filter((item) => {
        for (const key in item) {
            if (item[key]?.toString()?.toLowerCase()?.includes(inputValues?.searchTerm?.toLocaleLowerCase())) {
                return true;
            }
        }
        return false;
    })

    const searchedUser = searchResult?.map((property) => {

        return {
            userID: property.uid ? property.uid : 'missing',
            fullName: property.company_name ? property.company_name?.replace("Plus ", "") : 'missing',
            role: property.Client_name ? property.Client_name : 'missing',
            phone: property.contact_number ? property.contact_number : 'missing',
            email: property.email_address ? `${property?.email_address?.slice(0, 15)}...` : 'missing',
            creationDate: property.creation_date?.slice(0, 10) ? property.creation_date?.slice(0, 10) : 'missing',
            lastOrderDate: property.last_order_date?.slice(0, 10) !== '0000-00-00' && property.last_order_date?.slice(0, 10) ? property.last_order_date?.slice(0, 10) : 'missing',
            status: property.active === 1 ? 'Active' : 'In-Active',
        };
    });

    const defaultUsers = data?.map((property) => {

        return {
            userID: property.uid ? property.uid : 'missing',
            fullName: property.company_name ? property.company_name?.replace("Plus ", "") : 'missing',
            role: property.Client_name ? property.Client_name : 'missing',
            phone: property.contact_number ? property.contact_number : 'missing',
            email: property.email_address ? `${property?.email_address?.slice(0, 15)}...` : 'missing',
            creationDate: property.creation_date?.slice(0, 10) ? property.creation_date?.slice(0, 10) : 'missing',
            lastOrderDate: property.last_order_date?.slice(0, 10) !== '0000-00-00' && property.last_order_date?.slice(0, 10) ? property.last_order_date?.slice(0, 10) : 'missing',
            status: property.active === 1 ? 'Active' : 'In-Active',
        };
    });

    const users = !isEmpty(searchedUser) ? searchedUser : defaultUsers

    const handleInvoiceClick = (client) => navigate(`/edit-client/${client?.userID}`)

    return (
        <div className="w-full h-screen flex flex-col justify-start gap-3">
            <div className="w-full flex flex-col-reverse lg:flex-row lg:items-center flex-wrap justify-start gap-2">
                <div className="w-full md:w-3/4 lg:w-1/3 relative flex items-center justify-between lg:ease-in-out duration-300">
                    <input
                        type='text'
                        placeholder="search"
                        name="searchTerm"
                        value={inputValues.searchTerm || ''}
                        onChange={handleChange}
                        className="w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow" />
                    <button className='absolute right-2 bg-red rounded-e text-white text-sm font-medium py-1 md:py-2 px-6 cursor-pointer hover:mr-1' onClick={clearValues}>Clear</button>
                </div>
            </div>
            <div className="w-full h-[82vh] md:h-[82vh] lg:h-[74.5vh] overflow-y-hidden">
                <Table headers={headers} data={users} onInvoiceClick={handleInvoiceClick} sizing={sizing} />
            </div>
        </div>
    )
}
