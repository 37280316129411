import { useState, useEffect } from 'react';

export const useSessionPolling = (key, interval = 500) => {
    const [sessionData, setSessionData] = useState(null);

    useEffect(() => {
        const pollSessionStorage = () => {
            const session = sessionStorage.getItem(key);
            const parsedSession = session ? JSON.parse(session) : null;
            setSessionData(parsedSession);
        };

        pollSessionStorage();

        const intervalId = setInterval(pollSessionStorage, interval);

        return () => {
            clearInterval(intervalId);
        };
    }, [key, interval]);

    return sessionData;
};
