import { useState } from 'react'
import * as turf from '@turf/turf';
import { toast } from 'react-hot-toast';
import { isEmpty } from 'lodash';

//images
import bchev from '../../assets/icons/chevup.svg'
import loader from '../../assets/icons/wloader.svg'

//hooks
import { useQuery } from '../../hooks/useQuery'
import axios from 'axios'

//request config
import { config } from '../../utils/app-settings'
import { DatePicker } from '../../shared/date-picker';

export const AverageDeliveryTime = () => {
    const [state, setState] = useState({
        isOpen: '',
        selectedVehicle: '',
        start_date: '',
        end_date: '',
        totalDistance: '',
        isLoading: false
    })

    const { data } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/vehicle/active/`, 'get');

    const toggleDropDown = () => setState({ ...state, isOpen: !state.isOpen, selectedVehicle: '', start_date: '', end_date: '', totalDistance: '' })

    const accessDates = async ({ startDate, endDate }) => setState({ ...state, start_date: startDate, end_date: endDate })

    const calculateDistances = (points) => {
        let totalDistance = 0;

        for (let i = 0; i < points.length; i++) {
            const from = turf.point([parseFloat(points[i].longitude), parseFloat(points[i].latitude)]);

            for (let j = i + 1; j < points.length; j++) {
                const to = turf.point([parseFloat(points[j].longitude), parseFloat(points[j].latitude)]);
                const distance = turf.distance(from, to, { units: 'kilometers' });

                totalDistance += distance;
            }
        }

        return totalDistance.toFixed(2);
    };

    const filter = async () => {
        const payLoad = {
            selectedVehicle: state.selectedVehicle,
            start_date: state.start_date,
            end_date: state.end_date
        }

        try {
            setState({ ...state, isLoading: true });

            const travelledDistance = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/analytics/gettimespentatstore/${payLoad?.selectedVehicle?.uid}/${payLoad?.start_date}/${payLoad?.end_date}`, config)

            if (travelledDistance?.data && !isEmpty(travelledDistance?.data)) {

                const coordsArray = travelledDistance?.data?.map((property) => {
                    return {
                        longitude: property.longitude,
                        latitude: property.latitude,
                    };
                });

                const distance = calculateDistances(coordsArray);
                setState({ ...state, totalDistance: distance, isLoading: false })

                toast(`Report generated succesfully`, {
                    style: {
                        icon: '👋',
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });
            }
            else {
                setState({ ...state, isLoading: false })

                toast(`There is no history for this vehicle, please try again`, {
                    style: {
                        icon: '❌',
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });
            }

        }
        catch (error) {
            setState({ ...state, isLoading: false })

            toast(`Failed to generate report please try again`, {
                style: {
                    icon: '❌',
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }
    }

    return (
        <div className="w-full h-full flex flex-col justify-start gap-6">
            <div className='flex flex-col md:flex-row justify-start items-end gap-2 md:gap-5 w-full'>
                <div className='w-full md:w-6/12 lg:w-2/12 flex flex-col gap-1 lg:ease-in-out duration-500'>
                    <label className='font-medium text-base text-gray-500'>Select A Vehicle</label>
                    <div className='custom-select border bg-[var(--white)] rounded text-[1rem] shadow relative'>
                        <div className='flex items-center justify-between p-3 cursor-pointer text-sm text-gray-500 font-medium' onClick={toggleDropDown}>
                            {state.selectedVehicle?.license_number || 'Vehicle Reg'}
                            <img src={bchev} alt='Up' loading='lazy' className={`w-5 lg:ease-in-out duration-500 ${state.isOpen && 'rotate-180'}`} />
                        </div>
                        {state.isOpen &&
                            <ul className='options-list z-[2] absolute bg-[var(--white)] w-full shadow-md mt-2 md:mt-3 rounded-md flex flex-col max-h-[50vh] overflow-y-scroll'>
                                {data && data?.map((vehicle, index) => <li className={`p-3 hover:bg-grey lg:ease-in-out duration-300 cursor-pointer text-sm font-medium text-gray-500`} onClick={() => setState({ ...state, selectedVehicle: vehicle, isOpen: false })} key={index}>{vehicle.make} - {vehicle.model} <span className='text-xs'>({vehicle.license_number})</span></li>)}
                            </ul>
                        }
                    </div>
                </div>
                {
                    state.selectedVehicle &&
                    <div className='w-4/12'>
                        <DatePicker accessDates={accessDates} />
                    </div>
                }
                {
                    state.end_date &&
                    <button className='lg:ease-in-out duration-500 bg-purple text-white text-sm  shadow font-medium py-3 px-8 rounded tracking-wide flex items-center justify-center gap-1 md:gap-2' onClick={filter} disabled={state.isLoading}>
                        {state.isLoading ? 'Filtering' : 'Filter'}
                        <img src={loader} alt='' loading='lazy' className={`w-5 animate-spin ${state.isLoading ? 'block' : 'hidden'}`} />
                    </button>
                }
            </div>
            <div className='flex flex-col justify-start gap-4'>
                <div className='w-full flex items-center bg-white p-2 rounded'>
                    <p className='w-1/2 md:w-4/12 text-base text-gray-500 font-medium'>Vehicle</p>
                    <p className='w-1/2 md:w-4/12 text-base text-gray-500 font-medium flex items-center justify-start gap-1 md:gap-1'>Average Time <span className='text-sm font-normal italic hidden md:flex'>(Between Stores)</span></p>
                </div>
                <div className='h-screen lg:h-[58vh] flex flex-col justify-start gap-4 overflow-hidden'>
                    <div className='w-full flex items-center bg-white p-2 rounded'>
                        <p className='w-1/2 md:w-4/12 font-medium text-sm'>{state?.selectedVehicle?.license_number ? `${state?.selectedVehicle?.make} - ${state?.selectedVehicle?.model} (${state?.selectedVehicle?.license_number})` : '--:--'}</p>
                        <p className='w-1/2 md:w-4/12 font-medium text-sm'>--:--</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
