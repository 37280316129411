import { useState } from 'react'
import { Toaster, toast } from 'react-hot-toast';

//images
import eye from '../assets/icons/faEye.svg'
import eyeSlash from '../assets/icons/faEye.svg'
import logo from '../assets/logos/plusLogo.svg'
import loader from '../assets/icons/wloader.svg'

//hooks
import { InputHandler } from '../hooks/useInputHandler';

export const ResetPassword = () => {
    const [state, setState] = useState({
        showPswd: '',
        isLoading: '',
    })

    //add state setter
    const [errors] = useState({});

    const { inputValues, handleChange } = InputHandler();

    const togglePswd = () => setState({ ...state, showPswd: !state.showPswd });

    const changePassword = (e) => {
        e.preventDefault();

        setTimeout(() => {
            toast(`This feature in in currently development!`, {
                icon: '👋',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }, 1000);
    }

    return (
        <div className='w-[100vw] h-screen flex login z-[1] overflow-hidden'>
            <div className='w-full full flex justify-end z-[2] loginbanner bg-cover bg--no-repeat'>
                <div className='w-full h-full hidden md:flex items-center justify-center'>
                </div>
                <form className='w-full md:w-10/12 lg:w-1/2 xl:w-1/2 h-full flex flex-col justify-center items-center gap-6 lg:ease-in-out duration-700 ' onSubmit={changePassword}>
                    <div className='flex items-center justify-start gap-2'>
                        <img src={logo} alt='notification' loading='lazy' className='w-40 cursor-pointer' />
                    </div>
                    <div className='w-11/12 md:w-11/12 lg:w-10/12 mx-auto lg:p-4 rounded-lg flex flex-col gap-4'>
                        <div className='text-center'>
                            <h4 className='font-bold text-3xl lg:text-4xl flex items-center gap-4 w-[100%] justify-center'>
                                Welcome!
                                👋
                            </h4>
                            <p className='text-gray-500 font-medium text-sm'>Please enter your new password below</p>
                        </div>
                        <div className='flex flex-col gap-1'>
                            <label className='text-base lg:text-xl'>Password</label>
                            <div className='flex items-center justify-between relative'>
                                <input
                                    type={state.showPswd ? 'text' : 'password'}
                                    name="password"
                                    value={inputValues.password || ''}
                                    onChange={handleChange}
                                    placeholder='*************'
                                    className={`placeholder:text-[${errors.password ? 'var(--red)' : 'var(--green)'}] border-[1px] lg:ease-in-out duration-300 outline-none rounded-lg py-[14px] px-4 placeholder:${errors.password ? 'normal' : 'italic'} placeholder:text-sm w-[100%]`} />
                                <img src={state.isLoading ? eye : eyeSlash} alt='' loading='lazy' className='w-6 absolute right-3 cursor-pointer' onClick={togglePswd} />
                            </div>
                            {errors.password && <span className="text-base text-[var(--red)]">{errors.password[0]}</span>}
                        </div>
                        <div className='flex flex-col gap-1'>
                            <label className='text-base lg:text-xl'>Confirm Password</label>
                            <div className='flex items-center justify-between relative'>
                                <input
                                    type={state.showPswd ? 'text' : 'password'}
                                    name="password"
                                    value={inputValues.password || ''}
                                    onChange={handleChange}
                                    placeholder='*************'
                                    className={`placeholder:text-[${errors.password ? 'var(--red)' : 'var(--green)'}] border-[1px] lg:ease-in-out duration-300 outline-none rounded-lg py-[14px] px-4 placeholder:${errors.password ? 'normal' : 'italic'} placeholder:text-sm w-[100%]`} />
                                <img src={state.isLoading ? eye : eyeSlash} alt='' loading='lazy' className='w-6 absolute right-3 cursor-pointer' onClick={togglePswd} />
                            </div>
                            {errors.password && <span className="text-base text-[var(--red)]">{errors.password[0]}</span>}
                        </div>
                        <button className='rounded-lg py-3 px-3 text-md lg:text-xl cursor-pointer lg:ease-in-out duration-500 bg-purple text-white hover:shadow-2xl outline-none flex items-center justify-center gap-2' type='submit' disabled={state.isLoading}>
                            Change Password
                            <img src={loader} alt='' loading='lazy' className={`w-6 animate-spin ${state.isLoading ? 'block' : 'hidden'}`} />
                        </button>
                    </div>
                    <Toaster
                        position="bottom-center"
                        reverseOrder={false}
                        toastOptions={{
                            className: 'flex items-start justify-center gap-2 text-center'
                        }}
                    />
                </form>
            </div>
        </div>
    )
}