import { useEffect } from 'react';

export const useTokenFromSession = () => {
    const extractTokenFromSession = () => {
        const session = JSON.parse(sessionStorage.getItem('session'));
        const token = session?.token || null;
        return token;
    };

    useEffect(() => {
        extractTokenFromSession();
    }, []);

    return extractTokenFromSession();
};
