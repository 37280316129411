import React from 'react';
import { InputHandler } from '../../../../hooks/useInputHandler';

export const EditSurveyModal = ({ action, closeModal, updateAction }) => {
    const { inputValues, handleChange } = InputHandler();

    return (
        <div className={`w-full -mt-20 flex items-center justify-center rounded-lg fade-in lg:ease-in-out duration-500`}>
            <div className='max-w-sm w-full bg-white p-4 rounded-md flex flex-col justify-center gap-4'>
                <div className='w-full h-full flex flex-col justify-start gap-4'>
                    <p className='font-medium text-xl text-center'>Edit Survey Action</p>
                    <div className='flex items-center justify-between gap-2'>
                        <p className='font-medium w-1/2'>Survey Action:</p>
                        <input
                            type='text'
                            name='actions'
                            defaultValue={action?.actions}
                            required
                            onChange={handleChange}
                            className='w-1/2 h-8 placeholder:italic placeholder:text-sm placeholder:text rounded text-sm text-black'
                        />
                    </div>
                </div>
                <div className='flex items-center justify-between'>
                    <button
                        className='bg-white text-red border border-red hover:bg-red hover:text-white rounded px-4 py-1 lg:ease-in-out duration-300 cursor-pointer w-1/3 text-[1.2rem] font-medium'
                        onClick={closeModal}
                    >
                        Close
                    </button>
                    <button
                        className='bg-white text-green border border-green hover:bg-green hover:text-white rounded px-4 py-1 lg:ease-in-out duration-300 cursor-pointer w-1/3 text-[1.2rem] font-medium'
                        onClick={() => updateAction(action, inputValues)}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};