import React, { useRef, useState } from 'react';
import { InputHandler } from '../../../../hooks/useInputHandler';
import bchev from '../../../../assets/icons/chevup.svg';

export const EditModal = ({ category, closeModal, updateCategory }) => {
    const [activeContainer, setActiveContainer] = useState(null);
    const { inputValues, handleChange } = InputHandler();
    const [state, setState] = useState({
        colour: null
    })

    const assignColor = (colour) => {
        setState({ ...state, colour: colour })
        closeDropDown()
    }

    const toggleContainer = (index) =>
        setActiveContainer((prevIndex) => (prevIndex === index ? null : index));

    const toggleDropDown = (index) => toggleContainer(index);

    const containerRef = useRef([]);

    const closeDropDown = () => setActiveContainer(null)

    const presetColours = [
        'lavender',
        'green',
        'aqua',
        'yellow',
        'violet',
        'red',
        'purple',
        'pink',
        'orange',
        'lime',
    ];

    return (
        <div className={`w-full -mt-96 flex items-center justify-center rounded-lg fade-in lg:ease-in-out duration-500`}>
            <div className='max-w-sm w-full bg-white p-4 rounded-md flex flex-col justify-center gap-4'>
                <div className='w-full h-full flex flex-col justify-start gap-4'>
                    <p className='font-medium text-xl text-center'>Edit Category</p>
                    <div className='flex items-center justify-between gap-2'>
                        <p className='font-medium w-1/2'>Category Name:</p>
                        <input
                            type='text'
                            name='category'
                            defaultValue={category?.category_name}
                            required
                            onChange={handleChange}
                            className='w-1/2 h-8 placeholder:italic placeholder:text-sm placeholder:text rounded text-sm text-black'
                        />
                    </div>
                    <div className='flex items-center justify-between gap-2'>
                        <p className='font-medium w-1/2'>Colour Code:</p>
                        <div className='flex flex-col justify-start gap-2 relative w-1/2'>
                            <span
                                className='text-sm text-gray-500 font-medium cursor-pointer flex items-center justify-between gap-1 w-full p-2 rounded'
                                onClick={() => toggleDropDown(0)}>
                                {state?.colour?.charAt(0).toUpperCase() + state?.colour?.slice(1) || category?.colour_code?.charAt(0).toUpperCase() + category?.colour_code?.slice(1) || 'Choose Colour'}
                                <img
                                    src={bchev}
                                    alt='Up'
                                    loading='lazy'
                                    className={`w-5 ${activeContainer === 0 && 'rotate-180'} lg:ease-in-out duration-300`}
                                />
                            </span>
                            <ul
                                className={`white z-50 absolute flex flex-col justify-start gap-0 top-10 rounded-lg bg-white w-full mx-auto max-h-56 overflow-y-auto ${activeContainer === 0 ? '' : 'hidden'} fade-in__left`}
                                ref={(ref) => (containerRef.current[0] = ref)}>
                                {presetColours.map((colour, index) => (
                                    <li
                                        className='hover:bg-grey p-2 first:rounded-t-lg last:rounded-b-lg'
                                        key={index}
                                        name='colour'
                                        onClick={() => assignColor(colour)}>
                                        <span className='text-sm text-gray-500 font-medium cursor-pointer'>{colour.charAt(0).toUpperCase() + colour.slice(1)}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-between'>
                    <button
                        className='bg-white text-red border border-red hover:bg-red hover:text-white rounded px-4 py-1 lg:ease-in-out duration-300 cursor-pointer w-1/3 text-[1.2rem] font-medium'
                        onClick={closeModal}
                    >
                        Close
                    </button>
                    <button
                        className='bg-white text-green border border-green hover:bg-green hover:text-white rounded px-4 py-1 lg:ease-in-out duration-300 cursor-pointer w-1/3 text-[1.2rem] font-medium'
                        onClick={() => updateCategory(category, inputValues, state?.colour)}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};