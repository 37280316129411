import { Tabs } from "../components/tabs/Tabs";

//components
import { Layout } from "../layout/layout"
import { CreateClient } from "../modules/clients/create-client";
import { ViewClients } from "../modules/clients/view-cients";

//tab components
const tabs = [
    {
        label: 'Create Client',
        content: <CreateClient />,
    },
    {
        label: 'View All Clients',
        content: <ViewClients />,
    },
];

export const Clients = () => {
    return (
        <Layout>
            <div className="p-2">
                <Tabs tabs={tabs} />
            </div>
        </Layout>
    )
}
