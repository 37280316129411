//charts
import { OrderPerApprovalStatus } from "./charts/general/orders-per-status"
import { OrderPerApprovalStage } from "./charts/general/orders-per-stage"
import { MissingStores } from "./charts/general/missing-stores"

export const GeneralReports = () => {
    return (
        <div className="w-full pb-6 h-[91vh] lg:h-[83vh] overflow-y-scroll pr-2 flex items-start justify-between flex-wrap gap-y-7">
            <OrderPerApprovalStatus />
            <OrderPerApprovalStage />
            <MissingStores />
        </div>
    )
}
