import { useState } from "react"
import { Layout } from "../../layout/layout"
import { SurveyChart } from "./sections/survey-chart"
import { DatePicker } from "../../shared/date-picker"


import wloader from '../../assets/icons/wloader.svg';
import { toast } from "react-hot-toast";


export const DetailedSurvey = () => {
    const [state, setState] = useState({
        isOPen: false,
        isLoading: false,
    })

    const reRunSurvey = () => {
        setState({ ...state, isLoading: true });

        setTimeout(() => {
            setState({ ...state, isLoading: false });

            toast('Survey re-run initiated successfully', {
                icon: '✅',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }, 1500);
    };

    const accessDates = async ({ startDate, endDate }) => setState({ ...state, startDate: startDate, endDate: endDate })

    return (
        <Layout>
            <div className="p-2 flex flex-col justify-around items-center gap-5 h-full w-full relative">
                <div className="w-full lg:w-11/12 flex items-start gap-4">
                    <div className="w-full md:w-9/12 gap-2">
                        <SurveyChart />
                    </div>
                    <div className="w-full md:w-3/12 flex flex-col justify-start gap-6">
                        <DatePicker accessDates={accessDates} />
                        <button className="bg-purple text-white rounded p-2" onClick={reRunSurvey}>
                            {state.isLoading ?
                                <div className="flex items-center justify-center gap-2">
                                    <p>Saving</p>
                                    <img src={wloader} alt='loading' loading="lazy" className="w-5 animate-spin" />
                                </div>
                                :
                                <p>Re-Run Survey</p>
                            }
                        </button>
                    </div>
                </div>
                <div className="w-full lg:w-11/12 flex items-end justify-start gap-2">
                    <div className="shadow p-2 rounded w-full md:w-4/12 bg-white h-20 gap-2">
                        <span className="text-gray-500 text-sm font-medium">Survey Name</span>
                        <p className="text-purple text-lg font-medium">Do you like VS Code ?</p>
                    </div>
                    <div className="shadow p-2 rounded w-full md:w-4/12 bg-white h-20 gap-2">
                        <span className="text-gray-500 text-sm font-medium">Survey Dates</span>
                        <p className="text-purple text-lg font-medium">2023-20-08 to 2023-20-28</p>
                    </div>
                    <div className="shadow p-2 rounded w-full md:w-4/12 bg-white h-20 gap-2">
                        <span className="text-gray-500 text-sm font-medium">Total Entries</span>
                        <p className="text-purple text-lg font-medium">58 Participants</p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
