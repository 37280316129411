import axios from 'axios';
import moment from 'moment/moment';
import React, {
    createContext,
    useEffect,
    useState
} from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useLocation } from '../../helpers/userLocation';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);
    const [store, setStore] = useState(null);
    const [truck, setTruck] = useState(null);
    const [state, setState] = useState({
        isLoading: false
    })

    const userLocation = useLocation()

    const navigate = useNavigate();

    //token extractor from session storage 
    const extractTokenFromSession = () => {
        const session = JSON.parse(sessionStorage.getItem('session'));
        const token = session?.token || null;
        return token;
    };

    useEffect(() => {
        extractTokenFromSession()
    })

    //api req header configuration
    const config = {
        headers: {
            'token': extractTokenFromSession(),
            mode: 'no-cors'
        }
    };

    useEffect(() => {
        // Retrieve cart items from sessionStorage on component mount
        const storedCartItems = sessionStorage.getItem('cartItems');
        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
        }

        // Retrieve store data from sessionStorage on component mount
        const storedStoreData = sessionStorage.getItem('store');
        if (storedStoreData) {
            setStore(JSON.parse(storedStoreData));
        }
    }, []);

    useEffect(() => {
        // Update sessionStorage only when cartItems are not empty
        if (cartItems.length > 0) {
            sessionStorage.setItem('cartItems', JSON.stringify(cartItems));
        }
    }, [cartItems]);

    useEffect(() => {
        // Update sessionStorage only when store is not null
        if (store !== null) {
            sessionStorage.setItem('store', JSON.stringify(store));
        }
    }, [store]);

    const assignTruck = (truck) => {
        if (truck) {
            setTruck(truck)
        }
    }

    const addToCart = (product) => {
        const newItem = { ...product, quantity: 1 };
        setCartItems((prevItems) => [...prevItems, newItem]);
    };

    const editCartItem = (productID, quantity) => {
        setCartItems((products) =>
            products.map((product) =>
                product.uid === productID ? { ...product, quantity } : product
            )
        );
    };

    const removeFromCart = (productID) => {
        setCartItems((cartItems) =>
            cartItems.filter((product) => product.uid !== productID)
        );
    };

    const incrementItem = (productID) => {
        const updatedCartItems = cartItems.map((product) => {
            if (product.uid === productID) {
                return {
                    ...product,
                    quantity: product.quantity + 1,
                };
            }
            return product;
        });

        setCartItems(updatedCartItems);
    };

    const decrementItem = (productID) => {
        const updatedCartItems = cartItems.map((product) => {
            if (product.uid === productID && product.quantity > 0) {
                return {
                    ...product,
                    quantity: product.quantity - 1,
                };
            }
            return product;
        });

        setCartItems(updatedCartItems);
    };

    const cartTotal = cartItems.reduce((total, product) => total + product.quantity * product.incsell, 0);

    const addStoreToCart = (storeData) => setStore(storeData);

    const updateStoreInCart = (updatedStoreData) => {
        setStore((prevStore) => ({ ...prevStore, ...updatedStoreData }));
    };

    const clearCart = () => {
        setCartItems([]);
        setStore(null);
        sessionStorage.removeItem('cartItems');
        sessionStorage.removeItem('store');
    };

    //cart checkout logic
    //gets current order number
    const getOrderNumber = async (storeUID) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/client/clientnumber/${storeUID}`, config, {});
            const currentOrderNo = Number(response?.data[0]?.order_number_inc);
            return currentOrderNo;
        }
        catch (error) {
            toast('Failed to place order, please refresh and try again.', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }
    };

    //updates the current order number
    const updateOrderNumber = async (storeUID, orderNo) => {
        const response = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/client/setclientnumber/${storeUID}`, { number: orderNo }, config);
        return response?.data?.message === "(Rows matched: 1  Changed: 1  Warnings: 0";
    };

    //creates an order payload
    const createOrder = async (storeUID, orderNo, action) => {

        const userID = JSON.parse(sessionStorage.getItem("session"));

        const payLoad = {
            amount: cartTotal,
            approval_stage_uid: "1",
            client_uid: storeUID,
            creation_date: moment().format('YYYY-MM-DD HH-mm-ss'),
            dc_uid: "1",
            draft: `${action}`,
            number: `${storeUID}00${orderNo}`,
            status_uid: "2",
            uid: `${storeUID}00${orderNo}`,
            user_uid: userID?.uid,
            latitude: `${userLocation?.latitude}`,
            longitude: `${userLocation?.longitude}`,
        };

        console.log(payLoad)

        const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/createorder/create`, payLoad, config);
        return response?.data?.message === "Order has been Created Successfully";
    };

    //lists the ordered items
    const createOrderItems = async (orderUid, items) => {
        const orderItemArray = items.map((property) => ({
            excl_price: property.exclsell,
            inc_price: property.incsell,
            item_uid: property.uid,
            order_uid: orderUid,
            quantity: property.quantity,
        }));

        const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/createorderitem/createbatch`, { orderItemArray }, config);
        return response?.data?.message === "OrderItem has been Created Successfully";
    };

    //final checkout execution
    const checkout = async (action) => {
        setState({ ...state, isLoading: true });

        try {
            if (action === 2) {
                //card payment action will be done here 
            }

            if (userLocation?.latitude !== null && userLocation?.longitude !== null) {

                toast(`${action === 0 ? 'Placing your order' : action === 1 ? 'Saving your draft' : 'Processing payment'}`, {
                    icon: `${action === 0 ? '📦' : action === 1 ? '🗃️' : '💳'}`,
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                const storeID = JSON.parse(sessionStorage.getItem('store'));

                const storeUID = storeID[0]?.uid || storeID?.uid

                const currentOrderNo = await getOrderNumber(storeUID);

                console.log(userLocation?.latitude, userLocation?.longitude);

                if (currentOrderNo) {
                    const orderNo = currentOrderNo + 1;
                    const updatedOrderNo = await updateOrderNumber(storeUID, orderNo);

                    if (updatedOrderNo) {
                        const orderCreated = await createOrder(storeUID, orderNo, action);

                        if (orderCreated) {
                            const orderItemsCreated = await createOrderItems(`${storeUID}00${orderNo}`, cartItems);

                            if (orderItemsCreated) {
                                setState({ ...state, isLoading: false });

                                toast(`Your order has ${action === 1 ? 'successfully saved as a draft' : 'been successfully created/placed'}`, {
                                    icon: '✅',
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                    },
                                    duration: 3000,
                                });

                                clearCart();

                                const user = JSON.parse(sessionStorage.getItem('session'));

                                setTimeout(() => {
                                    sessionStorage.setItem('tabIndex', user?.type?.toLowerCase()?.includes('super admin') || user?.type?.toLowerCase()?.includes('dc user') ? 0 : 2)
                                    navigate(user?.type?.toLowerCase()?.includes('super admin') || user?.type?.toLowerCase()?.includes('dc user') ? '/approvals' : '/');
                                }, 1000);

                            }
                            else {
                                setState({ ...state, isLoading: false });

                                toast('Failed to create order items, , please refresh and try again', {
                                    icon: '❌',
                                    style: {
                                        borderRadius: '10px',
                                        background: '#333',
                                        color: '#fff',
                                    },
                                    duration: 3000,
                                });
                            }
                        }
                        else {
                            setState({ ...state, isLoading: false });

                            toast('Failed to create order, please refresh and try again', {
                                icon: '❌',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });
                        }
                    }
                    else {
                        setState({ ...state, isLoading: false });

                        toast('Failed to update order number, please try again', {
                            icon: '❌',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                            duration: 3000,
                        });
                    }
                }
                else {
                    setState({ ...state, isLoading: false });

                    toast('Failed to get current order number, please refresh and try again', {
                        icon: '❌',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });
                }
            }
            else {
                toast('Please grant location permission to place your order', {
                    icon: '🚨',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 5000,
                });

            }

        } catch (err) {
            setState({ ...state, isLoading: false });

            toast('Failed to place order, please refresh and try again.', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }
    };

    const clearStore = () => {
        setStore(null);
        sessionStorage.removeItem('store');
    };

    const checkoutStatus = state.isLoading

    return (
        <CartContext.Provider
            value={{
                truck,
                store,
                checkout,
                clearCart,
                addToCart,
                cartTotal,
                cartItems,
                clearStore,
                assignTruck,
                editCartItem,
                incrementItem,
                decrementItem,
                removeFromCart,
                checkoutStatus,
                addStoreToCart,
                updateStoreInCart,
            }}>
            {children}
        </CartContext.Provider>
    );
};