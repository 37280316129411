import { useState, useRef, useContext } from "react"
import { isEmpty } from 'lodash'

//hooks
import { useQuery } from "../../../hooks/useQuery"

//cart context provider
import { CartContext } from "../../../context/cart/cartContext"

//images
import bchev from '../../../assets/icons/chevup.svg'
import storeIcon from '../../../assets/icons/clients.svg'
import alert from '../../../assets/icons/alert.svg'
import times from '../../../assets/icons/times.svg'

export const StoresFilter = () => {
    const [activeContainer, setActiveContainer] = useState(null);
    const containerRef = useRef([]);

    const cartContext = useContext(CartContext);
    const { addStoreToCart, store, clearStore } = cartContext;

    const user = JSON.parse(sessionStorage.getItem('session'));

    const { data, isLoading } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/user/getassignedstoresforuser/${user?.uid}`, 'get');

    if (isLoading) {
        return (
            <div className="p-[1.35rem] bg-black-skeleton animate-pulse w-1/2 rounded"></div>
        )
    }

    const addStore = (store) => {
        addStoreToCart(store)
        closeDropDown()
    }

    const toggleContainer = (index) => activeContainer === index ? setActiveContainer(null) : setActiveContainer(index);

    const toggleDropDown = (index) => toggleContainer(index)

    const closeDropDown = () => setActiveContainer(null)

    const clearSelection = () => store?.company_name && clearStore();

    return (
        <div className="relative z-10 flex flex-col justify-start w-1/2 gap-4">
            <span className="flex items-center justify-between w-full p-3 text-sm font-medium text-center text-gray-500 bg-white border rounded shadow cursor-pointer" onClick={() => toggleDropDown(0)}>
                <span className='flex items-center justify-start gap-2'>
                    {store && <img src={storeIcon} alt='Up' loading='lazy' className={`w-5 hidden md:flex`} />}
                    <span className="text-xs font-medium text-left text-gray-500 lg:text-sm">
                        {store?.company_name || (store && store[0]?.client_company_name) || 'Select A Store'}
                    </span>
                </span>
                <img src={store?.company_name ? times : bchev} alt='Up' loading='lazy' className={`w-5 ${activeContainer === 0 && 'rotate-180'} lg:ease-in-out duration-300`} onClick={clearSelection} />
            </span>
            <div className={`absolute bg-white shadow rounded top-14 w-full h-[400px] overflow-y-scroll ${activeContainer === 0 ? '' : 'hidden'} fade-in__left`} ref={(ref) => (containerRef.current[0] = ref)}>
                {
                    isEmpty(data) ?
                        <p className='flex items-center justify-start w-full gap-2 p-2 text-sm font-medium leading-none text-gray-500 cursor-pointer hover:bg-grey lg:p-4 last:rounded-b first:rounded-t'>
                            <img src={alert} alt='Up' loading='lazy' className={`w-8`} />
                            No stores available please refresh
                        </p>
                        :
                        data?.map((store) => (
                            <p className='flex items-center justify-start w-full gap-2 p-2 text-xs font-medium leading-none text-gray-500 cursor-pointer hover:bg-grey lg:p-4 last:rounded-b first:rounded-t lg:text-sm' key={store?.uid} onClick={() => addStore(store)}>
                                <img src={storeIcon} alt='Up' loading='lazy' className={`w-5`} />
                                {`${store.company_name?.replace(/\b\w/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); })}`}
                            </p>
                        ))
                }
            </div>
        </div>
    )
}
