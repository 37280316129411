import dismissIcon from '../../assets/icons/times.svg'
import { SurveyForm } from '../../modules/surveys/survey-form'

import { useQuery } from '../../hooks/useQuery'
import { isEmpty } from 'lodash';

export const SurveyPortal = () => {
    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/survey/getclientsurvey/${1}`, 'get');

    if (isLoading || error || isEmpty(data)) {
        return;
    }

    const { uid, name, start_date, end_date, date_created, disabled, survey_uid, question, pa1, pa2, pa3, pa4, pa5, pa6, ac1, ac2, ac3, ac4, ac5, ac6 } = data[0]

    return (
        <></>
        // <div className="absolute z-50 flex items-center justify-center w-full h-full bg-black-light">
        //     <div className="w-11/12 p-4 bg-white rounded-md md:w-8/12">
        //         <figure className='flex items-center justify-end w-full'>
        //             <img src={dismissIcon} alt='Dismiss' loading="lazy" className="w-10 h-10 cursor-pointer" />
        //         </figure>
        //         <SurveyForm />
        //     </div>
        // </div>
    )
}
