import axios from "axios";
import { isEmpty } from "lodash";
import { toast } from "react-hot-toast";
import jsPDF from "jspdf";

export const pdfResolver = async (invoice) => {
    let encodedPDF = null;

    if (invoice?.uid) {
        const user = JSON.parse(sessionStorage.getItem('session'));

        const config = {
            headers: {
                'token': user?.token,
                mode: 'no-cors'
            }
        };

        try {
            const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/ordermodel/itemsoutofstock/${invoice?.uid}`, config);
            
            if (response?.data && !isEmpty(response?.data)) {
                const pdf = new jsPDF();

                let count = 1
                const concatenatedText = "Thank you for your order!\nApologies, the following items are unfortunately out of stock:\n\n" + response?.data.map(item => (count ++) + ". " + item.name).join("\n");
                pdf.text(concatenatedText, 10, 10);

                const pdfBase64 = pdf.output('dataurl');
                const [, base64Data] = pdfBase64.split(",");
                encodedPDF = base64Data;

                return encodedPDF;
            }
            else {
                toast(`There are no out of stock items for order: ${invoice?.uid}`, {
                    icon: '✅',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    return encodedPDF;
}