import axios from 'axios';
import { isEmpty } from "lodash";
import { toast } from 'react-hot-toast';

//hooks
import { useQuery } from "../../../hooks/useQuery";

//request configuration
import { config } from '../../../utils/app-settings';

import ploadImage from '../../../assets/icons/uploadImage.svg';

export const Promotions = () => {
    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/documents/getbanners`, 'get', 10000);

    if (isLoading) { }

    if (error) { }

    if (isEmpty(data)) {
    }

    const handleFileUpload = async (e) => {
        e.preventDefault();

        console.log("promo page")

        const file = e.target.files[0];
        const fileType = e.target.files[0].type;
        if (file?.size / 1000000 < 3.5) {
            try {
                let currentDate = new Date();
                let year = currentDate.getFullYear();
                let month = String(currentDate.getMonth() + 1).padStart(2, '0');
                let day = String(currentDate.getDate()).padStart(2, '0');
                let formattedDate = `${year}-${month}-${day}`;
                const payload = {
                    uid: Number(e.target.id),
                    image: file,
                    image_name: e.target.name,
                    position: Number(e.target.id),
                    file_type: fileType,
                    date: formattedDate,
                };
                const formData = new FormData();
                formData.append('uid', payload.uid);
                formData.append('image', payload.image);
                formData.append('image_name', payload.image_name);
                formData.append('position', payload.position);
                formData.append('file_type', payload.file_type);
                formData.append('date', payload.date);

                const uploadFile = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/documents/upload`, formData, config);

                if (uploadFile?.data === 'Success') {
                    toast(`Banner uploaded successfully`, {
                        icon: ':wave:',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });
                } else {
                    toast('Failed to upload banner, please try again', {
                        icon: ':x:',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });
                }
            } catch (error) {
                toast('Error uploading banner, try again later', {
                    icon: ':x:',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });
            } finally {
                e.target.value = null;
            }
        } else {
            toast(
                `Image size too large, yours was ${(file?.size / 1000000).toFixed(2)}mb, the max limit is 3.5mb`,
                {
                    duration: 6000,
                }
            );
            e.target.value = null;
        }
    };

    return (
        <div className="flex flex-col justify-start w-full h-full gap-4">
            <div className='text-lg' >
                <p className="text-xl font-medium text-gray-500">Home Banners</p>
            </div>
            <hr className="w-full border border-grey-darker" />
            <div className="flex items-start justify-start w-full md:w-6/12">
                <label className="items-start w-2/12 text-base font-bold text-left">Position</label>
                <div className="w-5/12 md:px-8" >
                    <p className="text-base font-bold text-center md:text-left">File <span className="text-sm font-medium">(image size limit 3.5 mb)</span></p>
                </div>
                <div className="w-5/12 md:px-8" >
                    <p className="text-base font-bold text-center md:text-left">Preview</p>
                </div>
            </div>
            <div className="flex flex-col justify-start w-full gap-3 md:w-6/12">
                <div className="flex items-center justify-between ">
                    <div className="w-2/12">
                        <p className="text-base font-medium text-gray-500">Banner Position 1</p>
                    </div>
                    <div className="flex items-center justify-center w-5/12">
                        <div class="relative max-w-xs bg-white rounded-lg shadow w-10/12">
                            <input type="file" id="1" name="Banner1" class="hidden" onChange={(e) => handleFileUpload(e)} />
                            <label for="1" class="z-20 flex flex-col-reverse items-center justify-center w-full cursor-pointer h-20">
                                <p class="z-10 text-xs font-light text-center text-gray-500">Upload Image</p>
                                <figure className="w-10">
                                    <img src={ploadImage} alt='Upload' loading="lazy" className="w-full" />
                                </figure>
                            </label>
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-5/12">
                        <div class="relative w-10/12 max-w-xs bg-white rounded-lg shadow">
                            <figure className='relative w-full h-20 rounded-md'>
                                <img alt='Banner 1' src={`${process.env.REACT_APP_BANNER_ENDPOINT}/${data[0]?.image_name}`} className='absolute object-cover w-full h-full rounded-md' />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-between ">
                    <div className="w-2/12">
                        <p className="text-base font-medium text-gray-500">Banner Position 2</p>
                    </div>
                    <div className="flex items-center justify-center w-5/12">
                        <div class="relative max-w-xs bg-white rounded-lg shadow w-10/12">
                            <input type="file" id="2" name="Banner2" class="hidden" onChange={(e) => handleFileUpload(e)} />
                            <label for="2" class="z-20 flex flex-col-reverse items-center justify-center w-full cursor-pointer h-20">
                                <p class="z-10 text-xs font-light text-center text-gray-500">Upload Image</p>
                                <figure className="w-10">
                                    <img src={ploadImage} alt='Upload' loading="lazy" className="w-full" />
                                </figure>
                            </label>
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-5/12">
                        <div class="relative w-10/12 max-w-xs bg-white rounded-lg shadow">
                            <figure className='relative w-full h-20 rounded-md'>
                                <img alt='Banner 2' src={`${process.env.REACT_APP_BANNER_ENDPOINT}/${data[1]?.image_name}`} className='absolute object-cover w-full h-full rounded-md' />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-between ">
                    <div className="w-2/12">
                        <p className="text-base font-medium text-gray-500">Banner Position 3</p>
                    </div>
                    <div className="flex items-center justify-center w-5/12">
                        <div class="relative max-w-xs bg-white rounded-lg shadow w-10/12">
                            <input type="file" id="3" name="Banner3" class="hidden" onChange={(e) => handleFileUpload(e)} />
                            <label for="3" class="z-20 flex flex-col-reverse items-center justify-center w-full cursor-pointer h-20">
                                <p class="z-10 text-xs font-light text-center text-gray-500">Upload Image</p>
                                <figure className="w-10">
                                    <img src={ploadImage} alt='Upload' loading="lazy" className="w-full" />
                                </figure>
                            </label>
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-5/12">
                        <div class="relative w-10/12 max-w-xs bg-white rounded-lg shadow">
                            <figure className='relative w-full h-20 rounded-md'>
                                <img alt='Banner 3' src={`${process.env.REACT_APP_BANNER_ENDPOINT}/${data[2]?.image_name}`} className='absolute object-cover w-full h-full rounded-md' />
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-between ">
                    <div className="w-2/12">
                        <p className="text-base font-medium text-gray-500">Banner Position 4</p>
                    </div>
                    <div className="flex items-center justify-center w-5/12">
                        <div class="relative max-w-xs bg-white rounded-lg shadow w-10/12">
                            <input type="file" id="4" name="Banner4" class="hidden" onChange={(e) => handleFileUpload(e)} />
                            <label for="4" class="z-20 flex flex-col-reverse items-center justify-center w-full cursor-pointer h-20">
                                <p class="z-10 text-xs font-light text-center text-gray-500">Upload Image</p>
                                <figure className="w-10">
                                    <img src={ploadImage} alt='Upload' loading="lazy" className="w-full" />
                                </figure>
                            </label>
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-5/12">
                        <div class="relative w-10/12 max-w-xs bg-white rounded-lg shadow">
                            <figure className='relative w-full h-20 rounded-md'>
                                <img alt='Banner 4' src={`${process.env.REACT_APP_BANNER_ENDPOINT}/${data[3]?.image_name}`} className='absolute object-cover w-full h-full rounded-md' />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
