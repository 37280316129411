import React from "react";
import axios from "axios";

const extractTokenFromSession = () => {
  const session = JSON.parse(sessionStorage.getItem('session'));
  const token = session?.token || null;
  return token;
};

const api3 = axios.create({
  baseURL: `${process.env.REACT_APP_ENDPOINT}/api/v1/proformainvoice/proformaitems/`,
  mode: "no-cors",
  headers: { token: extractTokenFromSession() },
});

class ProFormaTable extends React.Component {
  state = {
    Clients: [],
    UserTypes: [],
    SearchBoxValue: "",
  };

  constructor(props) {
    super(props);
    const query = this.props.query;

    api3.get(`/${query}`)
      .then((res) => {
        this.setState({ Clients: res.data });
      })
      .catch((error) => { });
  }

  render() {
    return (
      <div>
        <table className="w-full">
          <thead>
            <tr className="border-b-[2px] border-black w-full">
              <th style={{ width: "15%", fontFamily: 'Inter, sans-serif', fontWeight: 'bolder', fontSize: '9px', lineHeight: '15px', textAlign: 'left' }}>
                Stock Code
              </th>
              <th style={{ width: "40%", fontFamily: 'Inter, sans-serif', fontWeight: 'bolder', fontSize: '9px', lineHeight: '15px', textAlign: 'left' }}>
                Description
              </th>
              <th style={{ width: "10%", fontFamily: 'Inter, sans-serif', fontWeight: 'bolder', fontSize: '9px', lineHeight: '15px', textAlign: 'left' }}>
                Packsize
              </th>
              <th style={{ width: "10%", fontFamily: 'Inter, sans-serif', fontWeight: 'bolder', fontSize: '9px', lineHeight: '15px', textAlign: 'left' }}>
                QTY
              </th>
              <th style={{ width: "12%", fontFamily: 'Inter, sans-serif', fontWeight: 'bolder', fontSize: '9px', lineHeight: '15px', textAlign: 'left' }}>
                Excl. Unit Price
              </th>
              <th style={{ width: "8%", fontFamily: 'Inter, sans-serif', fontWeight: 'bolder', fontSize: '9px', lineHeight: '15px', textAlign: 'left' }}>
                VAT %
              </th>
              <th style={{ width: "10%", fontFamily: 'Inter, sans-serif', fontWeight: 'bolder', fontSize: '9px', lineHeight: '15px', textAlign: 'left' }}>
                Incl. Total
              </th>
            </tr>
          </thead>

          {this.state.Clients.map((item, index) => {
            return (
              <tr className={`border-b-2 border-t-2 border-grey h-[2px] w-full p-2 ${index > 0 && index % 38 === 0 ? 'break-after-page' : ''}`}>

                <td>

                  <div
                    style={{
                      maxHeight: '23px',
                      overflow: 'hidden',
                      fontSize: '9px',
                      whiteSpace: 'nowrap',
                      tableLayout: 'fixed',
                      fontWeight: '500'
                    }}
                    name={item.uid}
                  >
                    {item.fk_composite_item_uid}
                  </div>

                </td>

                <td>

                  <div
                    style={{
                      maxHeight: '23px',
                      overflow: 'hidden',
                      fontSize: '9px',
                      whiteSpace: 'nowrap',
                      tableLayout: 'fixed',
                      fontWeight: '500'
                    }}
                    name={item.uid}
                  >
                    {item.name}
                  </div>

                </td>

                <td>

                  <div
                    style={{
                      maxHeight: '23px',
                      overflow: 'hidden',
                      fontSize: '9px',
                      whiteSpace: 'nowrap',
                      tableLayout: 'fixed',
                      fontWeight: '500'
                    }}
                    name={item.uid}
                  >
                    {item.packsize}
                  </div>

                </td>

                <td>
                  <div
                    style={{
                      maxHeight: '23px',
                      overflow: 'hidden',
                      fontSize: '9px',
                      whiteSpace: 'nowrap',
                      tableLayout: 'fixed',
                      fontWeight: '500'
                    }}
                    name={item.quantity_picked}
                  >
                    {item.quantity_picked}
                  </div>

                </td>

                <td>

                  <div
                    style={{
                      maxHeight: '23px',
                      overflow: 'hidden',
                      fontSize: '9px',
                      whiteSpace: 'nowrap',
                      tableLayout: 'fixed',
                      fontWeight: '500'
                    }}
                    name={item.uid}>
                    R{(item.exclsell).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </div>

                </td>

                <td>

                  <div
                    style={{
                      maxHeight: '23px',
                      overflow: 'hidden',
                      fontSize: '9px',
                      whiteSpace: 'nowrap',
                      tableLayout: 'fixed',
                      fontWeight: '500'
                    }}
                    name={item.uid}
                  >
                    {item.vat}
                  </div>

                </td>

                <td>

                  <div
                    style={{
                      maxHeight: '23px',
                      overflow: 'hidden',
                      fontSize: '9px',
                      whiteSpace: 'nowrap',
                      tableLayout: 'fixed',
                      fontWeight: '500'
                    }}
                    name={item.uid}
                  >
                    R{(item.incsell_total).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                  </div>

                </td>
              </tr>
            );
          })}
        </table>
      </div >
    )
  }
}

export default ProFormaTable;