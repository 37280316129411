import { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';

export const useSync = () => {
    useEffect(() => {
        const syncData = async () => {
            try {
                let session = JSON.parse(sessionStorage.getItem('session'));

                const config = {
                    headers: {
                        'token': session?.token,
                        mode: 'no-cors'
                    }
                };

                const syncData = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/user/getsettingsinfo/${session?.uid}`, config);

                if (syncData?.data[0]) {

                    session.ROS_Min = syncData?.data[0]?.ROS_Min;
                    session.ROS_Max = syncData?.data[0]?.ROS_Max;
                    session.ROS_Average = syncData?.data[0]?.ROS_Average;
                    session.ButcherTarget = syncData?.data[0]?.ButcherTarget;
                    session.ExpressTarget = syncData?.data[0]?.ExpressTarget;
                    session.SuperTarget = syncData?.data[0]?.SuperTarget;

                    sessionStorage.setItem('session', JSON.stringify(session));
                }
            }
            catch (error) {
                toast('Failed to synced all parameters', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });
            }
        };

        syncData();

    }, []);

    return null;
}