import { useParams } from "react-router-dom";

//hooks
import { useQuery } from "../../hooks/useQuery";

export const InvoiceTotals = () => {
    const { query } = useParams();

    const { data } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/ordermodel/itemsvaluestotals/${query}`, 'get')

    const invoiceTotals = data?.map((property) => {
        const totals = parseFloat(property.inxsellTotal).toLocaleString(undefined, {
            minimumFractionDigits: 2,
        });

        const totalsNoVat = parseFloat(property.exclsellTotal).toLocaleString(undefined, {
            minimumFractionDigits: 2,
        });

        const vat = parseFloat(property?.inxsellTotal - property?.exclsellTotal).toLocaleString(undefined, {
            minimumFractionDigits: 2,
        });
        return {
            totals: totals,
            totalsNoVat: totalsNoVat,
            vat: vat,
            deliveryFee: '0.00',
            invoiceTotals: totals
        }
    })

    return (
        <div className="w-full flex items-start justify-end">
            <div className="flex items-start justify-between w-full md:w-6/12 lg:w-5/12">
                <div className="flex flex-col justify-start gap-3 w-1/2 text-left">
                    <div className="flex flex-col justify-start gap-2">
                        <p className="text-sm font-medium text-gray-500">Total Excl VAT:</p>
                        <p className="text-sm font-medium text-gray-500">VAT:</p>
                        <p className="text-sm font-medium text-gray-500">Delivery:</p>
                    </div>
                    <p className="text-black font-semibold text-xl lg:text-3xl">Total</p>
                </div>
                {
                    invoiceTotals?.map((totals, index) =>
                        <div className="w-1/2 flex flex-col justify-start gap-5 items-end overflow-hidden" key={index}>
                            <div className="flex flex-col justify-start gap-2 text-gray-500 font-medium">
                                <p className="w-full lg:w-3/4 leading-none font-semibold">R{totals?.totalsNoVat}</p>
                                <p className="w-full lg:w-3/4 leading-none font-semibold">R{totals?.vat}</p>
                                <p className="w-full lg:w-3/4 leading-none font-semibold">R{totals?.deliveryFee}</p>
                            </div>
                            <div className="w-full lg:w-3/4">
                                <p className="text-right text-2xl font-bold text-gray-500">R{totals?.invoiceTotals}</p>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
