import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

//hooks
import { useQuery } from "../../hooks/useQuery";
import { InputHandler } from "../../hooks/useInputHandler";

//components
import Table from '../../components/tables/orders-table'

//configurations
import { tableHeaders } from '../../utils/app-settings';

//error handling components
import { Empty, Loading, Error } from '../../components/states/orders/states';

export const MyOrders = () => {
    const navigate = useNavigate();

    const headers = tableHeaders[0]?.orders[1]?.myOrders;
    const sizing = tableHeaders[0]?.orders[1]?.sizing;

    const { inputValues, clearValues, handleChange } = InputHandler();

    const user = JSON.parse(sessionStorage.getItem('session'));

    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/order/orderbyuseruid/${user?.uid}`, 'get');

    if (isLoading) {
        return (
            <Loading sizing={sizing} header={headers} />
        )
    }

    if (error) {
        return (
            <Error content={'We could not load your orders'} sizing={sizing} header={headers} />
        )
    }

    if (isEmpty(data)) {
        return (
            <Empty content={'You have no orders'} sizing={sizing} header={headers} />
        )
    }

    const searchResult = data.filter((item) => {
        for (const key in item) {
            if (item[key]?.toString()?.toLowerCase()?.includes(inputValues?.searchTerm?.toLocaleLowerCase())) {
                return true;
            }
        }
        return false;
    });

    const searchInvoices = searchResult?.map((property) => {
        const amountNumber = parseFloat(property.amount);
        const formattedAmount = 'R ' + amountNumber.toLocaleString(undefined, { minimumFractionDigits: 2 });

        return {
            order_number: property.number,
            order_date: property.creation_date.replace(/T.*/, ''),
            client: property.company_name,
            amount: !isNaN(amountNumber) ? formattedAmount : 'R 0.00',
            status: property.status,
            stage: property.stage,
        };
    });

    const defaultOrders = data?.map((property) => {
        const amountNumber = parseFloat(property.amount);
        const formattedAmount = 'R ' + amountNumber.toLocaleString(undefined, { minimumFractionDigits: 2 });

        return {
            order_number: property.number,
            order_date: property.creation_date.replace(/T.*/, ''),
            client: property.company_name,
            amount: !isNaN(amountNumber) ? formattedAmount : 'R 0.00',
            status: property.status,
            stage: property.stage,
        };
    });

    const orders = !isEmpty(searchInvoices) ? searchInvoices : defaultOrders

    const handleInvoiceClick = (invoice) => navigate(`invoice/${invoice?.order_number}`)

    return (
        <div className="w-full h-full flex flex-col justify-start gap-3">
            <div className="w-full flex flex-col-reverse lg:flex-row lg:items-center flex-wrap justify-start gap-2">
                <div className="w-full md:w-3/4 lg:w-1/3 relative flex items-center justify-between lg:ease-in-out duration-300">
                    <input
                        type='text'
                        placeholder="search"
                        name="searchTerm"
                        value={inputValues.searchTerm || ''}
                        onChange={handleChange}
                        className="w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow" />
                    <button className='absolute right-2 bg-red rounded-e text-white text-sm font-medium py-1 md:py-2 px-6 cursor-pointer hover:mr-1' onClick={clearValues}>Clear</button>
                </div>
                <span className="text-base font-medium text-gray-500">My Orders: {orders.length}</span>
            </div>
            <div className="w-full h-[80vh] md:h-[82vh] lg:h-[74.5vh] overflow-y-hidden">
                <Table headers={headers} data={orders} onInvoiceClick={handleInvoiceClick} sizing={sizing} />
            </div>
        </div>
    )
}
