import { useNavigate } from 'react-router';
import { Layout } from '../../layout/layout';

export const Loading = ({ sizing, header }) => {
    const navigate = useNavigate()
    const goBack = () => navigate(-1)
    return (
        <Layout>
            <section className='w-full h-[88vh] flex flex-col justify-start items-start gap-6 overflow-x-hidden relative p-2'>
                <div className='flex items-center justify-between w-full lg:h-[15%] flex-wrap gap-2 lg:gap-2'>
                    <div className='flex flex-col justify-start gap-0'>
                        <p className='text-purple font-semibold text-2xl lg:text-3xl'>Purchase Order</p>
                        <p className='bg-black-skeleton animate-pulse animate-ping rounded px-[20px] py-[15px] w-8/12'></p>
                    </div>
                    <div className='flex items-center justify-start lg:justify-end gap-2 flex-wrap w-full lg:w-6/12'>
                        <button className='border border-red p-1 md:p-2 w-[30%] md:w-[20%] text-red hover:bg-red hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer' >Cancel</button>
                        <button className='border border-purple p-1 md:p-2 w-[30%] md:w-[20%] text-purple hover:bg-purple hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer' >Reorder</button>
                        <button className='border border-green p-1 md:p-2 w-[30%] md:w-[20%] text-green hover:bg-green hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer' onClick={goBack}>Close</button>
                    </div>
                </div>
                <hr className='bg-black-light p-[0.5px] w-full' />
                <div className='flex items-start justify-start w-full flex-wrap gap-6 h-[95%] overflow-y-scroll pr-2 pb-10'>
                    <div className='w-full md:w-5/12 lg:w-1/3 flex flex-col justify-start gap-2'>
                        <p className='text-md font-semibold text-gray-500'>Invoice Details</p>
                        <div className='flex items-center justify-between w-full'>
                            <ul className='w-1/2 flex flex-col justify-start gap-1 items-start'>
                                <li className='text-sm font-medium text-gray-500'>Operation:</li>
                                <li className='text-sm font-medium text-gray-500'>Number:</li>
                                <li className='text-sm font-medium text-gray-500'>Status:</li>
                                <li className='text-sm font-medium text-gray-500'>Stage:</li>
                                <li className='text-sm font-medium text-gray-500'>Loading Number:</li>
                                <li className='text-sm font-medium text-gray-500'>Route:</li>
                                <li className='text-sm font-medium text-gray-500'>Invoice Number:</li>
                                <li className='text-sm font-medium text-gray-500'>Creation Date:</li>
                                <li className='text-sm font-medium text-gray-500'>Delivery Date:</li>
                                <li className='text-sm font-medium text-gray-500'>Amount of Items:</li>
                                <li className='text-sm font-medium text-gray-500'>Discount Percentage:</li>
                                <li className='text-sm font-medium text-gray-500'>Discount Amount:</li>
                            </ul>
                            <ul className='w-1/2 flex flex-col justify-start gap-1 items-start'>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                            </ul>
                        </div>
                    </div>
                    <div className='w-full md:w-5/12 lg:w-1/3 flex flex-col justify-start gap-2'>
                        <p className='text-md font-semibold text-gray-500'>Entity Details</p>
                        <div className='flex items-center justify-between w-full'>
                            <ul className='w-1/2 flex flex-col justify-start gap-1 items-start'>
                                <li className='text-sm font-medium text-gray-500'>UID:</li>
                                <li className='text-sm font-medium text-gray-500'>Created by:</li>
                                <li className='text-sm font-medium text-gray-500'>Address:</li>
                                <li className='text-sm font-medium text-gray-500'>Active:</li>
                                <li className='text-sm font-medium text-gray-500'>Warehouse:</li>
                                <li className='text-sm font-medium text-gray-500'>Pending Orders:</li>
                            </ul>
                            <ul className='w-1/2 flex flex-col justify-start gap-1 items-start'>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                            </ul>
                        </div>
                    </div>
                    <hr className='bg-black-light p-[0.5px] w-full' />
                    <div className='w-full flex flex-col justify-start gap-2'>
                        <p className='text-md font-semibold text-gray-500'>Available Stock</p>
                        {
                            [...Array(5)].map((_, index) => (
                                <div className="bg-white w-full rounded-lg flex items-center justify-between p-2" key={index}>
                                    {[...Array(5)].map((_, innerIndex) => (
                                        <div className="bg-black-skeleton animate-pulse animate-ping p-2 rounded w-2/12" key={innerIndex}></div>
                                    ))}
                                </div>
                            ))
                        }
                    </div>
                    <hr className='bg-black-light p-[0.5px] w-full' />
                    <div className='w-full flex flex-col justify-start gap-2'>
                        <p className='text-md font-semibold text-gray-500'>Available Stock</p>
                        {
                            [...Array(3)].map((_, index) => (
                                <div className="bg-white w-full rounded-lg flex items-center justify-between p-2" key={index}>
                                    {[...Array(5)].map((_, innerIndex) => (
                                        <div className="bg-black-skeleton animate-pulse animate-ping p-2 rounded w-2/12" key={innerIndex}></div>
                                    ))}
                                </div>
                            ))
                        }
                    </div>
                    <hr className='bg-black-light p-[0.5px] w-full' />
                    <div className='w-full flex items-start justify-end'>
                        <div className='flex items-start justify-between w-full md:w-6/12 lg:w-3/12'>
                            <div className='flex flex-col justify-start gap-3 w-1/2 text-left'>
                                <div className='flex flex-col justify-start gap-1'>
                                    <p className='text-sm font-medium text-gray-500'>Total Excl VAT:</p>
                                    <p className='text-sm font-medium text-gray-500'>VAT:</p>
                                    <p className='text-sm font-medium text-gray-500'>Delivery:</p>
                                </div>
                                <p className='text-black font-semibold text-xl lg:text-3xl'>Total</p>
                            </div>
                            <div className='flex flex-col justify-start gap-5 w-1/2 text-right'>
                                <div className='flex flex-col justify-start items-end gap-1'>
                                    <p className=" bg-black-skeleton animate-pulse animate-ping rounded p-2 w-full lg:w-3/4"></p>
                                    <p className=" bg-black-skeleton animate-pulse animate-ping rounded p-2 w-full lg:w-3/4"></p>
                                    <p className=" bg-black-skeleton animate-pulse animate-ping rounded p-2 w-full lg:w-3/4"></p>
                                </div>
                                <p className=" bg-black-skeleton animate-pulse animate-ping rounded p-5 w-full lg:w-3/4 flex self-end"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout >
    )
}

export const Error = ({ content, sizing, header }) => {
    const navigate = useNavigate()
    const goBack = () => navigate(-1)
    return (
        <Layout>
            <section className='w-full h-[88vh] flex flex-col justify-start items-start gap-6 overflow-x-hidden relative p-2'>
                <div className='flex items-center justify-between w-full lg:h-[15%] flex-wrap gap-2 lg:gap-2'>
                    <div className='flex flex-col justify-start gap-0'>
                        <p className='text-purple font-semibold text-2xl lg:text-3xl'>Purchase Order</p>
                        <p className='bg-black-skeleton animate-pulse animate-ping rounded px-[20px] py-[15px] w-8/12'></p>
                    </div>
                    <div className='flex items-center justify-start lg:justify-end gap-2 flex-wrap w-full lg:w-6/12'>
                        <button className='border border-green p-1 md:p-2 w-[30%] md:w-[20%] text-green hover:bg-green hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer' onClick={goBack}>Close</button>
                        <button className='border border-purple p-1 md:p-2 w-[30%] md:w-[20%] text-purple hover:bg-purple hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer' >Reorder</button>
                        <button className='border border-red p-1 md:p-2 w-[30%] md:w-[20%] text-red hover:bg-red hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer' >Cancel</button>
                    </div>
                </div>
                <hr className='bg-black-light p-[0.5px] w-full' />
                <div className='flex items-start justify-start w-full flex-wrap gap-6 h-[95%] overflow-y-scroll pr-2 pb-10'>
                    <div className='w-full md:w-5/12 lg:w-1/3 flex flex-col justify-start gap-2'>
                        <p className='text-md font-semibold text-gray-500'>Invoice Details</p>
                        <div className='flex items-center justify-between w-full'>
                            <ul className='w-1/2 flex flex-col justify-start gap-1 items-start'>
                                <li className='text-sm font-medium text-gray-500'>Operation:</li>
                                <li className='text-sm font-medium text-gray-500'>Number:</li>
                                <li className='text-sm font-medium text-gray-500'>Status:</li>
                                <li className='text-sm font-medium text-gray-500'>Stage:</li>
                                <li className='text-sm font-medium text-gray-500'>Loading Number:</li>
                                <li className='text-sm font-medium text-gray-500'>Route:</li>
                                <li className='text-sm font-medium text-gray-500'>Invoice Number:</li>
                                <li className='text-sm font-medium text-gray-500'>Creation Date:</li>
                                <li className='text-sm font-medium text-gray-500'>Delivery Date:</li>
                                <li className='text-sm font-medium text-gray-500'>Amount of Items:</li>
                                <li className='text-sm font-medium text-gray-500'>Discount Percentage:</li>
                                <li className='text-sm font-medium text-gray-500'>Discount Amount:</li>
                            </ul>
                            <ul className='w-1/2 flex flex-col justify-start gap-1 items-start'>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                            </ul>
                        </div>
                    </div>
                    <div className='w-full md:w-5/12 lg:w-1/3 flex flex-col justify-start gap-2'>
                        <p className='text-md font-semibold text-gray-500'>Entity Details</p>
                        <div className='flex items-center justify-between w-full'>
                            <ul className='w-1/2 flex flex-col justify-start gap-1 items-start'>
                                <li className='text-sm font-medium text-gray-500'>UID:</li>
                                <li className='text-sm font-medium text-gray-500'>Created by:</li>
                                <li className='text-sm font-medium text-gray-500'>Address:</li>
                                <li className='text-sm font-medium text-gray-500'>Active:</li>
                                <li className='text-sm font-medium text-gray-500'>Warehouse:</li>
                                <li className='text-sm font-medium text-gray-500'>Pending Orders:</li>
                            </ul>
                            <ul className='w-1/2 flex flex-col justify-start gap-1 items-start'>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                            </ul>
                        </div>
                    </div>
                    <hr className='bg-black-light p-[0.5px] w-full' />
                    <div className='w-full flex flex-col justify-start gap-2'>
                        <p className='text-md font-semibold text-gray-500'>Available Stock</p>
                        {
                            [...Array(5)].map((_, index) => (
                                <div className="bg-white w-full rounded-lg flex items-center justify-between p-2" key={index}>
                                    {[...Array(5)].map((_, innerIndex) => (
                                        <div className="bg-black-skeleton animate-pulse animate-ping p-2 rounded w-2/12" key={innerIndex}></div>
                                    ))}
                                </div>
                            ))
                        }
                    </div>
                    <hr className='bg-black-light p-[0.5px] w-full' />
                    <div className='w-full flex flex-col justify-start gap-2'>
                        <p className='text-md font-semibold text-gray-500'>Available Stock</p>
                        {
                            [...Array(3)].map((_, index) => (
                                <div className="bg-white w-full rounded-lg flex items-center justify-between p-2" key={index}>
                                    {[...Array(5)].map((_, innerIndex) => (
                                        <div className="bg-black-skeleton animate-pulse animate-ping p-2 rounded w-2/12" key={innerIndex}></div>
                                    ))}
                                </div>
                            ))
                        }
                    </div>
                    <hr className='bg-black-light p-[0.5px] w-full' />
                    <div className='w-full flex items-start justify-end'>
                        <div className='flex items-start justify-between w-full md:w-6/12 lg:w-3/12'>
                            <div className='flex flex-col justify-start gap-3 w-1/2 text-left'>
                                <div className='flex flex-col justify-start gap-1'>
                                    <p className='text-sm font-medium text-gray-500'>Total Excl VAT:</p>
                                    <p className='text-sm font-medium text-gray-500'>VAT:</p>
                                    <p className='text-sm font-medium text-gray-500'>Delivery:</p>
                                </div>
                                <p className='text-black font-semibold text-xl lg:text-3xl'>Total</p>
                            </div>
                            <div className='flex flex-col justify-start gap-5 w-1/2 text-right'>
                                <div className='flex flex-col justify-start items-end gap-1'>
                                    <p className=" bg-black-skeleton animate-pulse animate-ping rounded p-2 w-full lg:w-3/4"></p>
                                    <p className=" bg-black-skeleton animate-pulse animate-ping rounded p-2 w-full lg:w-3/4"></p>
                                    <p className=" bg-black-skeleton animate-pulse animate-ping rounded p-2 w-full lg:w-3/4"></p>
                                </div>
                                <p className=" bg-black-skeleton animate-pulse animate-ping rounded p-5 w-full lg:w-3/4 flex self-end"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout >
    )
}

export const Empty = ({ content, sizing, header }) => {
    const navigate = useNavigate()
    const goBack = () => navigate(-1)

    return (
        <Layout>
            <section className='w-full h-[88vh] flex flex-col justify-start items-start gap-6 overflow-x-hidden relative p-2'>
                <div className='flex items-center justify-between w-full lg:h-[15%] flex-wrap gap-2 lg:gap-2'>
                    <div className='flex flex-col justify-start gap-0'>
                        <p className='text-purple font-semibold text-2xl lg:text-3xl'>Purchase Order</p>
                        <p className='bg-black-skeleton animate-pulse animate-ping rounded px-[20px] py-[15px] w-8/12'></p>
                    </div>
                    <div className='flex items-center justify-start lg:justify-end gap-2 flex-wrap w-full lg:w-6/12'>
                        <button className='border border-green p-1 md:p-2 w-[30%] md:w-[20%] text-green hover:bg-green hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer' onClick={goBack}>Close</button>
                        <button className='border border-purple p-1 md:p-2 w-[30%] md:w-[20%] text-purple hover:bg-purple hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer' >Reorder</button>
                        <button className='border border-red p-1 md:p-2 w-[30%] md:w-[20%] text-red hover:bg-red hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer' >Cancel</button>
                    </div>
                </div>
                <hr className='bg-black-light p-[0.5px] w-full' />
                <div className='flex items-start justify-start w-full flex-wrap gap-6 h-[95%] overflow-y-scroll pr-2 pb-10'>
                    <div className='w-full md:w-5/12 lg:w-1/3 flex flex-col justify-start gap-2'>
                        <p className='text-md font-semibold text-gray-500'>Invoice Details</p>
                        <div className='flex items-center justify-between w-full'>
                            <ul className='w-1/2 flex flex-col justify-start gap-1 items-start'>
                                <li className='text-sm font-medium text-gray-500'>Operation:</li>
                                <li className='text-sm font-medium text-gray-500'>Number:</li>
                                <li className='text-sm font-medium text-gray-500'>Status:</li>
                                <li className='text-sm font-medium text-gray-500'>Stage:</li>
                                <li className='text-sm font-medium text-gray-500'>Loading Number:</li>
                                <li className='text-sm font-medium text-gray-500'>Route:</li>
                                <li className='text-sm font-medium text-gray-500'>Invoice Number:</li>
                                <li className='text-sm font-medium text-gray-500'>Creation Date:</li>
                                <li className='text-sm font-medium text-gray-500'>Delivery Date:</li>
                                <li className='text-sm font-medium text-gray-500'>Amount of Items:</li>
                                <li className='text-sm font-medium text-gray-500'>Discount Percentage:</li>
                                <li className='text-sm font-medium text-gray-500'>Discount Amount:</li>
                            </ul>
                            <ul className='w-1/2 flex flex-col justify-start gap-1 items-start'>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                            </ul>
                        </div>
                    </div>
                    <div className='w-full md:w-5/12 lg:w-1/3 flex flex-col justify-start gap-2'>
                        <p className='text-md font-semibold text-gray-500'>Entity Details</p>
                        <div className='flex items-center justify-between w-full'>
                            <ul className='w-1/2 flex flex-col justify-start gap-1 items-start'>
                                <li className='text-sm font-medium text-gray-500'>UID:</li>
                                <li className='text-sm font-medium text-gray-500'>Created by:</li>
                                <li className='text-sm font-medium text-gray-500'>Address:</li>
                                <li className='text-sm font-medium text-gray-500'>Active:</li>
                                <li className='text-sm font-medium text-gray-500'>Warehouse:</li>
                                <li className='text-sm font-medium text-gray-500'>Pending Orders:</li>
                            </ul>
                            <ul className='w-1/2 flex flex-col justify-start gap-1 items-start'>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                                <li className=" bg-black-skeleton animate-pulse animate-ping rounded p-[10px] w-full lg:w-3/4"></li>
                            </ul>
                        </div>
                    </div>
                    <hr className='bg-black-light p-[0.5px] w-full' />
                    <div className='w-full flex flex-col justify-start gap-2'>
                        <p className='text-md font-semibold text-gray-500'>Available Stock</p>
                        {
                            [...Array(5)].map((_, index) => (
                                <div className="bg-white w-full rounded-lg flex items-center justify-between p-2" key={index}>
                                    {[...Array(5)].map((_, innerIndex) => (
                                        <div className="bg-black-skeleton animate-pulse animate-ping p-2 rounded w-2/12" key={innerIndex}></div>
                                    ))}
                                </div>
                            ))
                        }
                    </div>
                    <hr className='bg-black-light p-[0.5px] w-full' />
                    <div className='w-full flex flex-col justify-start gap-2'>
                        <p className='text-md font-semibold text-gray-500'>Available Stock</p>
                        {
                            [...Array(3)].map((_, index) => (
                                <div className="bg-white w-full rounded-lg flex items-center justify-between p-2" key={index}>
                                    {[...Array(5)].map((_, innerIndex) => (
                                        <div className="bg-black-skeleton animate-pulse animate-ping p-2 rounded w-2/12" key={innerIndex}></div>
                                    ))}
                                </div>
                            ))
                        }
                    </div>
                    <hr className='bg-black-light p-[0.5px] w-full' />
                    <div className='w-full flex items-start justify-end'>
                        <div className='flex items-start justify-between w-full md:w-6/12 lg:w-3/12'>
                            <div className='flex flex-col justify-start gap-3 w-1/2 text-left'>
                                <div className='flex flex-col justify-start gap-1'>
                                    <p className='text-sm font-medium text-gray-500'>Total Excl VAT:</p>
                                    <p className='text-sm font-medium text-gray-500'>VAT:</p>
                                    <p className='text-sm font-medium text-gray-500'>Delivery:</p>
                                </div>
                                <p className='text-black font-semibold text-xl lg:text-3xl'>Total</p>
                            </div>
                            <div className='flex flex-col justify-start gap-5 w-1/2 text-right'>
                                <div className='flex flex-col justify-start items-end gap-1'>
                                    <p className=" bg-black-skeleton animate-pulse animate-ping rounded p-2 w-full lg:w-3/4"></p>
                                    <p className=" bg-black-skeleton animate-pulse animate-ping rounded p-2 w-full lg:w-3/4"></p>
                                    <p className=" bg-black-skeleton animate-pulse animate-ping rounded p-2 w-full lg:w-3/4"></p>
                                </div>
                                <p className=" bg-black-skeleton animate-pulse animate-ping rounded p-5 w-full lg:w-3/4 flex self-end"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout >
    )
}
