import { useState, useEffect } from 'react';

export const Tabs = ({ tabs, startIndex }) => {
    const cachedTab = sessionStorage.getItem("tabIndex");
    const [activeTab, setActiveTab] = useState(cachedTab !== null ? parseInt(cachedTab) : startIndex);

    useEffect(() => {
        const tabValue = Number.isNaN(activeTab) || activeTab === undefined || activeTab === null ? 0 : activeTab;

        sessionStorage.setItem("tabIndex", tabValue.toString());

    }, [activeTab]);

    return (
        <section className='h-screen w-full flex flex-col justify-start gap-3'>
            <div className='w-full hidden lg:flex items-center justify-center md:justify-start gap-2 md:gap-4 flex-wrap'>
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        onClick={() => setActiveTab(index)}
                        className={`whitespace-nowrap w-10 lg:ease-in-out duration-500 shadow bg-${index === activeTab ? 'purple' : 'white'} rounded text-sm p-2 cursor-pointer ${index === activeTab ? 'text-white' : 'text-gray-500'} font-medium hover:text-white hover:bg-purple lg:ease-in-out duration-300 w-44 outline-none`}>
                        {tab.label}
                    </button>
                ))}
            </div>
            <div className='w-full h-full'>
                {tabs.map((tab, index) => (
                    <div key={index} className={`${index === activeTab ? 'flex' : 'hidden'} text-2xl w-full h-full`}>
                        {tab.content}
                    </div>
                ))}
            </div>
        </section>
    );
};
