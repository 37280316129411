import { Route, Routes } from "react-router-dom"

//pages
import { Analytics } from "./pages/analytics"
import { Approvals } from "./pages/approvals"
import { Clients } from "./pages/clients"
import { Login } from "./pages/login"
import { OrderTracking } from "./pages/order-tracking"
import { Orders } from "./pages/orders"
import { Profile } from "./pages/profile"
import { Reports } from "./pages/reports"
import { Users } from "./pages/users"
import { Vehicles } from "./pages/vehicles"
import { Notifications } from "./pages/notifications"
import { Settings } from "./pages/settings"
import { Cart } from "./pages/cart"
import { InvoiceViewer } from "./pages/invoice-viewer"
import { NotFound } from "./pages/not-found"
import { ForgotPassword } from "./pages/forgot-password"
import { ResetPassword } from "./pages/reset-password"
import { NotificationsReader } from "./pages/notifications-reader"
import { EditVehicle } from "./modules/vehicles/edit-vehicle"
import { EditUser } from "./modules/users/edit-users"
import { EditClient } from "./modules/clients/edit-client"

import DeliveryNote from "./modules/approvals/processes/deliveryNote"
import PickingSlip from "./modules/approvals/processes/pickingSlip"
import ProForma from "./modules/approvals/processes/proformaInvoice"

import { SplashScreen } from "./pages/splash-screen"

//sync hook
import { useSync } from "./hooks/useSync"
import { Survey } from "./pages/survey"
import { DetailedSurvey } from "./modules/surveys/detailed-survey"
import { IOT } from "./pages/iot"
import { Map } from "./pages/map"

export const App = () => {
      useSync()

      return (
            <Routes>
                  <Route path='/getting-ready' element={<SplashScreen />} />
                  <Route path='/login' element={<Login />} />
                  <Route path='/forgot-password' element={<ForgotPassword />} />
                  <Route path='/reset-password' element={<ResetPassword />} />

                  <Route path='/' element={<Orders />} />
                  <Route path='/approvals' element={<Approvals />} />

                  <Route path='/map' element={<Map />} />
                  <Route path='/tracking/:query' element={<OrderTracking />} />
                  <Route path='/analytics' element={<Analytics />} />
                  <Route path='/reports' element={<Reports />} />

                  <Route path='/clients' element={<Clients />} />
                  <Route path='/edit-client/:query' element={<EditClient />} />
                  <Route path='/vehicles' element={<Vehicles />} />
                  <Route path='/edit-vehicle/:query' element={<EditVehicle />} />

                  <Route path='/users' element={<Users />} />
                  <Route path='/edit-user/:query' element={<EditUser />} />

                  <Route path='/cart' element={<Cart />} />

                  <Route path='/notifications' element={<Notifications />} />
                  <Route path='/notifications/:uid' element={<NotificationsReader />} />

                  <Route path='/account' element={<Profile />} />
                  <Route path='/settings' element={<Settings />} />
                  <Route path='/faq' element={<Notifications />} />

                  <Route path='/invoice/:query' element={<InvoiceViewer />} />

                  <Route path='/deliverynote/:query' element={<DeliveryNote />} />
                  <Route path='/pickingslip/:query' element={<PickingSlip />} />
                  <Route path='/proformainvoice/:query' element={<ProForma />} />

                  <Route path='/survey' element={<Survey />} />
                  <Route path='/view-survey/:query' element={<DetailedSurvey />} />

                  <Route path='/iot' element={<IOT />} />


                  <Route path='*' element={<NotFound />} />
            </Routes>
      )
}