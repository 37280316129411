import { useRef, useState } from "react";
import axios from "axios";
import { isEmpty } from "lodash";
import { toast } from 'react-hot-toast';

//hooks
import { useQuery } from "../../hooks/useQuery";

//images
import alert from '../../assets/icons/alert.svg'
import bchev from '../../assets/icons/chevup.svg'

export const TruckSelector = ({ invoice, assignedTruck }) => {
    const [activeContainer, setActiveContainer] = useState(null);
    const containerRef = useRef([]);
    const [state, setState] = useState({
        selectedTruck: ''
    })

    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/vehicle/active`, 'get', 5000);

    if (isLoading) {
        return (
            <div className="bg-black-light animate-ping animate-pulse py-4 rounded w-full"></div>
        )
    }

    if (error) {
        return (
            <div className="w-full flex items-center justify-center gap-1">
                <img src={alert} alt='error' loading="lazy" className="w-6" />
                <p className="text-sm font-medium text-red">Error loading trucks</p>
            </div>
        )
    }

    if (isEmpty(data)) {
        return (
            <div className="w-full flex items-center justify-center gap-1">
                <p className="text-sm font-medium">No trucks available</p>
            </div>
        )
    }

    const toggleContainer = (index) => activeContainer === index ? setActiveContainer(null) : setActiveContainer(index);

    const toggleDropDown = (index) => toggleContainer(index)

    const closeDropDown = () => setActiveContainer(null)

    const handleTruck = async (truck) => {
        closeDropDown()
        if (truck) {
            try {
                setState({ ...state, selectedTruck: truck })
                const token = JSON.parse(sessionStorage.getItem('session'))?.token;
                const config = { headers: { 'token': token } };

                const payLoad = {
                    order_uid: invoice?.number,
                    vehicle_uid: truck?.uid
                }

                const assignTruck = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/setOrdervehicle`, payLoad, config)

                if (assignTruck?.data?.message === 'Order Vehcile Updated Successfully') {

                    toast(`Order number ${invoice?.uid} has been asigned to truck ${truck?.license_number}`,
                        {
                            icon: '🚚',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );
                }
            }
            catch (err) {
                toast(`Failed to assign the truck, please try again`,
                    {
                        icon: '❌',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
            }
        }
        else {
            toast('Please select a vehicle',
                {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            );
        }
    }

    const orderAssignedVehicle = data.filter(vehicle => vehicle.uid === assignedTruck);

    return (
        <div className="flex flex-col justify-start gap-2 relative w-full">
            <span className="text-sm text-gray-500 font-medium cursor-pointer flex items-center justify-center gap-1" onClick={() => toggleDropDown(0)}>
                {orderAssignedVehicle[0]?.license_number || state?.selectedTruck?.license_number || 'Pick A Truck'}
                <img src={bchev} alt='Up' loading='lazy' className={`w-5 ${activeContainer === 0 && 'rotate-180'} lg:ease-in-out duration-300`} />
            </span>
            <ul className={`white z-50 absolute flex flex-col justify-start gap-0 top-10 rounded-lg bg-white shadow-base w-full mx-auto ${activeContainer === 0 ? '' : 'hidden'} fade-in__left`} ref={(ref) => (containerRef.current[0] = ref)}>
                {data.map((truck) =>
                    truck.active === 1 &&
                    <li className="hover:bg-grey p-2 first:rounded-t-lg last:rounded-b-lg" key={truck.uid} onClick={() => handleTruck(truck)}>
                        <span className="text-sm text-gray-500 font-medium cursor-pointer">{truck.license_number}</span>
                    </li>
                )}
            </ul>
        </div>
    )
}
