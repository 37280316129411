import { useContext } from 'react'
import { SessionContext } from '../../context/session/sessionContext';

//images
import bgimage from '../../assets/logos/llogo.png'
import userIcon from '../../assets/icons/users.svg'

export const UserDetails = () => {
    const sessionContext = useContext(SessionContext);
    const { session } = sessionContext;

    return (
        <div className="w-[100%] h-[81.5vh] flex flex-col gap-4 rounded overflow-y-scroll md:pr-1">
            <div className="flex flex-col gap-2 w-full h-full">
                <figure className="w-full h-[300px] relative bg-white">
                    <img src={bgimage} alt='banner' className='w-full h-full rounded object-contain bg-center-left shadow-md' />
                </figure>
                <div className="flex flex-col md:flex-row gap-2 lg:gap-4 px-6 lg:px-10">
                    <figure className="w-40 h-40 -mt-24 z-50 bg-white rounded shadow-2xl">
                        <img
                            className="w-full h-full object-contain cursor-pointer rounded"
                            loading="lazy"
                            alt='Legend Systems'
                            src={userIcon}
                        />
                    </figure>
                    <div className="flex flex-col">
                        <p className="font-medium text-xl flex items-center justify-start gap-1">{`${session?.name?.replace(/\b\w/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); })} ${session?.surname?.replace(/\b\w/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); })}`}</p>
                        <p className="text-sm font-medium text-gray-500 flex items-center justify-start gap-1">{session?.type}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}