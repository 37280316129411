//components
import { Layout } from "../layout/layout"

export const Settings = () => {
    return (
        <Layout>
            <div className="p-2">
                <p>Settings Page</p>
            </div>
        </Layout>
    )
}
