import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ProFormaTable from "./proformaInvoiceTable";
import logo from '../../../assets/logos/favicon.png';
import { useReactToPrint } from 'react-to-print';

const extractTokenFromSession = () => {
    const session = JSON.parse(sessionStorage.getItem('session'));
    const token = session?.token || null;
    return token;
};

const api3 = axios.create({
    baseURL: `${process.env.REACT_APP_ENDPOINT}/api/v1/proformainvoice/proformatotals/`,
    mode: "no-cors",
    headers: { token: extractTokenFromSession() },
});

const api4 = axios.create({
    baseURL: `${process.env.REACT_APP_ENDPOINT}/api/v1/deliverynote/deliverynotemetadata/`,
    mode: 'no-cors',
    headers: { 'token': extractTokenFromSession() }
})


function ProForma() {

    const pdfExportComponent = React.useRef(null);
    const [Total, setTotal] = useState(0);
    const [Data, setData] = useState([])

    const { query } = useParams();

    function getTotalsData() {
        api3
            .get(`/${query}`)
            .then((res) => {
                setTotal(res.data[0].incsell_total)
                console.log(res.data)
                console.log(Total)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function getData() {
        api4.get(`/${query}`)
            .then(res => {

                setData(res.data)
                console.log(res.data)
            })
    }

    const handlePrint = useReactToPrint({
        content: () => pdfExportComponent.current,
        documentTitle: 'Picking Slip',
        onAfterPrint: () => console.log('Printed PDF successfully!'),
    });


    useEffect(() => {
        getTotalsData();
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="flex flex-col justify-start gap-4">
            <div className="bg-[#616161] w-full fixed top-0">
                <input type="button" value="Print" style={{
                    width: '75px',
                    padding: '5px',
                    margin: '12px',
                    backgroundColor: '#a17efa',
                    color: '#fff',
                    outline: 'none',
                    border: '1px solid #a17efa',
                    borderRadius: '10px',
                    fontFamily: 'Inter, sans-serif',
                }} onClick={handlePrint}></input>
            </div>
            <div style={{
                fontFamily: 'Inter, sans-serif',
                width: '21cm', minHeight: '29.7cm',
                margin: '1cm auto',
                border: '1px #D3D3D3 solid',
                borderRadius: '5px',
                background: 'white',
                boxShadow: '0 0 5px rgba(255, 255, 255, 0.1)'
            }}>
                <div style={{
                    fontFamily: 'Inter, sans-serif',
                    padding: '2cm'
                }} ref={pdfExportComponent}>

                    <div style={{
                        width: '100vw',
                        maxWidth: '100%',
                        fontFamily: 'Inter, sans-serif',
                        overflow: 'hidden'
                    }}>
                        <div style={{
                            fontSize: '24px',
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: '500'
                        }}>
                            Proforma Invoice
                        </div>

                        <div style={{
                            height: 'auto',
                            width: '100%',
                            display: 'grid',
                            gridTemplateColumns: '550px 300px',
                            gridTemplateRows: '20px 20px',
                            gridTemplateAreas: `'top top '`
                        }}>

                            <div style={{
                                height: '50px',
                                width: '500px',
                                background: 'linear-gradient(to right, #a17efa, transparent)',
                                backgroundColor: '#fff'
                            }} />

                            <div style={{
                                float: 'right', marginTop: '-25px', marginLeft: '-25px',
                                maxWidth: '120px',
                                width: '100%',
                                height: 'auto'
                            }}>
                                <img src={logo} loading='lazy' alt="Logo" style={{ marginBottom: '6px' }} />
                            </div>
                        </div>

                        <br />
                        {Data.map((item) => {
                            return (

                                <div style={{
                                    marginTop: '5px',
                                    height: 'auto',
                                    width: '100%',
                                    display: 'grid',
                                    gridTemplateColumns: '210px 210px 10px 250px',
                                    gridTemplateRows: '20px 20px 20px 20px',
                                    gridTemplateAreas: 'top top top top'
                                }}>
                                    <label style={{ marginTop: '-10px', fontSize: '9px', fontWeight: '500' }}>Company Name: </label>
                                    <label style={{ marginTop: '-10px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <div style={{ height: '120px', marginTop: '-10px', borderLeft: '2px solid #a17efa' }}></div>
                                    <label style={{ marginTop: '-10px', fontSize: '9px', fontWeight: '500' }}>Customer Name: {item.company_name}</label>

                                    <label style={{ marginTop: '-20px', fontSize: '9px', fontWeight: '500' }}>Address Line 1: </label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}>Tel. No: </label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-20px', fontSize: '9px', fontWeight: '500' }}>Acc. No: {item.registration_number}</label>

                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}>Address Line 2:</label>
                                    <label style={{ marginTop: '-40px', fontSize: '9px', fontWeight: '500' }}>Email: {item.company_contact_email_address}</label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}>Address Line 1: {item.business_street_address}</label>

                                    <label style={{ marginTop: '-40px', fontSize: '9px', fontWeight: '500' }}>Address Line 3: </label>
                                    <label style={{ marginTop: '-50px', fontSize: '9px', fontWeight: '500' }}>Invoiced By: </label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-40px', fontSize: '9px', fontWeight: '500' }}>Suburb: {item.business_address_suburb}</label>

                                    <label style={{ marginTop: '-50px', fontSize: '9px', fontWeight: '500' }}>Suburb:</label>
                                    <label style={{ marginTop: '-60px', fontSize: '9px', fontWeight: '500' }}>Invoice No:</label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-50px', fontSize: '9px', fontWeight: '500' }}>City: {item.business_address_city}</label>

                                    <label style={{ marginTop: '-40px', fontSize: '9px', fontWeight: '500' }}>City:</label>
                                    <label style={{ marginTop: '-50px', fontSize: '9px', fontWeight: '500' }}>Invoiced Date:</label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-40px', fontSize: '9px', fontWeight: '500' }}>Tel. {item.company_contact_number}</label>

                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}>VAT No:</label>
                                    <label style={{ marginTop: '-40px', fontSize: '9px', fontWeight: '500' }}>Due Date:</label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}>VAT No: {item.vat_number}</label>

                                    <label style={{ marginTop: '-20px', fontSize: '9px', fontWeight: '500' }}>Reg No:</label>
                                    <label style={{ marginTop: '-20px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-30px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-20px', fontSize: '9px', fontWeight: '500' }}>Reg No: {item.registration_number}</label>

                                    <label style={{ marginTop: '0px', fontSize: '9px', fontWeight: '500' }}>Payment Term:</label>
                                    <label style={{ marginTop: '0px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '0px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '0px', fontSize: '9px', fontWeight: '500' }}>Credit Limit: R{(Number(item.approved_credit_limit))?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</label>

                                    <label style={{ marginTop: '-3px', fontSize: '9px', fontWeight: '500' }}>Settlement Discount: {item.early_settlement_discount}</label>
                                    <label style={{ marginTop: '0px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '0px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-3px', fontSize: '9px', fontWeight: '500' }}>Credit Used: R{(item.approved_credit_limit - item.available_balance)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</label>

                                    <label style={{ marginTop: '-4px', fontSize: '9px', fontWeight: '500' }}>Early Settlement Discount:</label>
                                    <label style={{ marginTop: '0px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '0px', fontSize: '9px', fontWeight: '500' }}></label>
                                    <label style={{ marginTop: '-4px', fontSize: '9px', fontWeight: '500' }}>Available Balance: R{(item.available_balance)?.toLocaleString(undefined, { minimumFractionDigits: 2 })}</label>
                                </div>
                            )
                        })}

                        <hr className="bg-grey-darker h-[2px] my-3" />

                        <ProFormaTable query={query} />

                        <br />

                        <div style={{
                            height: 'auto',
                            width: 'auto',
                            display: 'grid',
                            textAlign: 'end',
                            float: 'right',
                            gridTemplateColumns: '100px 200px',
                            gridTemplateRows: '20px 20px',
                            gridTemplateAreas: `'top top'`
                        }}>
                            <div style={{
                                width: 'auto',
                                height: 'auto',
                                fontSize: '14px'
                            }}>
                                Sub Total:
                            </div>

                            <div style={{
                                width: 'auto',
                                height: 'auto',
                                fontSize: '14px'
                            }}>
                                R {(Total - (Total * 0.15)).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                            </div>

                            <div style={{
                                width: 'auto',
                                height: 'auto',
                                fontSize: '14px'
                            }}>
                                VAT Total:
                            </div>

                            <div style={{
                                width: 'auto',
                                height: 'auto',
                                fontSize: '14px'
                            }}>
                                R {(Total * 0.15).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                            </div>

                            <div style={{
                                width: 'auto',
                                height: 'auto',
                                fontSize: '20px'
                            }}>
                                Total:
                            </div>

                            <div style={{
                                width: 'auto',
                                height: 'auto',
                                fontSize: '20px'
                            }}>
                                R {(Total).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ProForma;