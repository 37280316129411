import React, { useState } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

export const AddressAutocomplete = ({ handleAddress }) => {
    const [state, setState] = useState({
        geoCOORDS: '',
        address: '',
        city: '',
        postalCode: ''
    });

    const handleSelect = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);

        // Find city and postal code in the address components
        let city = '';
        let postalCode = '';
        let suburb = '';
        for (const component of results[0].address_components) {
            if (component.types.includes("locality")) {
                city = component.long_name;
            } else if (component.types.includes("postal_code")) {
                postalCode = component.long_name;
            } else if (component.types.includes("sublocality_level_1") || component.types.includes("sublocality")) {
                suburb = component.long_name;
            }
        }
        setState({ ...state, address: value });

        const addressArray = value.split(", ");
        const [STREET, SUBURB, CITY, COUNTRY] = addressArray;

        const selectedAddress = {
            fullAddress: { STREET, SUBURB, CITY, COUNTRY },
            coords: latLng,
            city,
            postalCode,
            suburb
        };

        handleAddress(selectedAddress);
    };

    return (
        <PlacesAutocomplete
            value={state.address}
            onChange={(value) => setState({ ...state, address: value })}
            onSelect={handleSelect}>

            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                <div>
                    <input
                        {...getInputProps({
                            placeholder: "Enter your addresses",
                            className: "w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow",
                        })}
                    />
                    <div className="bg-red">
                        {suggestions.map((suggestion) => {
                            const style = { backgroundColor: suggestion.active ? "#e3e3e3" : "#ffffff", cursor: "pointer", };

                            return (
                                <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })}>
                                    <p className="text-sm text-gray-500 font-medium">{suggestion.description}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}

        </PlacesAutocomplete>
    );
};
