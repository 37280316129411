import { Layout } from "../../layout/layout"

//images
import walert from '../../assets/icons/walert.svg';
import emptyIcon from '../../assets/icons/empty.svg';
import { useNavigate } from "react-router-dom";

export const Loading = () => {
    const navigate = useNavigate()

    const goBack = () => navigate(-1);

    return (
        <Layout>
            <div className="w-full h-full overflow-y-scroll p-2 flex flex-col justify-start">
                <div className='flex items-center justify-between'>
                    <div className='flex flex-col justify-start gap-0'>
                        <p className="text-purple font-semibold text-2xl lg:text-3xl">
                            Edit Vehicle
                        </p>
                        <p className="text-lg font-bold text-gray-500"></p>
                    </div>
                    <div className="flex items-center justify-start lg:justify-end gap-2 flex-wrap w-full lg:w-6/12">
                        <button className="border border-red p-1 md:p-2 w-[30%] md:w-[20%] text-red hover:bg-red hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer" onClick={goBack}>Close</button>
                    </div>
                </div>
                <form className='w-full h-full flex flex-col justify-between items-start'>
                    <div className='flex items-start justify-start flex-wrap gap-2 w-full'>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>UID</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Make</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Model</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>License Number</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Veh. register Number</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>VIN</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Engine Number</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>GVM</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Tare</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>AA Vehicle Rate Fee</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Fuel Efficiency (L/100km)</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300'>
                            <label className='text-base font-medium text-gray-500'>Active</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                    </div>
                    <p className="bg-black-skeleton animate-ping animate-pulse py-5 rounded w-2/12"></p>
                </form>
            </div>
        </Layout>
    )
}

export const Error = () => {
    const navigate = useNavigate()

    const goBack = () => navigate(-1);

    return (
        <Layout>
            <div className="w-full h-full overflow-y-scroll p-2 flex flex-col justify-start">
                <div className='flex items-center justify-between'>
                    <div className='flex flex-col justify-start gap-0'>
                        <p className="text-purple font-semibold text-2xl lg:text-3xl">
                            Edit Vehicle
                        </p>
                        <p className="text-lg font-bold text-gray-500"></p>
                    </div>
                    <div className="flex items-center justify-start lg:justify-end gap-2 flex-wrap w-full lg:w-6/12">
                        <button className="border border-red p-1 md:p-2 w-[30%] md:w-[20%] text-red hover:bg-red hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer" onClick={goBack}>Close</button>
                    </div>
                </div>
                <form className='w-full h-full flex flex-col justify-between items-start'>
                    <div className='flex items-start justify-start flex-wrap gap-2 w-full'>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>UID</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Make</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Model</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>License Number</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Veh. register Number</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>VIN</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Engine Number</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>GVM</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Tare</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>AA Vehicle Rate Fee</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Fuel Efficiency (L/100km)</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300'>
                            <label className='text-base font-medium text-gray-500'>Active</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                    </div>
                    <p className="bg-black-skeleton animate-ping animate-pulse py-5 rounded w-2/12"></p>
                </form>
                <div className='rounded-lg flex items-center justify-center fixed bg-red text-white p-6 w-11/12 md:6/12 lg:w-6/12 xl:4/12'>
                    <figure className='flex items-center justify-center'>
                        <img src={walert} alt='Error' loading='lazy' className='object-contain w-20 md:w-32' />
                    </figure>
                    <p className='text-md font-medium leading-5'>We could not load the vehicle data</p>
                </div>
            </div>
        </Layout>
    )
}

export const Empty = () => {
    const navigate = useNavigate()

    const goBack = () => navigate(-1);

    return (
        <Layout>
            <div className="w-full h-full overflow-y-scroll p-2 flex flex-col justify-start">
                <div className='flex items-center justify-between'>
                    <div className='flex flex-col justify-start gap-0'>
                        <p className="text-purple font-semibold text-2xl lg:text-3xl">
                            Edit Vehicle
                        </p>
                        <p className="text-lg font-bold text-gray-500"></p>
                    </div>
                    <div className="flex items-center justify-start lg:justify-end gap-2 flex-wrap w-full lg:w-6/12">
                        <button className="border border-red p-1 md:p-2 w-[30%] md:w-[20%] text-red hover:bg-red hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer" onClick={goBack}>Close</button>
                    </div>
                </div>
                <form className='w-full h-full flex flex-col justify-between items-start' >
                    <div className='flex items-start justify-start flex-wrap gap-2 w-full'>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>UID</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Make</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Model</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>License Number</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Veh. register Number</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>VIN</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Engine Number</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>GVM</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Tare</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>AA Vehicle Rate Fee</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%]'>
                            <label className='text-base font-medium text-gray-500'>Fuel Efficiency (L/100km)</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                        <div className='flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300'>
                            <label className='text-base font-medium text-gray-500'>Active</label>
                            <p className="bg-black-skeleton animate-ping animate-pulse py-5 w-full rounded"></p>
                        </div>
                    </div>
                    <p className="bg-black-skeleton animate-ping animate-pulse py-5 rounded w-2/12"></p>
                </form>
                <div className='rounded-lg flex items-center justify-center fixed bg-green text-white p-6 w-11/12 md:6/12 lg:w-6/12 xl:4/12 gap-4'>
                    <figure className='flex items-center justify-center'>
                        <img src={emptyIcon} alt='Empty Store' loading='lazy' className='object-contain w-20 md:w-28' />
                    </figure>
                    <p className='text-md font-medium leading-5'>This vehicle has not data linked to it</p>
                </div>
            </div>
        </Layout>
    )
}